import { IconTruckDelivery, IconFileImport, IconCreditCard } from '@tabler/icons';

const icons = {
    IconTruckDelivery,
    IconFileImport,
    IconCreditCard
};

const pickMenu = {
    id: 'pick-menu',
    type: 'group',
    children: [
        {
            id: 'pick-list',
            title: 'Pickup',
            type: 'item',
            icon: icons.IconTruckDelivery,
            url: '/pick/list'
        }
    ]
};

export const payoutMenu = {
    id: 'payout-menu',
    type: 'group',
    children: [
        {
            id: 'payout-menu-id',
            title: 'Payout',
            type: 'item',
            icon: icons.IconCreditCard,
            url: '/payout/list'
        }
    ]
};

export const importMenu = {
    id: 'import-menu',
    type: 'group',

    children: [
        {
            id: 'pick-xlsx-coll',
            title: 'Import',
            type: 'collapse',
            icon: icons.IconFileImport,
            children: [
                {
                    id: 'pick-xlsx',
                    title: 'Xlsx',
                    type: 'item',
                    url: '/pick/xlsx'
                },
                {
                    id: 'pick-manual',
                    title: 'Manual',
                    type: 'item',
                    url: '/pick/manual'
                }
            ]
        }
    ]
};

export default pickMenu;

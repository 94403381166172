import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import useAxiosServices from '../../hooks/useAxios';
import MessageContent from './message.content';
import MessageUserContent from './message.user.content';
import { gridSpacing } from '../../store/constant';
import BeforeList from '../before/before.list';
import BeforeInList from '../before/before.in.list';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { useTheme } from '@mui/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import BeforeDrawerWrapper from '../before/before.drawer.wrapper';
import { userBeforeTypes } from '../../types/before';

function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`user-tabpanel-${index}`} aria-labelledby={`user-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `user-tab-${index}`,
        'aria-controls': `user-tabpanel-${index}`
    };
}

const MessageUserWapper = () => {
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const [type, setType] = useState(0);
    const location = useLocation() as any;
    const [selectRow, setSelectRow] = useState([]);
    useEffect(() => {
        setSelectRow([...location?.state]);
    }, [location.state]);
    console.log(selectRow);

    const handleChange = (event: React.SyntheticEvent, newValue) => {
        setType(newValue);
    };

    const handlerUpdateProfile = (updateData) => {};
    const navigation = useNavigate();

    const [data, setData] = useState({ title: '', content: '', mailContent: '' });
    const handleDeleteRows = useCallback(
        (idx: number) => {
            const filter = selectRow.filter((v) => v !== idx);
            setSelectRow([...filter]);
        },
        [selectRow]
    );

    const [openDrawer, setOpenDrawer] = useState(false);

    const handleOpenDrawer = useCallback(() => {
        setOpenDrawer((p) => !p);
    }, [openDrawer]);

    const addSelectRow = (user: number[]) => {
        const container = [];
        for (const row of user) {
            if (row && !selectRow.includes(row)) {
                container.push(row);
            }
        }
        const addData = [...selectRow, ...container];
        setSelectRow([...addData]);
    };
    const [refresh, setRefresh] = useState(false);
    const onRefresh = () => {
        setRefresh((p) => !p);
    };

    return (
        <>
            <MainCard title={'Mail Send'}>
                <MessageUserContent type={10} selectRows={selectRow} onRefresh={onRefresh} />
                <MainCard
                    title={
                        <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                            <Grid item>
                                <Typography variant="h3">Prepare List ({selectRow.length})</Typography>
                            </Grid>
                        </Grid>
                    }
                    content={false}
                >
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            <Stack direction="row" justifyContent="center" alignItems="center">
                                <Tooltip placement="top" title="Add User">
                                    <IconButton
                                        onClick={() => handleOpenDrawer()}
                                        color="primary"
                                        sx={{
                                            color: theme.palette.success.dark,
                                            borderColor: theme.palette.success.main,
                                            '&:hover ': { background: theme.palette.success.light }
                                        }}
                                        size="large"
                                    >
                                        <MenuTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>
                    {selectRow && selectRow.length > 0 && <BeforeInList refresh={refresh} inList={selectRow} onDelete={handleDeleteRows} />}
                </MainCard>
            </MainCard>
            <BeforeDrawerWrapper
                refresh={refresh}
                outRow={selectRow}
                open={openDrawer}
                handleDrawerOpen={handleOpenDrawer}
                addUser={addSelectRow}
            />
        </>
    );
};

export default MessageUserWapper;

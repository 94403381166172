import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Typography, MenuItem, FormControlLabel, Radio, TextField } from '@mui/material';

// third party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import { IconBrandFacebook, IconBrandYoutube, IconBrandTwitter, IconCash, IconBusinessplan } from '@tabler/icons';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import { DefaultRootStateProps } from 'types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
import SkeletonTotalGrowthBarChart from '../../ui-component/cards/Skeleton/TotalGrowthBarChart';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/lab';
import useAxiosServices from '../../hooks/useAxios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const radioData = [
    { label: 'Last 7days', value: '2' },
    { label: 'Last 30days', value: '55' },
    { label: 'Monthly', value: '3' },
    { label: 'Quarterly', value: '4' },
    { label: 'Custom', value: '5' },
    { label: 'Monthly comparison', value: '6' }
];

const chartDataSystem = (data, type) => {
    let r = 0;
    let c = 0;
    if (type !== '6') {
        for (const row of data.rData.data ?? []) {
            r += row;
        }
        for (const row of data.cData.data ?? []) {
            c += row;
        }
    }
    const up = r - c > 0;

    const upNumber = r > c ? r : c;
    const downNumber = r > c ? c : r;
    const total = ((up ? r : c) / (r + c)) * 100;
    const rate = total === 100 ? r : total;

    return { r, c, up, rate: isNaN(rate) ? 0 : rate.toFixed(2) };
};

const chartDataOrder = (data, range, type, subType) => {
    const dataView = () => {
        const result = [];
        if (type !== '6') {
            result.push({
                name: 'Revenue',
                data: data.rData.data ?? []
            });
            result.push({
                name: 'Deficit',
                data: data.cData.data ?? []
            });
        } else {
            result.push(...(data.listData ?? []));
        }
        return result;
    };
    console.log(dataView());
    return {
        height: 300,
        type: 'line',
        options: {
            chart: {
                id: 'area-chart',
                toolbar: {
                    show: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '20%'
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            xaxis: {
                type: 'index',
                categories: range
            }
        },
        series: dataView()
    };
};

// ===========================|| DASHBOARD ANALYTICS - MARKET SHARE AREA CHART CARD ||=========================== //

const monthData = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
];

const monthDataSimple = [
    { label: 'Jan', value: '1' },
    { label: 'Feb', value: '2' },
    { label: 'Mar', value: '3' },
    { label: 'Apr', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'Aug', value: '8' },
    { label: 'Sep', value: '9' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' }
];

interface Props {
    isLoading: boolean;
}

const LineChart = ({ isLoading }: Props) => {
    const theme = useTheme();

    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const { navType } = customization;

    const [month, setMonth] = useState('3');
    const [type, setType] = useState('2');

    const [subType, setSubType] = useState('0');
    const [monthList, setMonthList] = useState(['3']);
    const [rangeDate, setRangeDate] = React.useState<DateRange<Date>>([null, null]);

    const [apiData, setApiData] = useState({
        rData: {
            data: []
        },
        cData: {
            data: []
        },
        listData: {
            data: []
        }
    });
    const [dataRange, setDataRange] = useState([]);
    const { axiosService } = useAxiosServices();
    const getData = async () => {
        const post = { type, range: month, custom: [], subType, monthList };
        if (rangeDate[1]) {
            post.custom = [...rangeDate];
        }
        const api = await axiosService.post('/statistics/order', post);

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { range, data, status } = api.data;
        if (status) {
            data && setApiData({ ...data });
            range && setDataRange([...range]);
        }
    };

    const chartDataOpti = chartDataOrder(apiData, dataRange, type, subType);

    useEffect(() => {
        getData();
    }, [type, month, rangeDate[1], subType, monthList]);

    const secondaryMain = theme.palette.secondary.main;
    const errorMain = theme.palette.error.main;
    const primaryDark = theme.palette.primary.dark;

    React.useEffect(() => {
        const newChartData = {
            ...chartDataOpti.options,
            colors: [secondaryMain, errorMain, primaryDark],
            tooltip: {
                theme: navType === 'dark' ? 'dark' : 'light'
            }
        };
        if (!isLoading) {
            ApexCharts.exec(`market-share-area-chart`, 'updateOptions', newChartData);
        }
    }, [isLoading, navType, secondaryMain, errorMain, primaryDark]);
    const handleChangeType = (event: SelectChangeEvent) => {
        console.log(event?.target.value);
        event?.target.value && setType(event.target.value as string);
    };

    const handleChange = (event: SelectChangeEvent) => {
        console.log(event?.target.value);
        event?.target.value && setMonth(event.target.value as string);
    };

    const monthListHandler = (value: string) => {
        const temp = [...monthList];
        if (temp.includes(value)) {
            const tempList = temp.filter((v) => v !== value);
            setMonthList([...tempList]);
        } else {
            const tempListArray = [...temp, value];
            setMonthList([...tempListArray]);
        }
    };

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard sx={{ '&>div': { p: 0, pb: '0px !important' } }}>
                    <Box
                        sx={{
                            p: 3
                        }}
                    >
                        <Grid container direction="column" spacing={3}>
                            <Grid item container spacing={1} alignItems="center">
                                <Grid item>
                                    <Typography variant="h3">Finanical</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth />
                                <Grid item>
                                    {chartDataSystem(apiData, type).up ? (
                                        <TrendingUpIcon fontSize="large" color="primary" />
                                    ) : (
                                        <TrendingDownIcon fontSize="large" color="error" />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography variant="h3">{chartDataSystem(apiData, type).rate}%</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="space-around" alignItems="center" spacing={3}>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    color: theme.palette.secondary.main,
                                                    borderRadius: '12px',
                                                    padding: 1,
                                                    backgroundColor:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.background.default
                                                            : theme.palette.secondary.light
                                                }}
                                            >
                                                <MonetizationOnTwoToneIcon />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm zeroMinWidth>
                                            <Typography color={'primary'} variant="h4">
                                                ${chartDataSystem(apiData, type).r}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    color: theme.palette.primary.main,
                                                    borderRadius: '12px',
                                                    padding: 1,
                                                    backgroundColor:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.background.default
                                                            : theme.palette.primary.light
                                                }}
                                            >
                                                <CreditCardTwoToneIcon />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm zeroMinWidth>
                                            <Typography color={'error'} variant="h4">
                                                ${chartDataSystem(apiData, type).c}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs zeroMinWidth />
                            </Grid>
                        </Grid>
                        <Grid container flexDirection={'row'} item my={2}>
                            <Grid item>
                                <Select
                                    id="columnIsd"
                                    name="columnIad"
                                    value={type}
                                    onChange={handleChangeType}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    {radioData.map(({ value: monthValue, label }, index: number) => (
                                        <MenuItem key={index} value={monthValue}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            {type === '99' && (
                                <Grid item ml={2}>
                                    <Select
                                        id="columnId"
                                        name="columnId"
                                        value={month}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {monthData.map(({ value: monthValue, label }, index: number) => (
                                            <MenuItem key={index} value={monthValue}>
                                                {label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            )}
                            {type === '5' && (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item ml={2}>
                                        <DateRangePicker
                                            startText="Start"
                                            endText="End"
                                            value={rangeDate}
                                            onChange={(newValue) => {
                                                setRangeDate(newValue);
                                            }}
                                            renderInput={(startProps, endProps) => (
                                                <>
                                                    <TextField {...startProps} />
                                                    <Box sx={{ mx: 2 }}> to </Box>
                                                    <TextField {...endProps} />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            )}
                        </Grid>
                        {type === '6' && (
                            <Grid container flexDirection={'row'} item my={2}>
                                <Grid item xs={12}>
                                    {['0', '1'].map((v, i) => (
                                        <FormControlLabel
                                            checked={v === String(v)}
                                            control={
                                                <Radio
                                                    onClick={() => setSubType(v)}
                                                    checked={v === subType}
                                                    sx={{
                                                        color: 'primary.main',
                                                        '&.Mui-checked': { color: 'primary.main' }
                                                    }}
                                                />
                                            }
                                            label={v === '0' ? 'Revenue' : 'Deficit'}
                                        />
                                    ))}
                                </Grid>
                                <Grid item xs={12}>
                                    {monthDataSimple.map((v, i) => (
                                        <FormControlLabel
                                            checked={monthList.includes(v.value)}
                                            control={
                                                <Radio
                                                    onClick={() => monthListHandler(v.value)}
                                                    checked={monthList.includes(v.value)}
                                                    sx={{
                                                        color: 'primary.main',
                                                        '&.Mui-checked': { color: 'primary.main' }
                                                    }}
                                                />
                                            }
                                            label={v.label}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                    {/* @ts-ignore */}
                    <Chart {...chartDataOrder(apiData, dataRange, type, subType)} />
                </MainCard>
            )}
        </>
    );
};
export default LineChart;

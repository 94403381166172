// material-ui
import { Avatar, Button, Grid, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';

import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

import { useFormik } from 'formik';
import * as yup from 'yup';
import FaceIcon from '@mui/icons-material/Face';
import MaskedInput from 'react-text-mask';
import TextFieldCustom from '../../ui-component/text-field';
import { userListProfile } from '../../_mockApis/user-profile/types';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import useAuth from '../../hooks/useAuth';

const validationSchema = yup.object({
    userId: yup.string().required('Id is required'),
    userName: yup.string().min(2, 'Name should be of minimum 2 characters length').required('Name is required'),
    userFirstName: yup.string().min(2, 'First name should be of minimum 2 characters length').required('First name is required'),
    userEmail: yup.string().email('Enter a valid email').required('Email is required'),
    note: yup.string()
});

interface Props {
    id: string;
}

const ManagerDetail = ({ id }: Props) => {
    const { axiosService } = useAxiosServices();
    const formik = useFormik({
        initialValues: {
            userId: '',
            userName: '',
            userEmail: '',
            userFirstName: '',
            note: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            await updateApi(values);
        }
    });

    const updateApi = async (values) => {
        const { userId, userName, userEmail, note, userFirstName } = values;
        const api = await axiosService.post('/users/admin/update/admin', { userId, userName, userEmail, note, userFirstName, id });
        const { status } = api.data;
        if (status) {
            toast.info('Success Update profile', { ...ToastSetting.default });
            await getProfile();
        }
    };

    const [profile, setProfile] = useState<userListProfile>({
        userBirth: '',
        userPhone: '',
        credit: 0,
        idx: 0,
        userAddress: '',
        userAddressMore: '',
        userEmail: '',
        userId: undefined,
        userImage: undefined,
        userName: '',
        userNickName: '',
        userPick: 0,
        userRole: 0,
        userStatus: 0,
        userZip: '',
        note: '',
        adminRole: 0,
        admin: 0
    });

    useEffect(() => {
        getProfile();
    }, [id]);

    const getProfile = async () => {
        try {
            const api = await axiosService.post('/users/detail', { id });
            const { data } = api;
            const userProfile = {
                ...data,
                note: data.note ?? ''
            };
            console.log('USER', data);
            setProfile({ ...userProfile });
            const r = data.adminRole;
            const a = data.admin;
            setTrans(r === 5 || r === 6);
            setStat(r === 5 || r === 7);
            setManager(a === 0);
            await updateUserProfile(data);
        } catch (e) {
            console.log(e);
        }
    };

    const updateUserProfile = async (data) => {
        const { userBirth, userPhone, userAddress, note, userEmail, userId, userName, userFirstName, userZip } = data;
        console.log(userBirth);
        await formik.setValues({
            note: note ?? '',
            userEmail: userEmail ?? '',
            userId,
            userName,
            userFirstName: userFirstName ?? ''
        });
    };

    const [open, setOpen] = useState(false);
    const handlerDriverAccess = () => {
        console.log('idx', profile.idx);
        setOpen((p) => !p);
    };

    const [stat, setStat] = useState(false);
    const [trans, setTrans] = useState(false);
    const [manager, setManager] = useState(false);

    const handlerSetting = async (type, value) => {
        const api = await axiosService.post('users/setting/manager/change', { type, id, value });
        const { data, status } = api.data;
        if (status) {
            console.log('ok');
            setTrans(data === 5 || data === 6);
            setStat(data === 5 || data === 7);
        }
    };
    const handlerSettingAdmin = async (value) => {
        const api = await axiosService.post('users/setting/manager/admin', { id, manager: value });
        const { data, status } = api.data;
        if (status) {
            setManager(data === 0);
        }
    };

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item sm={6} md={8}>
                    <SubCard title="User Details">
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="userId"
                                        fullWidth
                                        label="Id"
                                        value={formik.values.userId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.userId && Boolean(formik.errors.userId)}
                                        helperText={formik.touched.userId && formik.errors.userId}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="userEmail"
                                        fullWidth
                                        label="Email"
                                        value={formik.values.userEmail}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.userEmail && Boolean(formik.errors.userEmail)}
                                        helperText={formik.touched.userEmail && formik.errors.userEmail}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        id="userName"
                                        fullWidth
                                        label="Last name"
                                        value={formik.values.userName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.userName && Boolean(formik.errors.userName)}
                                        helperText={formik.touched.userName && formik.errors.userName}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        id="userFirstName"
                                        fullWidth
                                        label="First name"
                                        value={formik.values.userFirstName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.userFirstName && Boolean(formik.errors.userFirstName)}
                                        helperText={formik.touched.userFirstName && formik.errors.userFirstName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="note"
                                        fullWidth
                                        label="Note"
                                        value={formik.values.note}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.note && Boolean(formik.errors.note)}
                                        helperText={formik.touched.note && formik.errors.note}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row">
                                        <AnimateButton>
                                            <Button variant="contained" type={'submit'}>
                                                Change Profile
                                            </Button>
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    </SubCard>
                </Grid>
                <Grid item sm={6} md={4}>
                    <SubCard title="Manager Setting" contentSX={{ textAlign: 'center' }}>
                        <Grid xs={12} mb={2}>
                            <Typography variant={'h4'}>Managers Hide</Typography>
                            <Switch
                                checked={manager}
                                onChange={(event, checked) => handlerSettingAdmin(checked)}
                                inputProps={{ 'aria-label': 'checked' }}
                            />
                        </Grid>
                        <Grid xs={12} mb={2}>
                            <Typography variant={'h4'}>Transaction Hide</Typography>
                            <Switch
                                checked={trans}
                                onChange={(event, checked) => handlerSetting(1, checked)}
                                inputProps={{ 'aria-label': 'checked' }}
                            />
                        </Grid>
                        <Grid xs={12} mb={2}>
                            <Typography variant={'h4'}>Statistics Hide</Typography>
                            <Switch
                                checked={stat}
                                onChange={(event, checked) => handlerSetting(2, checked)}
                                inputProps={{ 'aria-label': 'checked' }}
                            />
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
            <AlertDialog
                title={'Access Driver'}
                content={'Access Driver?'}
                open={open}
                handleClose={setOpen}
                handleSuccess={handlerDriverAccess}
            />
        </>
    );
};

export default ManagerDetail;

import React, { useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Box, ButtonBase, InputBase, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props {
    formikValue: string;
    formikHandler: any;
    placeholder: string;
    locationId: string;
    id: string;
    sx: SxProps<Theme>;
    onBlur: any;
    error: any;
}
const AddressInput = ({ placeholder, formikValue, formikHandler, id, sx, locationId, onBlur, error }: Props) => {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */

            componentRestrictions: { country: 'AU' }
        },
        debounce: 300
    });

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const handleSelect = ({ description }) => () => {
        console.log('DE', description);
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();
        getGeocode({ address: description }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            console.log('📍 Coordinates: ', results);
            formattingAddress(results[0], description, lat, lng);
        });

        // Get latitude and longitude via utility functions
    };

    const formattingAddress = async (a: any, ad, la, ln) => {
        formikHandler(id, ad);
        formikHandler(`${locationId}.lat`, la);
        formikHandler(`${locationId}.lot`, ln);
    };
    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text }
            } = suggestion;

            return (
                <ButtonBase
                    key={place_id}
                    sx={{ width: '100%', padding: '10px 0', overflow: 'hidden', maxHeight: '40px' }}
                    onClick={handleSelect(suggestion)}
                >
                    <Box display={'flex'} width={'100%'} overflow={'hidden'} maxHeight={'40px'} flexWrap={'nowrap'}>
                        <Typography
                            sx={{ fontSize: { xs: '10px', md: '16px' } }}
                            overflow={'hidden'}
                            maxHeight={'40px'}
                            textOverflow={'hidden'}
                            whiteSpace={'nowrap'}
                        >
                            {main_text} {secondary_text}
                        </Typography>
                    </Box>
                </ButtonBase>
            );
        });
    const [temp, setTemp] = useState('');
    const changeAddress = (e) => {
        console.log(e);
        setTemp(e);
        formikHandler(id, e);
        setValue(e);
    };
    return (
        <Box ref={ref}>
            <InputBase
                autoComplete={'off'}
                onBlur={onBlur}
                error={error}
                sx={sx}
                id={id}
                value={formikValue}
                placeholder={placeholder}
                onChange={(e) => changeAddress(e.currentTarget.value)}
            />
            {status === 'OK' && (
                <Box
                    sx={{
                        zIndex: 10,
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#fff',
                        borderColor: '#222',
                        borderTop: '0px',
                        paddingX: '10px',
                        boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px'
                    }}
                >
                    {renderSuggestions()}
                </Box>
            )}
        </Box>
    );
};

export default AddressInput;

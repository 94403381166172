import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/system';
import {
    Box,
    Button,
    Checkbox,
    Grid,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography
} from '@mui/material';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { KanbanUserStory } from '../../types/kanban';
import { userStory, userStoryOrder } from '../../store/kanban';
import PickListDetail from './PickListDetail';
import AddIcon from '@mui/icons-material/Add';
import { sub } from 'date-fns';
import AddPickUpDrawer from './drawer/AddPickupDrawer';
import pick from '../../menu-items/pick';
import { bagListType, pickListTypes } from '../../types/pick';
import { Link } from 'react-router-dom';
import AddDepotDrawer from './drawer/add.depot.drawer';
import { DepotListProfileType } from '../../_mockApis/user-profile/types';
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons';
import { addressFormat, addressFormat2 } from '../../utils/common';
import RouteGroupList from './map/route.group.list';
import * as uuid from 'uuid';
import { PolyLineDataType, RouteLineColorPalette } from './route.optimasation';
import { pickUpUserName } from '../../utils/users.helper';

export interface RouteRootList {
    location: google.maps.LatLng;
    type: 'depot' | 'pick';
    idx: number;
    address: string;
    name: string;
    bag?: number;
    info?: pickListTypes;
    group?: number;
    uuid: string;
    bags?: bagListType[];
    optiIdx?: number;
}

export interface RouteRootWithPolyList extends RouteRootList {
    poly?: string;
}
/// //////
function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`user-tabpanel-${index}`} aria-labelledby={`user-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `user-tab-${index}`,
        'aria-controls': `user-tabpanel-${index}`
    };
}

/// ///////
const getDropWrapper = (isDraggingOver: boolean, theme: Theme) => ({
    background: isDraggingOver ? theme.palette.secondary.light + 50 : 'transparent'
});
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `item ${k + offset}`
    }));
export const reorder = (list: RouteRootList[], startIndex, endIndex): RouteRootList[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};
const grid = 8;
export const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: '#fff',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    background: '#fff',
    padding: '10px'
});
interface Props {
    centerList: google.maps.LatLng[];
    setCenterList: Dispatch<SetStateAction<google.maps.LatLng[]>>;
    routeList: RouteRootList[];
    initList: pickListTypes[];
    setRouteList: Dispatch<SetStateAction<RouteRootList[]>>;
    listHandler: (item: pickListTypes[]) => void;
    getPolyLineData: (group: any) => Promise<void>;
    depotList: DepotListProfileType[];
    setDepotList: Dispatch<SetStateAction<DepotListProfileType[]>>;
    startDepotList: DepotListProfileType[];
    setStartDepotList: Dispatch<SetStateAction<DepotListProfileType[]>>;
    viewPoly: number[];
    setViewPoly: Dispatch<SetStateAction<number[]>>;
    polylineData: PolyLineDataType[];
    optimise: boolean;
    handleOptimise: () => void;
}
const PickListWithDepot = ({
    centerList,
    setCenterList,
    viewPoly,
    setViewPoly,
    polylineData,
    depotList,
    setDepotList,
    initList,
    listHandler,
    setRouteList,
    routeList,
    getPolyLineData,
    setStartDepotList,
    startDepotList,
    optimise,
    handleOptimise
}: Props) => {
    const theme = useTheme();
    //
    const [state, setState] = useState([getItems(10), getItems(5, 10)]);
    //
    // Tab
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue) => {
        setTabValue(newValue);
    };
    //

    const [pickList, setPickList] = useState<pickListTypes[]>([]);
    const [selectDepotList, setSelectDepotList] = useState<DepotListProfileType[]>([]);
    const [mainList, setMainList] = useState<string[]>([]);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [openDepotDrawer, setOpenDepotDrawer] = useState<boolean>(false);
    const [startDepotDrawer, setStartDepotDrawer] = useState<boolean>(false);

    useEffect(() => {
        listAddHandler(initList);
    }, [initList]);

    const listAddHandler = (initData) => {
        setPickList([...initData]);
        setMainList([...initData.map((v) => `pick_${v.idx}`)]);
    };

    const addDepot = (item: DepotListProfileType) => {
        setDepotList((p) => [...p, item]);
        setOpenDepotDrawer(false);
    };

    const addStartDepot = (item: DepotListProfileType) => {
        setStartDepotList((p) => [item]);
        setStartDepotDrawer(false);
    };

    const sortList = (sort, pickData) => {
        const sortData = sort.map((idx: string, index: number) => pickData.filter((item) => `pick_${item.idx}` === idx)[0]);
        setPickList([...sortData]);
        listHandler(sortData);
    };

    const deleteList = (item: pickListTypes) => {
        const convertList = [...pickList].filter((v) => v.idx !== item.idx);
        const convertMainList = [...mainList].filter((v) => v !== `pick_${item.idx}`);
        setPickList([...convertList]);
        setMainList([...convertMainList]);
        sortList(convertMainList, convertList);
    };

    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = source.droppableId;
        const dInd = destination.droppableId;
        if (sInd === dInd) {
            // const startIndex = routeList.findIndex((v) => v.uuid === (source.index as string));
            // const endIndex = routeList.findIndex((v) => v.uuid === destination.index);

            const item = routeList[source.index];
            item && setCenterList([...[item.location]]);
            const items: RouteRootList[] = reorder(routeList, source.index, destination.index);
            console.log(items);

            setRouteList([...items]);
        }
    };

    // const onDragEnd = (result: DropResult) => {
    //     const { source, destination, draggableId, type } = result;
    //
    //     if (!destination) return;
    //
    //     if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    //
    //     if (type === 'driver-route') {
    //         const newUserStoryOrder = Array.from(mainList);
    //         newUserStoryOrder.splice(source.index, 1); // remove dragged column
    //         newUserStoryOrder.splice(destination?.index, 0, draggableId); // set column new position
    //         setMainList([...newUserStoryOrder]);
    //         sortList(newUserStoryOrder, pickList);
    //     }
    // };

    const handleDrawerOpen = () => {
        setOpenDepotDrawer(false);
        setStartDepotDrawer(false);

        setOpenDrawer((prevState) => !prevState);
    };
    const handleDrawerOpenDepot = () => {
        setOpenDrawer(false);
        setStartDepotDrawer(false);
        setOpenDepotDrawer((prevState) => !prevState);
    };
    const handleDrawerOpenStartDepot = () => {
        setOpenDrawer(false);
        setOpenDepotDrawer(false);

        setStartDepotDrawer((prevState) => !prevState);
    };

    const handleDeleteListDepot = (index) => {
        const filterTemp = depotList.filter((v, i) => i !== index);
        setDepotList([...filterTemp]);
    };

    const handleAddPickList = (item: pickListTypes) => {
        console.log(item);
        const temp = [...routeList];
        const location = new google.maps.LatLng({ lat: item.lat, lng: item.lot });
        const addData: RouteRootList = {
            idx: item.idx,
            address: `${addressFormat2(item.addressZip ?? '', '', item.address ?? '')}`,
            location,
            type: 'pick',
            name: `${pickUpUserName(item)}`,
            bag: item.bag ?? 0,
            info: item,
            uuid: uuid.v4()
        };
        if (temp.length === 0) {
            temp.push(addData);
            setRouteList([...temp]);
        } else {
            const [oneData, ...anyData] = temp;
            const temp2Index = [oneData, addData, ...anyData];
            setRouteList([...temp2Index]);
        }
        const applyPickList = [...pickList, item];
        setPickList([...applyPickList]);
        setTabValue(0);
        setCenterList([...[addData.location]]);
    };

    const handleAddDepotRouteList = (v: DepotListProfileType) => {
        const temp = [...routeList];
        const location = new google.maps.LatLng({ lat: v.depot?.lat, lng: v.depot?.lot });
        const addData: RouteRootList = {
            idx: v.idx,
            address: v.depot.depotAddress,
            location,
            type: 'depot',
            name: v.userName,
            uuid: uuid.v4()
        };
        if (temp.length === 0) {
            temp.push(addData);
            setRouteList([...temp]);
        } else {
            const [oneData, ...anyData] = temp;
            const temp2Index = [oneData, addData, ...anyData];
            setRouteList([...temp2Index]);
        }
        setTabValue(0);
        setCenterList([...[addData.location]]);
    };

    const handleDeleteRouteList = (uud, index) => {
        const filterTemp = routeList.filter((v, i) => v.uuid !== uud);
        const filterTemp2 = pickList.filter((v, i) => v.idx !== index);
        setRouteList([...filterTemp]);
        setPickList([...filterTemp2]);
    };

    return (
        <>
            <TableContainer>
                <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setTabValue(0)}>
                    <Tabs
                        value={tabValue}
                        indicatorColor={'primary'}
                        onChange={handleChange}
                        sx={{
                            mb: 3,
                            minHeight: 'auto',
                            '& button': {
                                minWidth: 100
                            },
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.25,
                                color: 'grey.600'
                            },
                            '& a.Mui-selected': {
                                color: 'primary.main'
                            }
                        }}
                        aria-label="simple tabs example"
                        variant="scrollable"
                    >
                        <Tab component={Link} to="#" label="Route" {...a11yProps(0)} />
                        <Tab component={Link} to="#" label="Depot" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <Droppable key={'route'} droppableId={`route`}>
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <Tooltip title="Add Depot" sx={{ marginBottom: '20px' }}>
                                            <Button
                                                onClick={() => handleDrawerOpen()}
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                endIcon={<AddIcon />}
                                            >
                                                ADD Pick
                                            </Button>
                                        </Tooltip>
                                        <Grid
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            display={'flex'}
                                            sx={{ marginBottom: '20px' }}
                                        >
                                            <Grid justifyContent={'center'} alignItems={'center'} display={'flex'} marginRight={'5px'}>
                                                <Typography variant={'subtitle1'}>Optimise</Typography>
                                                <Checkbox
                                                    sx={{ width: '100%', height: '100%', padding: 0 }}
                                                    checked={optimise}
                                                    onChange={(event) => handleOptimise()}
                                                    name="checked"
                                                    color="primary"
                                                    aria-label={'ddd'}
                                                />
                                            </Grid>
                                            <Tooltip title="Optimise All">
                                                <Button
                                                    onClick={() => getPolyLineData(null)}
                                                    variant="contained"
                                                    size="small"
                                                    color="secondary"
                                                    endIcon={<AddIcon />}
                                                >
                                                    Optimise All
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Box>
                                    {startDepotList.length === 0 && (
                                        <Box
                                            sx={{
                                                maxWidth: '500px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                                                '&:hover': {
                                                    boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                                                },
                                                padding: '10px 20px',
                                                margin: '10px 0px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '12px',
                                                height: '100%',
                                                minHeight: '100px'
                                            }}
                                        >
                                            <Button
                                                onClick={() => handleDrawerOpenStartDepot()}
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                sx={{ color: '#fff', backgroundColor: '#b134db' }}
                                                endIcon={<AddIcon />}
                                            >
                                                ADD Departure
                                            </Button>
                                        </Box>
                                    )}
                                    {startDepotList.map((item, i) => (
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography typography="h6">Departure</Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    maxWidth: '500px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                                                    '&:hover': {
                                                        boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                                                    },
                                                    padding: '10px 20px',
                                                    margin: '10px 0px',
                                                    alignItems: 'center',
                                                    borderRadius: '12px',
                                                    height: '100%'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        marginTop: '10px',
                                                        display: 'flex',
                                                        width: '100%',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            height: '90px',
                                                            width: '5px',
                                                            backgroundColor: '#b134db',
                                                            marginRight: '20px'
                                                        }}
                                                    />
                                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                                        <Typography typography="h4">Start</Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                width: '100%',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Box sx={{ marginBottom: '10px', marginRight: '10px', width: '100%' }}>
                                                                <Typography typography="h4" marginBottom={'10px'}>
                                                                    {item.userAddress}
                                                                </Typography>
                                                                <Box
                                                                    sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                                                >
                                                                    <Typography typography="h4">{item.userName}</Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    marginLeft: '30px',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'flex-end'
                                                                }}
                                                            >
                                                                <Button
                                                                    variant="contained"
                                                                    size="small"
                                                                    color="secondary"
                                                                    disabled
                                                                    sx={{
                                                                        marginBottom: '10px',
                                                                        marginRight: '10px',
                                                                        '&:disabled': {
                                                                            backgroundColor: '#b134db'
                                                                        }
                                                                    }}
                                                                >
                                                                    <Typography typography="h5" color={'#fff'}>
                                                                        {'Depot'}
                                                                    </Typography>
                                                                </Button>
                                                                <Button
                                                                    onClick={() => setStartDepotList([...[]])}
                                                                    variant="contained"
                                                                    size="small"
                                                                    color="secondary"
                                                                    sx={{ marginBottom: '10px', marginRight: '10px', maxWidth: '60px' }}
                                                                >
                                                                    <IconCircleMinus />
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}

                                    <RouteGroupList
                                        setCenterList={setCenterList}
                                        polylineData={polylineData}
                                        viewPoly={viewPoly}
                                        setViewPoly={setViewPoly}
                                        valueList={routeList}
                                        handleDeleteRouteList={handleDeleteRouteList}
                                        getPolyLineData={getPolyLineData}
                                    />

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <>
                            <Box sx={{ padding: '10px' }}>
                                <Tooltip title="Add Depot" sx={{ marginBottom: '20px' }}>
                                    <Button
                                        onClick={() => handleDrawerOpenDepot()}
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        endIcon={<AddIcon />}
                                    >
                                        ADD Depot
                                    </Button>
                                </Tooltip>
                                {depotList.map((v, i) => (
                                    <Box
                                        sx={{
                                            maxWidth: '500px',
                                            display: 'flex',

                                            width: '100%',
                                            boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                                            '&:hover': {
                                                boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                                            },
                                            padding: '10px 20px',
                                            margin: '10px 0px',
                                            alignItems: 'center',
                                            borderRadius: '12px',
                                            height: '100%'
                                        }}
                                    >
                                        <Box sx={{ height: '50px', width: '5px', backgroundColor: '#ff0000', marginRight: '20px' }} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Box sx={{ marginBottom: '10px', marginRight: '10px' }}>
                                                <Typography typography="h4" marginBottom={'10px'}>
                                                    {v.depot?.depotAddress}
                                                </Typography>
                                                <Typography typography="h4">{v.userName}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', marginLeft: '30px', flexDirection: 'column' }}>
                                                <Button
                                                    onClick={() => handleAddDepotRouteList(v)}
                                                    variant="contained"
                                                    size="small"
                                                    color="secondary"
                                                    sx={{ marginBottom: '10px', marginRight: '10px' }}
                                                >
                                                    <IconCirclePlus />
                                                </Button>
                                                <Button
                                                    onClick={() => handleDeleteListDepot(i)}
                                                    variant="contained"
                                                    size="small"
                                                    color="secondary"
                                                    sx={{ marginBottom: '10px', marginRight: '10px' }}
                                                >
                                                    <IconCircleMinus />
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </>
                    </TabPanel>
                </DragDropContext>
            </TableContainer>
            <AddPickUpDrawer open={openDrawer} handleDrawerOpen={handleDrawerOpen} addPick={handleAddPickList} initList={pickList} />
            <AddDepotDrawer open={openDepotDrawer} handleDrawerOpen={handleDrawerOpenDepot} addPick={addDepot} initList={depotList} />
            <AddDepotDrawer
                open={startDepotDrawer}
                handleDrawerOpen={handleDrawerOpenStartDepot}
                addPick={addStartDepot}
                initList={startDepotList}
            />
        </>
    );
};

export default PickListWithDepot;

import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, Typography, FormGroup, FormControlLabel, Checkbox, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { gridSpacing } from '../../store/constant';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import { userListProfile } from '../../_mockApis/user-profile/types';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { CheckBox } from '@mui/icons-material';
import { DatePicker, DateRangePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import ReactDraftWysiwyg from '../../ui-component/editor/ReactDraftWysiwyg';
import AlertDialog from '../../ui-component/dialog/AlertDialog';

const validationSchema = yup.object({
    title: yup.string().required('title is required')
});

interface Props {
    selectRows: number[];
    onRefresh: () => void;
}

const PushMail = ({ selectRows, onRefresh }: Props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const formik = useFormik({
        initialValues: {
            title: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            if (selectRows.length > 0) {
                setAlertOpen(true);
            }
        }
    });
    const { axiosService } = useAxiosServices();
    const [content, setContent] = useState('');
    const sendPush = async () => {
        const api = await axiosService.post('push/send/select', {
            title: formik.values.title,
            content,
            list: selectRows,
            type: 1,
            res: resCheck ? 1 : 0,
            resTime: time
        });
        const { status } = api.data;
        if (status) {
            onRefresh();
            toast.info('Success Send', { ...ToastSetting.default });
        } else {
            toast.error('Failed Send', { ...ToastSetting.default });
        }
        setAlertOpen(false);
    };

    const [r, setR] = useState(false);
    const [rangeDate, setRangeDate] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {}, []);
    const [alertOpen, setAlertOpen] = useState(false);

    // Time
    const [resCheck, setResCheck] = useState(false);
    const [time, setTime] = useState(new Date());
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} mt={4}>
                    <TextField
                        id="title"
                        fullWidth
                        label="Title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h3'}>Mail Content</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        '& .rdw-editor-wrapper': {
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                            borderRadius: '12px',
                            overflow: 'hidden',
                            '& .rdw-editor-main': {
                                px: 2,
                                py: 0.5,
                                border: 'none',
                                minHeight: '300px'
                            },
                            '& .rdw-editor-toolbar': {
                                pt: 1.25,
                                border: 'none',
                                borderBottom: '1px solid',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                '& .rdw-option-wrapper': {
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                },
                                '& .rdw-dropdown-wrapper': {
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                    '& .rdw-dropdown-selectedtext': {
                                        color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                    }
                                }
                            }
                        }
                    }}
                >
                    <Stack spacing={gridSpacing}>
                        <ReactDraftWysiwyg value={content} init={content} setValue={setContent} />
                    </Stack>
                </Grid>

                <Grid container item xs={12} justifyContent={'space-between'} sx={{ mb: 2 }}>
                    <Grid xs={6} display={'flex'} flexDirection={'row'}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox value={resCheck} checked={resCheck} onClick={() => setResCheck((p) => !p)} />}
                                label="Reservation"
                            />
                        </FormGroup>
                        {resCheck && (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                                    label="Date"
                                    value={time}
                                    inputFormat={'yyyy/MM/dd HH:mm'}
                                    onChange={(newValue: Date | null) => {
                                        setTime(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                    </Grid>
                    <Button disabled={selectRows.length === 0} variant={'outlined'} size={'large'} onClick={() => formik.handleSubmit()}>
                        Send
                    </Button>
                </Grid>
            </Grid>
            <AlertDialog
                title={'Send Mail'}
                content={`Do you want to send this content to ${selectRows.length} contacts?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => sendPush()}
            />
        </>
    );
};

export default PushMail;

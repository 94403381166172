import React from 'react';
import { Chip, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import {
    FindPayoutTypeConst,
    FindPayoutTypeStyle,
    FindPickProfileTypeStyle,
    FindPickTypeConst,
    FindPickTypeStyle,
    FindProfileTypeConst
} from '../../utils/const/pick.const';
import dayFormat from '../../utils/times/timeFormats';

interface Props {
    type: number | null;
}

interface ProfileProps {
    type: string | null;
}
const PickTypeCard = ({ type }: Props) => {
    const theme = useTheme();
    return <Chip label={FindPickTypeConst(type).label} size="small" sx={FindPickTypeStyle(theme, type)} />;
};

export const PayoutTypeCard = ({ type }: Props) => {
    const theme = useTheme();
    return <Chip label={FindPayoutTypeConst(type).label} size="small" sx={FindPayoutTypeStyle(theme, type)} />;
};

export const PickTypeText = ({ type }: Props) => {
    const theme = useTheme();
    return (
        <Typography align="left" variant="subtitle1" noWrap fontWeight={900} color={FindPickTypeConst(type).color}>
            {FindPickTypeConst(type).label}
        </Typography>
    );
};

export const PickProfileChip = ({ type }: ProfileProps) => {
    const theme = useTheme();
    return <Chip label={FindProfileTypeConst(type).label} size="small" sx={FindPickProfileTypeStyle(theme, type)} />;
};

export const RouteTypeCard = ({ type }: Props) => {
    const theme = useTheme();
    switch (type) {
        case 1:
            return (
                <Chip
                    label="Processing"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                        color: theme.palette.warning.dark
                    }}
                />
            );
        case 2:
            return (
                <Chip
                    label="Processing"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                        color: theme.palette.success.dark
                    }}
                />
            );
        case 3:
            return (
                <Chip
                    label="Completed"
                    size="small"
                    sx={{
                        background: 'lightgreen',
                        color: '#222'
                    }}
                />
            );
        default:
            return (
                <Chip
                    label="Wait"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                        color: theme.palette.warning.dark
                    }}
                />
            );
    }
};

export default PickTypeCard;

import { useEffect, useState } from 'react';
// third-party
import { Editor, EditorState as EditorType } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import axiosServices from '../../utils/axios';
import axios from 'axios';
import useAxios from '../../hooks/useAxios';

const resizePlugin = createResizeablePlugin();
const plugins = [resizePlugin];
// ==============================|| EDITOR ||============================== //

interface Props {
    init?: string;
    value: string;
    setValue: any;
}

const ReactDraftWysiwyg = ({ init, value, setValue }: Props) => {
    const [editorState, setEditorState] = useState(() => {
        const contentState = stateFromHTML(init ?? 'add Content');
        return EditorState.createWithContent(contentState);
        // return EditorState.createWithContent(ContentState.createFromText(value));
    });

    const uploadCallback = (file, callback) => {
        console.log(file);
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            console.log(reader);
            reader.onloadend = async () => {
                const form_data = new FormData();
                form_data.append('file', file);
                const res = await uploadFile(form_data);
                const { result, uri } = res;
                if (!result) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject('Error');
                    alert('Error');
                }
                setValue('thumbnail', uri);
                resolve({ data: { link: uri } });
            };
            reader.readAsDataURL(file);
        });
    };
    const { axiosService } = useAxios();
    const uploadFile = async (form) => {
        const api = await axiosService.post('image/upload', form, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
        console.log('api.data', api.data);
        return api.data;
    };

    const onEditorStateChange = (editor: EditorType) => {
        setEditorState(editor);
        const update = draftToHtml(convertToRaw(editor.getCurrentContent()));
        setValue(update);
    };

    useEffect(() => {}, []);
    const config = {
        image: { uploadCallback }
    };
    return (
        <Editor
            toolbar={config}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
        />
    );
};

export default ReactDraftWysiwyg;

import { IconSitemap } from '@tabler/icons';

const icons = {
    IconSitemap
};

const routingRoute = {
    id: 'routing-menu',
    type: 'group',
    children: [
        {
            id: 'Routing list',
            title: 'Routing',
            type: 'item',
            icon: icons.IconSitemap,
            url: '/routing/list'
        }
    ]
};

export default routingRoute;

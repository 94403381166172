import { useDispatch, useSelector } from 'react-redux';

// material-ui
import {
    Box,
    Button,
    Drawer,
    Grid,
    Typography,
    Autocomplete,
    CardMedia,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    OutlinedInput,
    InputAdornment,
    IconButton,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress
} from '@mui/material';

// third party
import * as yup from 'yup';
import { Chance } from 'chance';
import { useFormik } from 'formik';

// project imports

// assets
import CancelIcon from '@mui/icons-material/Cancel';
// types
import { DefaultRootStateProps } from 'types';
import { gridSpacing } from '../../../store/constant';
import { IconSearch } from '@tabler/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/system';
import React, { useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import useAxios from '../../../hooks/useAxios';
import { driverListProfile, userListProfile } from '../../../_mockApis/user-profile/types';
import Avatar from '../../../ui-component/extended/Avatar';
import Chip from '../../../ui-component/extended/Chip';
import { addressFormat, addressNullCheck } from '../../../utils/common';

interface Props {
    open: boolean;
    handleDrawerOpen: () => void;
    addDriver: (rows: driverListProfile) => void;
}

const chance = new Chance();

const avatarImage = require.context('assets/images/users', true);

const validationSchema = yup.object({
    search: yup.string().required('search text required')
});

function createData(
    Profile: any,
    name: string,
    designation: string,
    badgeStatus: string,
    subContent: string,
    email: string,
    phone: string,
    location: string,
    progressValue: string,
    status: string,
    done: number
) {
    return { Profile, name, designation, badgeStatus, subContent, email, phone, location, progressValue, status, done };
}

const ChairtyDrawer = ({ open, handleDrawerOpen, addDriver }: Props) => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [status, setStatus] = useState<0 | 1 | 2>(0);
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
        status: number;
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC',
        status: 0
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/users/list/charity', { ...listParam });
        setList(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    React.useEffect(() => {
        getData();
    }, [listParam, status]);
    const [list, setList] = useState<userListProfile[]>([]);

    return (
        <Drawer
            sx={{
                ml: open ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 450 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 450 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={open}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            <Box sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                    <Grid container justifyContent="space-between" alignItems="center" item xs={12}>
                        <Grid item xs={8}>
                            <Typography variant="h3">Charity User</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton size="small" onClick={handleDrawerOpen}>
                                <CancelIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table
                        sx={{
                            '& td': {
                                whiteSpace: 'nowrap'
                            },
                            '& td:first-child': {
                                pl: 0
                            },
                            '& td:last-child': {
                                pr: 0
                            },
                            '& tbody tr:last-child  td': {
                                borderBottom: 'none'
                            },
                            [theme.breakpoints.down('xl')]: {
                                '& tr:not(:last-child)': {
                                    borderBottom: '1px solid',
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'rgb(132, 146, 196, .2)' : 'rgba(224, 224, 224, 1)'
                                },
                                '& td': {
                                    display: 'inline-block',
                                    borderBottom: 'none',
                                    pl: 0
                                },
                                '& td:first-child': {
                                    display: 'block'
                                },
                                '& td:last-child': {
                                    display: 'block'
                                }
                            }
                        }}
                    >
                        <TableBody>
                            {list.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Grid item sm zeroMinWidth>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography align="left" variant="subtitle1">
                                                                {row.userFirstName} {row.userName}
                                                                {row.userWait === 1 && (
                                                                    <CheckCircleIcon
                                                                        sx={{ color: 'success.dark', width: 14, height: 14 }}
                                                                    />
                                                                )}
                                                            </Typography>
                                                            <Typography
                                                                align="left"
                                                                variant="subtitle2"
                                                                sx={{ whiteSpace: 'break-spaces' }}
                                                            >
                                                                {/* eslint-disable-next-line no-nested-ternary */}
                                                                {row.businessName}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} overflow={'wrap'} whiteSpace={'pre-wrap'}>
                                                            <Typography variant="caption">Address</Typography>
                                                            <Typography variant="h6">
                                                                {row.userAddress}
                                                                {addressFormat(row.userZip, row.userAddressMore)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">Email</Typography>
                                                        <Typography variant="h6">{row.userEmail}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">Phone</Typography>
                                                        <Typography variant="h6">{row.userPhone}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">Credit</Typography>
                                                        <Typography variant="h6">${row.credit}.00</Typography>
                                                    </Grid>
                                                    {/* <Grid item xs={12}> */}
                                                    {/*    <Typography variant="caption">Status</Typography> */}
                                                    {/*    <Typography variant="h6">{row.userStatus}</Typography> */}
                                                    {/* </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} container spacing={1} justifyContent="flex-end">
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        sx={{ minWidth: 80 }}
                                                        onClick={() => addDriver(row)}
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Add
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Drawer>
    );
};
export default ChairtyDrawer;

import { useTheme } from '@mui/material/styles';
import {
    AvatarGroup,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';

import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddIcon from '@mui/icons-material/Add';

import React, { useEffect, useState } from 'react';
import ChairtyDrawer from './drawer/charity.drawer';
import { driverListProfile, userListProfile } from '../../_mockApis/user-profile/types';
import { addressFormat, addressNullCheck } from '../../utils/common';

interface driverRouteCardType {
    Profile: any;
    name: string;
    designation: string;
    badgeStatus: string;
    subContent: string;
    email: string;
    phone: string;
    location: string;
    progressValue: string;
    status: string;
    done: number;
}

function createData(
    Profile: any,
    name: string,
    designation: string,
    badgeStatus: string,
    subContent: string,
    email: string,
    phone: string,
    location: string,
    progressValue: string,
    status: string,
    done: number
) {
    return { Profile, name, designation, badgeStatus, subContent, email, phone, location, progressValue, status, done };
}

interface Props {
    driverHandler?: (profile: userListProfile) => void;
    initProfile?: userListProfile;
}

const ChairtyUserAdd = ({ driverHandler, initProfile }: Props) => {
    const theme = useTheme();
    const [list, setList] = useState<userListProfile[]>([]);

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const handleDrawerOpen = () => {
        setOpenDrawer((prevState) => !prevState);
    };
    const addDriver = (row: userListProfile) => {
        setOpenDrawer((prevState) => !prevState);
        setList([...[row]]);
        if (driverHandler) {
            driverHandler(row);
        }
    };

    const getDriverProfile = (profile) => {
        if (profile) {
            setList([...[profile]]);
        }
    };

    useEffect(() => {
        console.log('initPrifile', initProfile);
        getDriverProfile(initProfile);
    }, [initProfile]);

    return (
        <>
            {list.length > 0 ? (
                <TableContainer>
                    <Table
                        sx={{
                            '& td': {
                                whiteSpace: 'nowrap'
                            },
                            '& td:first-child': {
                                pl: 0
                            },
                            '& td:last-child': {
                                pr: 0
                            },
                            '& tbody tr:last-child  td': {
                                borderBottom: 'none'
                            },
                            [theme.breakpoints.down('xl')]: {
                                '& tr:not(:last-child)': {
                                    borderBottom: '1px solid',
                                    borderBottomColor: theme.palette.mode === 'dark' ? 'rgb(132, 146, 196, .2)' : 'rgba(224, 224, 224, 1)'
                                },
                                '& td': {
                                    display: 'inline-block',
                                    borderBottom: 'none',
                                    pl: 0
                                },
                                '& td:first-child': {
                                    display: 'block'
                                },
                                '& td:last-child': {
                                    display: 'block'
                                }
                            }
                        }}
                    >
                        <TableBody>
                            {list.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Grid item sm zeroMinWidth>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Typography align="left" variant="subtitle1">
                                                                {row.userFirstName} {row.userName}
                                                                {row.userWait === 1 && (
                                                                    <CheckCircleIcon
                                                                        sx={{ color: 'success.dark', width: 14, height: 14 }}
                                                                    />
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {`${row.businessName}`}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="caption">Address</Typography>
                                                            <Typography variant="h6">
                                                                {row.userAddress ?? ''}
                                                                {addressFormat(row.userZip, row.userAddressMore)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">Email</Typography>
                                                        <Typography variant="h6">{row.userEmail}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">Phone</Typography>
                                                        <Typography variant="h6">{row.userPhone}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">Credit</Typography>
                                                        <Typography variant="h6">${row.credit}.00</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} container spacing={1}>
                                                <Grid item xs={6}>
                                                    {' '}
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        fullWidth
                                                        size="small"
                                                        sx={{ minWidth: 80 }}
                                                        onClick={() => handleDrawerOpen()}
                                                        startIcon={<ManageAccountsIcon />}
                                                    >
                                                        Change
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} spacing={2} marginBottom={4}>
                        <Tooltip title="Add Driver">
                            <Button variant="contained" size="small" color="secondary" onClick={handleDrawerOpen} endIcon={<AddIcon />}>
                                ADD User
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            )}
            <ChairtyDrawer open={openDrawer} handleDrawerOpen={handleDrawerOpen} addDriver={addDriver} />
        </>
    );
};

export default ChairtyUserAdd;

import { bagListType, pickListTypes } from '../types/pick';
import { RouteRootList } from '../views/pick/pickListWithDepot';
import { PolyLineDataType } from '../views/pick/route.optimasation';
import { isValid } from 'date-fns';
import { PickItemMaterialInitValueType } from '../types/cal.type';

const Common = {};

export const ToastSetting = {
    default: {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    },
    top: {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    }
};

export const TransactionTypeCase = (status: number) => {
    switch (status) {
        case 0:
            return 'GiftPay';
        case 2:
            return 'Paypal';
        case 1:
            return 'Donate';
        case 3:
            return 'Buy';
        case 4:
            return 'Refund';
        case 6:
            return 'Promotion';
        case 9:
            return 'Admin';
        default:
            return '';
    }
};

export const BookStatusCase = (status: number | undefined) => {
    switch (status) {
        case 1:
            return 'Booked';
        case 2:
            return 'Assigned';
        case 3:
            return 'Collected';
        case 4:
            return 'Delivered';
        case 5:
            return 'Credited';
        case 6:
            return 'Processing';
        case 9:
            return 'Cancelled';
        default:
            return '';
    }
};

export const TransactionBagCase = (status: number) => {
    switch (status) {
        case 1:
            return 'RECAN Bag';
        case 2:
            return 'Rubbish Bag';
        case 3:
            return 'Wheelie Bin';
        case 4:
            return 'Bulk Bag';
        case 5:
            return 'Coex Bag';
        default:
            return '';
    }
};

export const addressNullCheck = (text) => {
    if (!text) return '';
    return ` ,${text}`;
};

export const addressFormat = (postCode, addressMore) => {
    // if (!addressMore || addressMore === '') return '';
    // if (!postCode) return '';
    const address = ` ${addressMore ?? ''} ${addressNullCheck(postCode)}`;
    const regExp = /[,]/gi;
    const replaceAddress = address.replace(regExp, '');
    return replaceAddress;
};

export const addressFormat2 = (postCode, first, addressMore) => {
    // if (!addressMore || addressMore === '') return '';
    // if (!postCode) return '';
    console.log('Addres', addressMore);
    const address = `${first ?? ''} ${addressMore ?? ''} ${addressNullCheck(postCode)}`;
    const regExp = /[,]/gi;
    const replaceAddress = address.replace(regExp, '');
    return replaceAddress;
};

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const bagCalcSwitch = (list: bagListType) => {
    switch (list.bagType) {
        case 1:
        case 2:
            return list.bagEa * 100;
        case 3:
            return list.bagEa * 240;
        case 4:
            return list.bagEa * 1200;
        default:
            return 0;
    }
};

export const bagTypeRending = (list: RouteRootList[]) => {
    let one = 0;
    let two = 0;
    let three = 0;
    let four = 0;
    let five = 0;

    let bagList: bagListType[] = [];
    for (const row of list) {
        if (row.type === 'pick') {
            if (row.info.bags) {
                bagList = [...bagList, ...(row.info.bags ?? [])];
            }
        }
    }
    for (const row of bagList) {
        if (row.bagType === 1) {
            one += row.bagEa;
        } else if (row.bagType === 2) {
            two += row.bagEa;
        } else if (row.bagType === 3) {
            three += row.bagEa;
        } else if (row.bagType === 4) {
            four += row.bagEa;
        } else if (row.bagType === 5) {
            five += row.bagEa;
        }
    }
    return { one, two, three, four, five };
};

export const bagTypeRendingForPickList = (rows: pickListTypes) => {
    let one = 0;
    let two = 0;
    let three = 0;
    let four = 0;
    let five = 0;

    let bagList: bagListType[] = [];
    if (rows.bags) {
        bagList = [...bagList, ...(rows.bags ?? [])];
    }

    for (const row of bagList) {
        if (row.bagType === 1) {
            one += row.bagEa;
        } else if (row.bagType === 2) {
            two += row.bagEa;
        } else if (row.bagType === 3) {
            three += row.bagEa;
        } else if (row.bagType === 4) {
            four += row.bagEa;
        } else if (row.bagType === 5) {
            five += row.bagEa;
        }
    }
    return { one, two, three, four, five };
};

export const bagTypeRendingRows = (list: bagListType[]) => {
    let one = 0;
    let two = 0;
    let three = 0;
    let four = 0;
    let five = 0;

    for (const row of list) {
        if (row.bagType === 1) {
            one += row.bagEa;
        } else if (row.bagType === 2) {
            two += row.bagEa;
        } else if (row.bagType === 3) {
            three += row.bagEa;
        } else if (row.bagType === 4) {
            four += row.bagEa;
        } else if (row.bagType === 5) {
            five += row.bagEa;
        }
    }
    return { one, two, three, four, five };
};

export const bagCalc = (list: bagListType[]) => {
    if (!list || list.length === 0) {
        return `${0} L`;
    }
    let l = 0;
    for (const row of list) {
        l += bagCalcSwitch(row);
    }
    return `${numberWithCommas(l)} L`;
};

export const bagCalcOnlyNumber = (list: bagListType[]) => {
    if (!list || list.length === 0) {
        return 0;
    }
    let l = 0;
    for (const row of list) {
        l += bagCalcSwitch(row);
    }
    return l;
};

export const bagCalcOnlyNumberEA = (list: bagListType[]) => {
    if (!list || list.length === 0) {
        return 0;
    }
    let l = 0;
    for (const row of list) {
        l += row.bagEa;
    }
    return l;
};

export const allBagCalc = (list: RouteRootList[]) => {
    let l = 0;
    for (const row of list) {
        if (row.type === 'pick') {
            const info = row.info.bags;
            const add = bagCalcOnlyNumber(info);
            l += add;
        }
    }
    return l;
};

export const allBagCalcEa = (list: RouteRootList[]) => {
    let l = 0;
    for (const row of list) {
        if (row.type === 'pick') {
            const info = row.info.bags;
            const add = bagCalcOnlyNumberEA(info);
            l += add;
        }
    }
    return l;
};

export const totalTimeGroupCalc = (list: PolyLineDataType[], group) => {
    const find = list.find((v) => v.group === group);
    if (!find) {
        return 0;
    }
    return find?.totalTime;
};

export function sleep(milliSeconds) {
    const startTime = new Date().getTime();
    while (new Date().getTime() < startTime + milliSeconds);
}

export const totalTimeAllCalc = (list: PolyLineDataType[]) => {
    let t = 0;
    for (const row of list) {
        t += row.totalTime;
    }
    return timeCalcHelper(t);
};

export const timeCalcHelper = (time: number) => {
    const h = Math.floor(time / 3600);
    const m = Math.floor((time % 3600) / 60);

    if (h >= 1) {
        return `${h}h ${m}m`;
    } else {
        return `${m}m`;
    }
};

export const dateCurrentTime = (date: string) => {
    try {
    } catch (e) {
        return;
    }
    if (isValid(date)) {
    }
};

export const NumberFixed = (n: number) => {
    if (isNaN(n)) {
        return (0.0).toFixed(2);
    }
    return n.toFixed(2);
};

export const AllBagItemCal = (list: any[]) => {
    let result = 0;
    for (const row of list) {
        result += RowBagItemCal(row);
    }
    return result > 0 ? result : 0;
};

export const RowBagItemCal = (list: PickItemMaterialInitValueType) => {
    let result = 0;
    const itemValues = Object.entries(list);
    for (const [key, value] of itemValues) {
        if (key !== 'ineligible') {
            if (!isNaN(value) && value > 0) {
                result += value;
            }
        }
    }
    return result;
};

export const BagMaterialPercentage = (type, value) => {
    if (type === '1') {
        switch (true) {
            case value >= 0 && value <= 100:
                return [0.05, 5, value * 0.05];
            case value >= 101 && value <= 150:
                return [0.055, value * (0.1 - 0.055), value * 0.055];
            case value >= 151 && value <= 200:
                return [0.06, value * (0.1 - 0.06), value * 0.06];
            case value >= 201 && value <= 300:
                return [0.065, value * (0.1 - 0.065), value * 0.065];
            case value >= 301 && value <= 500:
                return [0.07, value * (0.1 - 0.07), value * 0.07];
            case value >= 501 && value <= 800:
                return [0.075, value * (0.1 - 0.075), value * 0.075];
            case value >= 801 && value <= 1000:
                return [0.08, value * (0.1 - 0.08), value * 0.08];
            case value >= 1001 && value <= 4000:
                return [0.09, value * (0.1 - 0.09), value * 0.09];
            case value >= 4001:
                return [0.1, value * (0.1 - 0.1), value * 0.1];
            default:
                return [0, 0, 0];
        }
    } else if (type === '2') {
        switch (true) {
            case value >= 0 && value <= 200:
                return [0.06, 8, value * 0.06];
            case value >= 201 && value <= 500:
                return [0.07, value * (0.1 - 0.07), value * 0.07];

            case value >= 501 && value <= 1000:
                return [0.08, value * (0.1 - 0.08), value * 0.08];

            case value >= 1001 && value <= 4000:
                return [0.09, value * (0.1 - 0.09), value * 0.09];
            case value >= 4001:
                return [0.1, value * (0.1 - 0.1), value * 0.1];
            default:
                return [0, 0, 0];
        }
    } else if (type === '3') {
        switch (true) {
            case value >= 0 && value <= 200:
                return [0.06, 8, value * 0.06];
            case value >= 201 && value <= 500:
                return [0.07, value * (0.1 - 0.07), value * 0.07];
            case value >= 501 && value <= 1000:
                return [0.08, value * (0.1 - 0.08), value * 0.08];
            case value >= 1001:
                return [0.1, value * (0.1 - 0.1), value * 0.1];
            default:
                return [0, 0, 0];
        }
    } else {
        return [0, 0, 0];
    }
};

export const BagMaterialTypeSwitch = (type, value) => {
    if (type === '1') {
        switch (true) {
            case value >= 0 && value <= 100:
                console.log('d', value * 0.05);
                return value * 0.05;
            case value >= 101 && value <= 150:
                return value * 0.055;
            case value >= 151 && value <= 200:
                return value * 0.06;
            case value >= 201 && value <= 300:
                return value * 0.065;
            case value >= 301 && value <= 500:
                return value * 0.07;
            case value >= 501 && value <= 800:
                return value * 0.075;
            case value >= 801 && value <= 1000:
                return value * 0.08;
            case value >= 1001 && value <= 4000:
                return value * 0.09;
            case value >= 4001:
                return value * 0.1;
            default:
                return 0;
        }
    } else if (type === '2') {
        switch (true) {
            case value >= 0 && value <= 200:
                return value * 0.06;
            case value >= 201 && value <= 500:
                return value * 0.07;
            case value >= 501 && value <= 1000:
                return value * 0.08;
            case value >= 1001 && value <= 4000:
                return value * 0.09;
            case value >= 4001:
                return value * 0.1;
            default:
                return 0;
        }
    } else if (type === '3') {
        switch (true) {
            case value >= 0 && value <= 200:
                return value * 0.06;
            case value >= 201 && value <= 500:
                return value * 0.07;
            case value >= 501 && value <= 1000:
                return value * 0.08;
            case value >= 1001:
                return value * 0.1;
            default:
                return 0;
        }
    } else {
        return 0;
    }
};

export const MinimalBagMaterialTypeSwitch = (type, value) => {
    switch (type) {
        case '1':
            return value > 5 ? roundToTwo(value) : 0;
        case '2':
            return value >= 8 ? roundToTwo(value) : 0;
        case '3':
            return value >= 8 ? roundToTwo(value) : 0;
    }
};

export function roundToTwo(num) {
    return +`${Math.round(Number(`${num}e+1`))}e-1`;
}

export const BagMaterialCalResult = (type, list) => {
    const value = AllBagItemCal(list);
    console.log('valuevaluevaluevalue', value);

    const cal = BagMaterialTypeSwitch(type, value);
    console.log('BagMaterialTypeSwitch', cal);

    return `${MinimalBagMaterialTypeSwitch(type, cal).toFixed(2)}`;
};

export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const PickTypeConvert = (prefix, number) => {
    const convert = number > 99 ? String(number) : `0${String(number)}`;
    return `${prefix}-${convert}`;
};

export default Common;

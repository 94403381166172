import { useEffect, useState } from 'react';

// material-ui
import { Button, Grid } from '@mui/material';

// project imports
import EarningCard from '../dashboard/EarningCard';
// import TotalOrderLineChartCard from './TotalOrderLineChartCard';
// import TotalIncomeDarkCard from './TotalIncomeDarkCard';
// import TotalIncomeLightCard from './TotalIncomeLightCard';
import { gridSpacing } from 'store/constant';
import useAxios from '../../hooks/useAxios';
import SubCard from '../../ui-component/cards/SubCard';
import { collectionList, pickListTypes } from '../../types/pick';
import { useNavigate } from 'react-router-dom';
import TotalOrderLineChartCard from '../dashboard/TotalOrderLineChartCard';
import LineChart from '../dashboard/LineChart';
import RevenueCard from '../../ui-component/cards/RevenueCard';
import { useTheme } from '@mui/system';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';
// ==============================|| DEFAULT DASHBOARD ||============================== //

const OrderStatstics = () => {
    const { axiosService } = useAxios();
    const theme = useTheme();
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        getData();
    }, []);
    const [yesterDayValue, setYesterDayValue] = useState(0);
    const [todayDayValue, setTodayValue] = useState(0);
    const getData = async () => {
        try {
            const api = await axiosService.post('/statistics/order/value');
            const { status, cValue, rValue } = api.data;
            if (status) {
                setYesterDayValue(cValue ?? 0);
                setTodayValue(rValue ?? 0);
            }
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    const navigate = useNavigate();

    const [init, setInit] = useState<{ users: boolean; drivers: boolean; bags: boolean; report: boolean }>({
        users: true,
        drivers: true,
        bags: true,
        report: true
    });
    const [iinit, setiinit] = useState(true);

    const choiceHandler = (value) => {
        if (iinit && init.bags && init.drivers && init.bags && init.report) {
            const tempData = {};
            tempData[`${value}`] = true;
            console.log('temp', tempData);
            const temp = {
                bags: false,
                users: false,
                drivers: false,
                report: false,
                ...tempData
            };
            setInit({ ...temp });
            setiinit(false);
        } else {
            const tempData = {};
            tempData[`${value}`] = !init[value];

            const temp = {
                ...init,
                ...tempData
            };
            setInit({ ...temp });
            setiinit(false);
        }
    };

    console.log(init);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={6}>
                        <RevenueCard
                            primary="Revenue"
                            secondary={`${yesterDayValue}.00`}
                            content={`${'  '}`}
                            iconPrimary={MonetizationOnTwoToneIcon}
                            color={theme.palette.secondary.main}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <RevenueCard
                            primary="Deficit"
                            secondary={`${todayDayValue}.00`}
                            content={`${'  '}`}
                            iconPrimary={CreditCardTwoToneIcon}
                            color={theme.palette.primary.main}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={12}>
                        <LineChart isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}> */}
            {/*    <Grid container spacing={gridSpacing}> */}
            {/*        <Grid item xs={12} md={12}> */}
            {/*            <PopularCard isLoading={isLoading} /> */}
            {/*        </Grid> */}
            {/*    </Grid> */}
            {/* </Grid> */}
        </Grid>
    );
};

export default OrderStatstics;

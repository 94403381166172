import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputAdornment,
    InputBase,
    InputLabel,
    List,
    ListItem,
    OutlinedInput,
    TextField,
    Typography
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import useAxiosServices from '../../../hooks/useAxios';
import { SuburbListData } from '../../../types/suburb.type';
import { useDebounce } from '../../../hooks/useDebounce';
import { useTheme } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { SuburbWeekAddList } from '../suburb.week.add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SuburbFrequencyAddBox } from '../suburb.frequency.add';
import { SuburbActiveSelect } from '../suburb.active.select';
import { locationListType } from '../../../types/pick';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../../utils/common';
import { IconSearch } from '@tabler/icons';

export interface Props {
    open: boolean;
    handleClose: Dispatch<SetStateAction<Boolean>>;
}
export const SubAddBox = ({ open, handleClose }: Props) => {
    const theme = useTheme();
    console.log('theme.palette.primary.light', theme);
    const [query, setQuery] = useState('');
    const [suburbs, setSuburbs] = useState<SuburbListData[]>([]);
    const [loading, setLoading] = useState(false);
    const { axiosService } = useAxiosServices();
    const [selectedSuburb, setSelectedSuburb] = useState<null | SuburbListData>(null);
    const handleListItemClick = (suburb: SuburbListData) => {
        if (suburb.check) {
            return null;
        }
        setSelectedSuburb(selectedSuburb === suburb ? null : suburb);
    };
    const fetchSuburbs = async () => {
        if (query.length < 2) {
            setSuburbs([]);
            return null;
        }
        setSelectedSuburb(null);
        setLoading(true);
        try {
            const api = await axiosService.post('/address/searchService', { query });
            console.log('api.data', api.data);
            setSuburbs([...api.data]);
            // setSuburbs(response.data.predictions.slice(0, 10));
        } catch (error) {
            console.error('Error fetching suburbs:', error);
            setSuburbs([]);
        }
        setLoading(false);
    };
    useDebounce(fetchSuburbs, 300, [query]);
    const [area, setArea] = useState('');
    const [council, setCouncil] = useState('');
    const [basic, setBasic] = useState('0');
    const [business, setBusiness] = useState('0');
    const [charity, setCharity] = useState('0');
    const [school, setSchool] = useState('0');
    const [weekList, setWeekList] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [memo, setMemo] = useState('');
    const handleWeekList = (index: number) => {
        // eslint-disable-next-line no-nested-ternary
        const convertData = weekList.map((v, i) => (i === index ? (v === 0 ? 1 : 0) : v));
        setWeekList([...convertData]);
    };
    const [frequency, setFrequency] = useState('1');
    const [active, setActive] = useState('1');

    const saveData = async () => {
        console.log('Ddd');
        if (area.length === 0 || council.length === 0) {
            alert('Please Enter area or council');
            return null;
        }
        const api = await axiosService.post('/address/suburb/add', {
            active,
            frequency,
            area,
            council,
            weekList,
            suburbIdx: selectedSuburb.idx,
            school,
            charity,
            business,
            basic,
            remark: memo
        });
        toast.info(`${selectedSuburb.suburb} Saved!`, { ...ToastSetting.default });
        const { data } = api;
        if (data) {
            handleClose(false);
        }
    };
    console.log('basic', basic);
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="item-delete-title"
            aria-describedby="item-delete-description"
        >
            <DialogTitle id="add-row-title">{'Add Suburb'}</DialogTitle>
            <DialogContent sx={{ paddingTop: '20px !important' }}>
                <>
                    <OutlinedInput
                        placeholder="Search Suburbs"
                        value={query}
                        style={{ display: selectedSuburb ? 'none' : 'flex' }}
                        endAdornment={
                            <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                                {loading && <CircularProgress size={'1.5rem'} />}
                            </InputAdornment>
                        }
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    <AnimatePresence>
                        {!selectedSuburb && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }} // 선택 해제 시 역방향 애니메이션 정의
                                transition={{ duration: 0.5 }}
                            >
                                <List>
                                    {suburbs.map((suburb, index) => (
                                        <motion.div
                                            key={index}
                                            initial={{ opacity: 1 }}
                                            exit={{ opacity: 0, height: 0, overflow: 'hidden', transition: { duration: 0.3 } }}
                                        >
                                            <ListItem
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    '&:hover': { backgroundColor: '#e2e2e2' },
                                                    borderRadius: '5px',
                                                    backgroundColor: suburb.check ? '#e2e2e2' : '#fff',
                                                    marginBottom: '2px',
                                                    cursor: suburb.check ? 'not-allowed' : 'pointer',
                                                    transition: 'all 0.3s ease-in-out'
                                                }}
                                                onClick={() => handleListItemClick(suburb)}
                                            >
                                                <div>{`${suburb.suburb} ${suburb.area} ${suburb.postcode}`}</div>
                                                {suburb.check && <div style={{ color: theme.palette.primary.main }}>Added</div>}
                                            </ListItem>
                                        </motion.div>
                                    ))}
                                </List>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </>

                {selectedSuburb && (
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: -10 }}
                        exit={{ opacity: 0, y: 30 }} // 선택 해제 시 역방향 애니메이션 정의
                        transition={{ duration: 0.5 }}
                    >
                        <Grid
                            sx={{ cursor: 'pointer' }}
                            container
                            item
                            xs={4}
                            display={'flex'}
                            flexDirection={'column'}
                            onClick={() => setSelectedSuburb(null)}
                        >
                            <ArrowBackIcon />
                        </Grid>
                        <ListItem
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                '&:hover': { backgroundColor: '#e2e2e2' },
                                borderRadius: '5px',
                                backgroundColor: selectedSuburb.check ? '#e2e2e2' : '#fff',
                                marginBottom: '2px',
                                cursor: selectedSuburb.check ? 'not-allowed' : 'pointer',
                                opacity: selectedSuburb ? 1 : 0,
                                transition: 'all 0.3s ease-in-out'
                            }}
                            onClick={() => setSelectedSuburb(null)}
                        >
                            <div>{`${selectedSuburb.suburb} ${selectedSuburb.area} ${selectedSuburb.postcode}`}</div>
                            {selectedSuburb.check && <div style={{ color: theme.palette.primary.main }}>Added</div>}
                        </ListItem>
                        <Grid
                            style={{
                                marginTop: '10px',
                                width: '100%',
                                height: '300px',
                                transition: 'opacity 0.3s ease-in-out',
                                opacity: 1
                            }}
                        >
                            <Grid container xs={12}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <div>Area</div>
                                        <TextField
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    height: '30px',
                                                    padding: '5px 10px' // 패딩 조정
                                                }
                                            }}
                                            value={area}
                                            onChange={(e) => setArea(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>Council</div>
                                        <TextField
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    height: '30px',
                                                    padding: '5px 10px' // 패딩 조정
                                                }
                                            }}
                                            value={council}
                                            onChange={(e) => setCouncil(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid marginTop={'10px'} container item xs={12} display={'flex'} flexDirection={'column'}>
                                    <Grid display={'flex'}>
                                        <SuburbWeekAddList data={weekList} setData={handleWeekList} />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={8} mt={'20px'} mb={'10px'}>
                                    <Typography variant={'body1'} width={'200px'}>
                                        {`Frequency`}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={8} display={'flex'} flexDirection={'column'}>
                                    <SuburbFrequencyAddBox weekly={frequency} setWeekly={setFrequency} />
                                </Grid>
                                <Grid container item xs={12} mt={'20px'} mb={'10px'}>
                                    <Typography variant={'body1'} width={'200px'}>
                                        {`Available`}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={8} display={'flex'} flexDirection={'column'}>
                                    <SuburbActiveSelect active={active} setActive={setActive} />
                                </Grid>
                                <Grid container item xs={12} marginTop={'20px'}>
                                    <Grid item xs={6}>
                                        <div>Basic</div>
                                        <TextField
                                            type="number"
                                            inputProps={{
                                                step: '1',
                                                min: '0', // 최소값을 0으로 설정 (음수 입력 방지)
                                                pattern: '\\d*' // 숫자만 입력받도록 정규식 패턴 설정
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    height: '30px',
                                                    padding: '5px 10px' // 패딩 조정
                                                }
                                            }}
                                            value={basic !== '0' ? basic : ''}
                                            onChange={(e) => setBasic(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>Business</div>
                                        <TextField
                                            type="number"
                                            inputProps={{
                                                step: '1',
                                                min: '0', // 최소값을 0으로 설정 (음수 입력 방지)
                                                pattern: '\\d*' // 숫자만 입력받도록 정규식 패턴 설정
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    height: '30px',
                                                    padding: '5px 10px' // 패딩 조정
                                                }
                                            }}
                                            value={business !== '0' ? business : ''}
                                            onChange={(e) => setBusiness(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} marginTop={'10px'}>
                                    <Grid item xs={6}>
                                        <div>Charity</div>
                                        <TextField
                                            type="number"
                                            inputProps={{
                                                step: '1',
                                                min: '0', // 최소값을 0으로 설정 (음수 입력 방지)
                                                pattern: '\\d*' // 숫자만 입력받도록 정규식 패턴 설정
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    height: '30px',
                                                    padding: '5px 10px' // 패딩 조정
                                                }
                                            }}
                                            value={charity !== '0' ? charity : ''}
                                            onChange={(e) => setCharity(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>School</div>
                                        <TextField
                                            type="number"
                                            inputProps={{
                                                step: '1',
                                                min: '0', // 최소값을 0으로 설정 (음수 입력 방지)
                                                pattern: '\\d*' // 숫자만 입력받도록 정규식 패턴 설정
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    height: '30px',
                                                    padding: '5px 10px' // 패딩 조정
                                                }
                                            }}
                                            value={school !== '0' ? school : ''}
                                            onChange={(e) => setSchool(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} mt={'20px'} mb={'10px'}>
                                    <Typography variant={'body1'} width={'200px'}>
                                        {`Remark`}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    <OutlinedInput
                                        multiline
                                        style={{ background: '#fff' }}
                                        value={memo}
                                        onChange={(e) => setMemo(e.currentTarget.value)}
                                        id={`remarkInput`}
                                        placeholder="Remark"
                                        size="small"
                                        sx={{ width: '100%', pr: 1, pl: 1 }}
                                    />
                                </Grid>
                                <Grid marginTop={'20px'} container item xs={12} display={'flex'} flexDirection={'column'}>
                                    <Button
                                        size="large"
                                        sx={{ color: '#fff' }}
                                        color="primary"
                                        variant={'contained'}
                                        onClick={() => saveData()}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </motion.div>
                )}
            </DialogContent>

            <DialogActions sx={{ mr: 2 }}>
                <Button onClick={() => handleClose(false)} color="error">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

/**
 *
 * @param {Exceljs.Worksheet} sheet
 * @param {String} rangeCell
 * @returns Cell[] References
 */
const selectExcelRange = (sheet, rangeCell) => {
    let [startCell, endCell] = rangeCell.split(':');

    // Recalculate in case bottom left and top right are given
    if (endCell < startCell) {
        const temp = endCell;
        endCell = startCell;
        startCell = temp;
    }

    let [endCellColumn, endRow] = endCell.match(/[a-z]+|[^a-z]+/gi);
    let [startCellColumn, startRow] = startCell.match(/[a-z]+|[^a-z]+/gi);

    // Recalculate in case bottom left and top right are given
    if (endCellColumn < startCellColumn) {
        const temp = endCellColumn;
        endCellColumn = startCellColumn;
        startCellColumn = temp;
    }

    // Recalculate in case bottom left and top right are given
    if (Number(endRow) < Number(startRow)) {
        const temp = endRow;
        endRow = startRow;
        startRow = temp;
    }

    const endColumn = sheet.getColumn(endCellColumn);
    const startColumn = sheet.getColumn(startCellColumn);

    if (!endColumn) throw new Error('End column not found');
    if (!startColumn) throw new Error('Start column not found');

    const endColumnNumber = endColumn.number;
    const startColumnNumber = startColumn.number;

    const cells = [];

    for (let y = parseInt(startRow, 10); y <= parseInt(endRow, 10); y++) {
        const row = sheet.getRow(y);

        for (let x = startColumnNumber; x <= endColumnNumber; x++) {
            cells.push(row.getCell(x));
        }
    }

    return cells;
};
export default selectExcelRange;

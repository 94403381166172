// ==============================|| DASHBOARD - TOTAL GROWTH BAR CHART ||============================== //
import { Props } from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

const useChartData = (data, range, view = { users: true, drivers: true, bags: true, report: true }) => {
    const theme = useTheme();
    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];

    const primary200 = theme.palette.primary[200];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const secondaryLight = theme.palette.secondary.light;
    const dataView = () => {
        const result = [];
        console.log('REEEE', data);
        result.push({
            name: 'Users',
            data: view.users ? data.Users.data ?? [] : []
        });

        result.push({
            name: 'Drivers',
            data: view.drivers ? data.Drivers.data ?? [] : []
        });
        result.push({
            name: 'Bags',
            data: view.bags ? data.Bag.data ?? [] : []
        });

        result.push({
            name: 'Report',
            data: view.report ? data.Report.data ?? [] : []
        });

        return result;
    };

    return {
        height: 480,
        type: 'bar',
        colors: [primary200, primaryDark, secondaryMain, secondaryLight],
        options: {
            export: {
                csv: { headerCategory: 'Date', headerValue: 'ddddd' }
            },
            chart: {
                id: 'bar-chart',
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            xaxis: {
                type: 'category',
                categories: range,
                labels: {
                    style: {
                        colors: [primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary]
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                }
            },
            legend: {
                show: true,
                fontSize: '0.875rem',
                fontFamily: `'Roboto', sans-serif`,
                position: 'top',
                offsetX: 20,
                labels: {
                    useSeriesColors: false,
                    colors: grey500
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: true,
                borderColor: grey200
            },
            tooltip: {
                theme: 'light'
            }
        },
        series: dataView()
    };
};

export default useChartData;

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useTheme } from '@mui/styles';

// types
interface Props {
    title: string;
    content: string;
    open: boolean;
    handleClose: Dispatch<SetStateAction<Boolean>>;
    handleSuccess: () => void;
    isLoading?: boolean;
}

export default function AlertDialog({ title, content, open, handleClose, handleSuccess, isLoading }: Props) {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="item-delete-title"
            aria-describedby="item-delete-description"
        >
            <DialogTitle id="item-delete-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="item-delete-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ mr: 2 }}>
                <Button onClick={() => handleClose(false)} color="error">
                    Cancel
                </Button>
                <Button variant="contained" size="small" onClick={() => handleSuccess()} autoFocus disabled={isLoading}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

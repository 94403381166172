import React, { useState } from 'react';
import { Grid, Button, TextField, Typography, FormGroup, FormControlLabel, Checkbox, Box } from '@mui/material';
import { useTheme } from '@mui/system';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { gridSpacing } from '../../store/constant';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import { userListProfile } from '../../_mockApis/user-profile/types';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { CheckBox } from '@mui/icons-material';
import { DatePicker, DateRangePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useHref, useLocation, useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
    title: yup.string().required('title is required'),
    content: yup
        .string()
        .min(2, 'content should be of minimum 2 characters length')
        .max(100, 'content should be of max 100 characters length')
        .required('content is required')
});

interface Props {
    type: any;
}

const PushAppAll = ({ type }: Props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const formik = useFormik({
        initialValues: {
            title: '',
            content: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            await sendPush();
        }
    });
    const { axiosService } = useAxiosServices();
    const sendPush = async () => {
        if (r) {
            if (!rangeDate) {
                alert('The time of reservation was incorrectly entered or invalid.');
                return;
            }
        }

        const api = await axiosService.post('push/all', {
            type,
            title: formik.values.title,
            content: formik.values.content,
            re: r,
            time: rangeDate
        });
        const { status } = api.data;
        if (status) {
            toast.info('Success Send to notification', { ...ToastSetting.default });
            navigation(0);
        } else {
            toast.error('Failed Send to notification', { ...ToastSetting.default });
        }
    };

    const [r, setR] = useState(false);
    const [rangeDate, setRangeDate] = useState(null);
    const [error, setError] = useState(false);
    console.log('ERROR', error);
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} mt={4}>
                <TextField
                    id="title"
                    fullWidth
                    label="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="content"
                    fullWidth
                    label="Content"
                    value={formik.values.content}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.content && Boolean(formik.errors.content)}
                    helperText={formik.touched.content && formik.errors.content}
                />
            </Grid>
            <Grid item container>
                <Grid>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value={r} onChange={() => setR((p) => !p)} />} label="reservation message" />
                    </FormGroup>
                </Grid>
                {r && (
                    <Grid>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid item ml={2}>
                                <DateTimePicker
                                    onError={() => setError(true)}
                                    onAccept={() => setError(false)}
                                    value={rangeDate}
                                    minDateTime={new Date()}
                                    onChange={(newValue) => {
                                        setRangeDate(newValue);
                                    }}
                                    renderInput={(props) => (
                                        <>
                                            <TextField {...props} />
                                        </>
                                    )}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                )}
            </Grid>

            <Grid container item xs={12} justifyContent={'flex-end'}>
                <Button onClick={() => formik.handleSubmit()}>Send</Button>
            </Grid>
        </Grid>
    );
};

export default PushAppAll;

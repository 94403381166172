import React from 'react';
import { pickListTypes } from '../../types/pick';
import { Box, Grid, Link, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { FindPickProfileTypeStyle } from '../../utils/const/pick.const';
import { useTheme } from '@mui/system';

interface Props {
    value: pickListTypes;
}
const PickPageProfile = ({ value }: Props) => {
    const navigation = useNavigate();
    const theme = useTheme();
    if (value.pickSave === 'R') {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item xs zeroMinWidth>
                    <>
                        <Typography display={'flex'} mb={1} align="left" variant="subtitle1" component="div" sx={{ alignItems: 'center' }}>
                            <Link
                                onClick={() => navigation(`/user/default/${value.userIdx}`)}
                                underline="hover"
                                color="default"
                                sx={{
                                    overflow: 'hidden',
                                    display: 'block',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    ':hover': { color: 'primary.main' },
                                    cursor: 'pointer'
                                }}
                            >
                                {value.userType === 1
                                    ? `${value.user?.userFirstName} ${value.user?.userName}`
                                    : `${value.user?.businessName}`}
                            </Link>
                            {value.user?.userStatus === 1 && (
                                <CheckCircleIcon
                                    sx={{
                                        ml: 1,
                                        color: FindPickProfileTypeStyle(theme, value?.pickSave).background,
                                        width: 14,
                                        height: 14
                                    }}
                                />
                            )}
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {value.user?.userEmail}
                        </Typography>
                    </>
                </Grid>
            </Grid>
        );
    } else if (value.pickSave === 'C') {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item xs zeroMinWidth>
                    <Typography display={'flex'} mb={1} align="left" variant="subtitle1" component="div" sx={{ alignItems: 'center' }}>
                        <Link
                            onClick={() => null}
                            underline="hover"
                            color="default"
                            sx={{
                                overflow: 'hidden',
                                display: 'block',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                ':hover': { color: 'primary.main' },
                                cursor: 'pointer'
                            }}
                        >
                            {'Coex'}
                        </Link>
                        <CheckCircleIcon
                            sx={{ ml: 1, color: FindPickProfileTypeStyle(theme, value?.pickSave).background, width: 14, height: 14 }}
                        />
                    </Typography>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.userFirstName} {value.userName}
                    </Typography>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.mobile}
                    </Typography>
                </Grid>
            </Grid>
        );
    } else if (value.pickSave === 'D') {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item xs zeroMinWidth>
                    <Typography display={'flex'} mb={1} align="left" variant="subtitle1" component="div" sx={{ alignItems: 'center' }}>
                        <Link
                            onClick={() => navigation(`/user/default/${value.depotId}`)}
                            underline="hover"
                            color="default"
                            sx={{
                                overflow: 'hidden',
                                display: 'block',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                ':hover': { color: 'primary.main' },
                                cursor: 'pointer'
                            }}
                        >
                            {`Manual / ${value.depotName}`}
                        </Link>
                        <CheckCircleIcon
                            sx={{ ml: 1, color: FindPickProfileTypeStyle(theme, value?.pickSave).background, width: 14, height: 14 }}
                        />
                    </Typography>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.userFirstName} {value.userName}
                    </Typography>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.mobile}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
    return <Box />;
};
export default PickPageProfile;

import { IconBusinessplan } from '@tabler/icons';

const icons = {
    IconBusinessplan
};

const charityRoute = {
    id: 'charity-route',
    type: 'group',
    children: [
        {
            id: 'Charity',
            title: 'Charity List',
            type: 'item',
            icon: icons.IconBusinessplan,
            url: '/charity/list'
        }
    ]
};

export default charityRoute;

import { useEffect, useState } from 'react';

// material-ui
import { Button, Grid } from '@mui/material';

// project imports
import EarningCard from '../dashboard/EarningCard';
import { gridSpacing } from 'store/constant';
import useAxios from '../../hooks/useAxios';
import SubCard from '../../ui-component/cards/SubCard';
import { collectionList, pickListTypes } from '../../types/pick';
import { useNavigate } from 'react-router-dom';
import ShareChart from '../dashboard/ShareChart';
import ShareCard from '../dashboard/ShareCard';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const ShareStatstics = () => {
    const { axiosService } = useAxios();
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        getData();
    }, []);
    const [yesterDayValue, setYesterDayValue] = useState(0);
    const [todayDayValue, setTodayValue] = useState(0);
    const [orderValue, setOrderValue] = useState(0);

    const [salesValue, setSalesValue] = useState(0);
    const [waitPickupList, setWaitPickupList] = useState<pickListTypes[]>([]);
    const [waitPickupListCount, setWaitPickupListCount] = useState<number>(0);

    const getData = async () => {
        try {
            const api = await axiosService.post('/statistics/dashboard/share/all');
            const { status, text, link, more, feed } = api.data;
            if (status) {
                setYesterDayValue(text ?? 0);
                setTodayValue(feed ?? 0);
                setOrderValue(more ?? 0);
                setSalesValue(link ?? 0);
            }
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    const navigate = useNavigate();

    const [init, setInit] = useState<{ text: boolean; feed: boolean; link: boolean; more: boolean }>({
        text: true,
        feed: true,
        link: true,
        more: true
    });
    const [iinit, setiinit] = useState(true);

    const choiceHandler = (value) => {
        if (iinit && init.text && init.feed && init.link && init.more) {
            const tempData = {};
            tempData[`${value}`] = true;
            console.log('temp', tempData);
            const temp = {
                text: false,
                feed: false,
                link: false,
                more: false,
                ...tempData
            };
            setInit({ ...temp });
            setiinit(false);
        } else {
            const tempData = {};
            tempData[`${value}`] = !init[value];

            const temp = {
                ...init,
                ...tempData
            };
            setInit({ ...temp });
            setiinit(false);
        }
    };

    console.log(init);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <ShareCard
                            bool={init.text}
                            choiceType={'text'}
                            choiceHandler={choiceHandler}
                            value={yesterDayValue}
                            title={'SMS'}
                            isLoading={isLoading}
                        />
                    </Grid>{' '}
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <ShareCard
                            bool={init.feed}
                            choiceType={'feed'}
                            choiceHandler={choiceHandler}
                            value={todayDayValue}
                            title={'Feed'}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <EarningCard
                            bool={init.link}
                            choiceType={'link'}
                            choiceHandler={choiceHandler}
                            value={salesValue}
                            title={'Link'}
                            isLoading={isLoading}
                        />
                    </Grid>{' '}
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <EarningCard
                            bool={init.more}
                            choiceType={'more'}
                            choiceHandler={choiceHandler}
                            value={orderValue}
                            title={'Other'}
                            isLoading={isLoading}
                        />
                    </Grid>{' '}
                    {/* <Grid item lg={4} md={12} sm={12} xs={12}> */}
                    {/*    <Grid container spacing={gridSpacing}> */}
                    {/*        <Grid item sm={6} xs={12} md={6} lg={12}> */}
                    {/*            <TotalIncomeDarkCard isLoading={isLoading} /> */}
                    {/*        </Grid> */}
                    {/*        <Grid item sm={6} xs={12} md={6} lg={12}> */}
                    {/*            <TotalIncomeLightCard isLoading={isLoading} /> */}
                    {/*        </Grid> */}
                    {/*    </Grid> */}
                    {/* </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={12}>
                        <ShareChart isLoading={isLoading} init={init} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mb={5}>
                <Grid container spacing={gridSpacing} />
            </Grid>
        </Grid>
    );
};

export default ShareStatstics;

import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import axios from 'utils/axios';
import { MessageListType, userListProfile, UserProfile } from '_mockApis/user-profile/types';

// assets
import useAxios from '../../hooks/useAxios';

import { gridSpacing } from '../../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import UserDownload from '../../components/csv/user.download';

import { MainPush } from '../push/main.push';
import { UserListModeEnum } from '../../utils/const/users.const';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import MessageSelectMultiple from './message.select.multiple';
import MessageListRow from './message.list.row';
import MessageDetail from './message.detail';

// ==============================|| USER LIST 1 ||============================== //

const MessageList = () => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const navigation = useNavigate();
    const [data, setData] = React.useState<MessageListType[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/push/send/list', { ...listParam, type: selectTypes });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    const [selectTypes, setSelectTypes] = useState<number[]>([]);
    const [selectStatus, setSelectStatus] = useState<number[]>([]);
    const [checkUserList, setCheckUserList] = useState<userListProfile[]>([]);

    React.useEffect(() => {
        getData();
    }, [listParam, selectTypes]);

    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };
    const handleUserCheck = (user: userListProfile) => {
        const dumpList = [...checkUserList].map((v) => v.idx);
        if (!dumpList.includes(user.idx)) {
            setCheckUserList((p) => [...p, user]);
        } else {
            const removeList = checkUserList.filter((v) => v.idx !== user.idx);
            setCheckUserList([...removeList]);
        }
    };

    const handleChangeTake = (value) => {
        setListParam((p) => ({ ...p, take: value }));
        setAnchorEl(null);
    };

    const [mode, setMode] = useState<UserListModeEnum>('none');
    const handleMailMode = () => {
        if (mode === 'mail') {
            setMode('none');
        } else {
            setMode('mail');
        }
    };
    const [modalOpen, setModalOpen] = useState(false);
    const [selectIdx, setSelectIdx] = useState(0);

    const handleModalOpen = (idx: number) => {
        setSelectIdx(idx);
        setModalOpen(true);
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <MessageSelectMultiple type={1} selectMenus={selectTypes} setSelectTypes={setSelectTypes} />
            </Box>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 3 }}>Type</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Pushing</TableCell>
                            <TableCell>Reservation Date</TableCell>
                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.map((row, index) => <MessageListRow handleModalOpen={handleModalOpen} row={row} index={index} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={12} sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            sx={{ color: theme.palette.grey[900] }}
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                        >
                            {`${listParam.take} Rows`}
                        </Button>
                        <Menu
                            id="menu-user-list"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            {new Array(10).fill(10).map((v, i) => (
                                <MenuItem onClick={() => handleChangeTake(v + i * v)}> {v + i * v}+</MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
            <MessageDetail idx={selectIdx} open={modalOpen} handleClose={setModalOpen} />
        </>
    );
};

export default MessageList;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormControlLabel, Grid, MenuItem, RadioGroup, TextField, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';

// chart data

import { DefaultRootStateProps } from '../../types';
import useAxiosServices from '../../hooks/useAxios';
import { DateRange, DateRangePicker, LocalizationProvider, StaticDateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Skeleton from '@mui/material/Skeleton';
import useChartData from './chart-data/total-growth-bar-chart';

const status = [
    {
        value: 'today',
        label: 'Today'
    },
    {
        value: 'month',
        label: 'This Month'
    },
    {
        value: 'year',
        label: 'This Year'
    }
];

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

export interface TotalGrowthBarChartProps {
    isLoading: boolean;
    init: any;
}

const monthData = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
];

const radioData = [
    { label: 'Last 7days', value: '2' },
    { label: 'Last 30days', value: '55' },
    { label: 'Monthly', value: '3' },
    { label: 'Quarterly', value: '4' },
    { label: 'Custom', value: '5' }
];

const PostcodeChart = ({ isLoading, init }: TotalGrowthBarChartProps) => {
    const [value, setValue] = React.useState('today');
    const theme = useTheme();
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);

    const { navType } = customization;
    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];

    const primary200 = theme.palette.primary[200];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const secondaryLight = theme.palette.secondary.light;

    const [month, setMonth] = useState('3');
    const [type, setType] = useState('2');
    const [rangeDate, setRangeDate] = React.useState<DateRange<Date>>([null, null]);
    const handleChange = (event: SelectChangeEvent) => {
        console.log(event?.target.value);
        event?.target.value && setMonth(event.target.value as string);
    };

    const handleChangeType = (event: SelectChangeEvent) => {
        console.log(event?.target.value);
        event?.target.value && setType(event.target.value as string);
    };

    const { axiosService } = useAxiosServices();
    const [apiData, setApiData] = useState({
        Bag: {
            data: []
        },
        Users: {
            data: []
        },
        Drivers: {
            data: []
        },
        Report: {
            data: []
        }
    });

    const [dataRange, setDataRange] = useState([]);
    const [loading, setLoading] = useState(true);
    const getData = async () => {
        setLoading(true);
        const postRangeSetting = sList
            .split(',')
            .filter((v) => v !== '')
            .filter((v) => v !== ',')
            .filter((v) => v !== undefined);
        console.log(sList.split(','));
        console.log('postRangeSetting', postRangeSetting);

        const post = { type, range: month, custom: [], postRange: postRangeSetting };
        if (rangeDate[1]) {
            post.custom = [...rangeDate];
        }
        const api = await axiosService.post('/statistics/dashboard/postcode', post);
        console.log('ddd');
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { range, data, status } = api.data;
        console.log(api.data);
        if (status) {
            data && setApiData({ ...data });
            range && setDataRange([...range]);
        }

        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, [type, month, rangeDate[1]]);

    const chartDataOpti = useChartData(apiData, dataRange, init);

    React.useEffect(() => {
        // do not load chart when loading
        if (!isLoading) {
            ApexCharts.exec(`bar-chart`, 'updateOptions', chartDataOpti);
        }
    }, [apiData, navType, primary200, primaryDark, secondaryMain, secondaryLight, primary, darkLight, grey200, isLoading, grey500]);

    const [sList, setSList] = useState('');

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container flexDirection={'row'}>
                                <Grid item xs>
                                    <Select
                                        id="columnIsd"
                                        name="columnIad"
                                        value={type}
                                        onChange={handleChangeType}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {radioData.map(({ value: monthValue, label }, index: number) => (
                                            <MenuItem key={index} value={monthValue}>
                                                {label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                {type === '666' && (
                                    <Grid item ml={2}>
                                        <Select
                                            id="columnId"
                                            name="columnId"
                                            value={month}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {monthData.map(({ value: monthValue, label }, index: number) => (
                                                <MenuItem key={index} value={monthValue}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                )}
                                {type === '5' && (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Grid item ml={2}>
                                            <DateRangePicker
                                                startText="Start"
                                                endText="End"
                                                value={rangeDate}
                                                onChange={(newValue) => {
                                                    setRangeDate(newValue);
                                                }}
                                                renderInput={(startProps, endProps) => (
                                                    <>
                                                        <TextField {...startProps} />
                                                        <Box sx={{ mx: 2 }}> to </Box>
                                                        <TextField {...endProps} />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                )}
                                <div style={{ display: 'flex', flexGrow: 5, alignItems: 'center' }}>
                                    <Grid item>
                                        <TextField label={'Suburb'} value={sList} onChange={(e) => setSList(e.currentTarget.value)} />
                                    </Grid>
                                    <Grid item ml={2}>
                                        <Button variant={'outlined'} onClick={() => getData()}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </div>
                            </Grid>
                            {/* @ts-ignore */}
                            {!loading ? (
                                <>
                                    {/* @ts-ignore */}
                                    <Chart {...chartDataOpti} />
                                </>
                            ) : (
                                <Skeleton variant="rectangular" height={530} />
                            )}
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

export default PostcodeChart;

import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useTheme } from '@mui/system';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const SuburbFrequncyChip = ({ value }) => {
    const theme = useTheme();
    const switchWeeklyText = useCallback(() => {
        if (value === '1') {
            return 'Weekly';
        }
        if (value === '2') {
            return 'Bi-weekly(2-4)';
        }
        return 'Bi-weekly(1-3-5)';
    }, [value]);
    return (
        <Chip
            label={switchWeeklyText()}
            size="small"
            sx={{
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 20 * Number(value),
                color: theme.palette.success.dark
            }}
        />
    );
};

interface Props {
    selectMenus: string[];
    setSelectTypes: Dispatch<SetStateAction<string[]>>;
}

const SururbFrequencySelect = ({ selectMenus, setSelectTypes }: Props) => {
    const handleChange = (event: SelectChangeEvent<typeof selectMenus>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setSelectTypes([...value]);
    };
    const label = [
        { value: '1', label: 'Weekly' },
        { value: '2', label: 'Bi-weekly(2-4)' },
        { value: '3', label: 'Bi-weekly(1-3-5)' }
    ];

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label" sx={{ background: '#fff !important', width: 100 }}>
                    Frequency
                </InputLabel>
                <Select
                    sx={{ background: '#fff !important', '& .MuiOutlinedInput-input': { background: '#fff !important' } }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectMenus}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(s) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {s.map((v) => (
                                <SuburbFrequncyChip value={v} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {label.map((value, i) => (
                        <MenuItem key={`d123emp-dfdsfid-${i}`} value={value.value}>
                            <Checkbox checked={selectMenus.includes(value.value)} />
                            <ListItemText primary={value.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default SururbFrequencySelect;

import { IconQrcode, IconSettings, IconPin, IconMail, IconCalculator } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

const icons = {
    IconQrcode,
    IconSettings,
    IconPin,
    IconMail,
    IconCalculator
};

const settingRoute = {
    id: 'setting-route',
    type: 'group',
    children: [
        {
            id: 'qrCode',
            title: 'QR Code',
            type: 'item',
            icon: icons.IconQrcode,
            url: '/setting/qrList'
        },
        {
            id: 'setting',
            title: 'Setting',
            type: 'item',
            icon: icons.IconSettings,
            url: '/setting/settings'
        },
        {
            id: 'setting-suburb',
            title: 'Suburb Setting',
            type: 'item',
            icon: icons.IconPin,
            url: '/setting/suburb'
        },

        {
            id: 'setting-cal',
            title: 'Refund calculator',
            type: 'item',
            icon: icons.IconCalculator,
            url: '/pick/cal'
        }
    ]
};

export default settingRoute;

import { Box, Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select, TableCell, Typography } from '@mui/material';
import { PickProfileChip } from '../../ui-component/swith/pickTypeCard';
import { PickProfileConst } from '../../utils/const/pick.const';
import React, { useCallback, useEffect, useState } from 'react';
import { locationListType } from '../../types/pick';
import useAxiosServices from '../../hooks/useAxios';
import { useTheme } from '@mui/styles';
import { switchWeek } from './suburb.setting';
import { useStyles } from '../../utils/useStyles';

interface Props {
    data: number[];
    setData: (i: number) => void;
}

export const SuburbWeekAddList = ({ data, setData }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const label = [
        { value: '1', label: 'Weekly' },
        { value: '2', label: 'Bi-weekly(2-4)' },
        { value: '3', label: 'Bi-weekly(1-3-5)' }
    ];
    return (
        <>
            {data.map((v, i) => (
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <div>{switchWeek(i)}</div>
                    <Checkbox
                        color="primary"
                        checked={data[i] === 1}
                        onChange={() => setData(i)}
                        sx={{
                            color: theme.palette.primary.main,
                            '&.Mui-checked': { color: theme.palette.primary.main }
                        }}
                    />
                </div>
            ))}
        </>
    );
};

import { userListProfile } from '../_mockApis/user-profile/types';
import { pickListTypes } from '../types/pick';

export const UserCreditRender = (user: undefined | userListProfile) => {
    if (!user) {
        return 0;
    }
    return user.userType === 1 ? user?.credit ?? 0 : user?.credit2 ?? 0;
};

export const pickUpUserName = (pick: pickListTypes) => {
    if (!pick?.user) {
        return '';
    }
    if (pick?.user?.idx === 125 || pick?.user?.idx === 124) {
        return `${pick.userFirstName} ${pick.userName} (Manual)`;
    }
    if (pick.userType === 2) {
        return pick.user?.businessName;
    }
    return `${pick.user.userFirstName} ${pick.user.userName}`;
};

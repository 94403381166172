import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputBase,
    InputLabel,
    List,
    ListItem,
    OutlinedInput,
    TextField,
    Typography
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import useAxiosServices from '../../../hooks/useAxios';
import { SuburbListData } from '../../../types/suburb.type';
import { useDebounce } from '../../../hooks/useDebounce';
import { useTheme } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { SuburbWeekAddList } from '../suburb.week.add';
import SururbFrequencySelect from './suburb.frequency.select';
import { SuburbFrequencyAddBox } from '../suburb.frequency.add';
import { SuburbActiveSelect } from '../suburb.active.select';
import { locationListType } from '../../../types/pick';
import AlertDialog from '../../../ui-component/dialog/AlertDialog';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../../utils/common';

export interface Props {
    open: boolean;
    handleClose: (row: locationListType | null) => void;
    selectedData: null | locationListType;
}
export const SubModifyBox = ({ open, handleClose, selectedData }: Props) => {
    const theme = useTheme();
    const [query, setQuery] = useState('');
    const [suburbs, setSuburbs] = useState<SuburbListData[]>([]);
    const [loading, setLoading] = useState(false);
    const { axiosService } = useAxiosServices();
    const [basic, setBasic] = useState('0');
    const [business, setBusiness] = useState('0');
    const [charity, setCharity] = useState('0');
    const [school, setSchool] = useState('0');
    const [memo, setMemo] = useState('');
    useEffect(() => {
        dataConvert();
    }, [selectedData]);
    const dataConvert = () => {
        if (selectedData) {
            setArea(selectedData.area);
            setCouncil(selectedData.council);
            const weekConvertList = selectedData.weekDisabled.split('|:|').map((v) => Number(v));
            setWeekList([...weekConvertList]);
            setActive(String(selectedData.active));
            setBusiness(String(selectedData.business));
            setBasic(String(selectedData.basic));
            setCharity(String(selectedData.charity));
            setSchool(String(selectedData.school));
            setMemo(selectedData.remark);
            if (selectedData.frequency === 0) {
                setFrequency('1');
            } else {
                setFrequency(selectedData.frequencyType === 0 ? '2' : '3');
            }
        }
    };
    const fetchSuburbs = async () => {
        if (query.length < 2) {
            setSuburbs([]);
            return null;
        }
        setLoading(true);
        try {
            const api = await axiosService.post('/address/searchService', { query });
            console.log('api.data', api.data);
            setSuburbs([...api.data]);
            // setSuburbs(response.data.predictions.slice(0, 10));
        } catch (error) {
            console.error('Error fetching suburbs:', error);
            setSuburbs([]);
        }
        setLoading(false);
    };
    useDebounce(fetchSuburbs, 1000, [query]);
    const [area, setArea] = useState('');
    const [council, setCouncil] = useState('');
    const [weekList, setWeekList] = useState([0, 0, 0, 0, 0, 0, 0]);
    const handleWeekList = (index: number) => {
        // eslint-disable-next-line no-nested-ternary
        const convertData = weekList.map((v, i) => (i === index ? (v === 0 ? 1 : 0) : v));
        setWeekList([...convertData]);
    };
    const [frequency, setFrequency] = useState('1');
    const [active, setActive] = useState('1');

    const saveData = async () => {
        console.log('Ddd');
        if (area.length === 0 || council.length === 0) {
            alert('Please Enter area or council');
            return null;
        }
        const api = await axiosService.post('/address/suburb/update', {
            active,
            frequency,
            area,
            council,
            weekList,
            suburbIdx: selectedData.idx,
            school,
            charity,
            business,
            basic,
            remark: memo
        });
        const { data } = api;
        if (data) {
            toast.info(`${selectedData.title} Updated!`, { ...ToastSetting.default });
            handleClose(selectedData);
        }
    };
    const deleteData = async () => {
        const api = await axiosService.post('/address/suburb/delete', {
            suburbIdx: selectedData.idx
        });
        const { data } = api;
        if (data) {
            handleClose(selectedData);
            toast.info(`${selectedData.title} Deleted!`, { ...ToastSetting.default });
        }
    };
    const [deleteOpen, setDeleteOpen] = useState(false);
    const deleteFetch = async () => {
        setDeleteOpen(false);
        await deleteData();
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={() => handleClose(selectedData)}
                keepMounted
                fullWidth
                maxWidth="xs"
                aria-labelledby="item-delete-title"
                aria-describedby="item-delete-description"
            >
                <DialogTitle id="add-row-title">{'Add Suburb'}</DialogTitle>
                <DialogContent sx={{ paddingTop: '20px !important' }}>
                    <>
                        {!selectedData ? (
                            <CircularProgress />
                        ) : (
                            <motion.div
                                initial={{ opacity: 0, y: 30 }}
                                animate={{ opacity: 1, y: -10 }}
                                exit={{ opacity: 0, y: 30 }} // 선택 해제 시 역방향 애니메이션 정의
                                transition={{ duration: 0.5 }}
                            >
                                <ListItem
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        '&:hover': { backgroundColor: '#e2e2e2' },
                                        borderRadius: '5px',
                                        backgroundColor: '#fff',
                                        marginBottom: '2px',
                                        opacity: 1,
                                        transition: 'all 0.3s ease-in-out'
                                    }}
                                >
                                    <div>{`${selectedData.title} ${selectedData.area} ${selectedData.postcode}`}</div>
                                </ListItem>
                                <Grid
                                    style={{
                                        marginTop: '10px',
                                        width: '100%',
                                        height: '300px',
                                        transition: 'opacity 0.3s ease-in-out',
                                        opacity: 1
                                    }}
                                >
                                    <Grid container xs={12}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={6}>
                                                <div>Area</div>
                                                <TextField
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            height: '30px',
                                                            padding: '5px 10px' // 패딩 조정
                                                        }
                                                    }}
                                                    value={area}
                                                    onChange={(e) => setArea(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>Council</div>
                                                <TextField
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            height: '30px',
                                                            padding: '5px 10px' // 패딩 조정
                                                        }
                                                    }}
                                                    value={council}
                                                    onChange={(e) => setCouncil(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid marginTop={'10px'} container item xs={12} display={'flex'} flexDirection={'column'}>
                                            <Grid display={'flex'}>
                                                <SuburbWeekAddList data={weekList} setData={handleWeekList} />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={8} mt={'20px'} mb={'10px'}>
                                            <Typography variant={'body1'} width={'200px'}>
                                                {`Frequency`}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={8} display={'flex'} flexDirection={'column'}>
                                            <SuburbFrequencyAddBox weekly={frequency} setWeekly={setFrequency} />
                                        </Grid>
                                        <Grid container item xs={12} mt={'20px'} mb={'10px'}>
                                            <Typography variant={'body1'} width={'200px'}>
                                                {`Available`}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={8} display={'flex'} flexDirection={'column'}>
                                            <SuburbActiveSelect active={active} setActive={setActive} />
                                        </Grid>
                                        <Grid container item xs={12} marginTop={'20px'}>
                                            <Grid item xs={6}>
                                                <div>Basic</div>
                                                <TextField
                                                    type="number"
                                                    inputProps={{
                                                        step: '1',
                                                        min: '0', // 최소값을 0으로 설정 (음수 입력 방지)
                                                        pattern: '\\d*' // 숫자만 입력받도록 정규식 패턴 설정
                                                    }}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            height: '30px',
                                                            padding: '5px 10px' // 패딩 조정
                                                        }
                                                    }}
                                                    value={basic !== '0' ? basic : ''}
                                                    onChange={(e) => setBasic(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>Business</div>
                                                <TextField
                                                    type="number"
                                                    inputProps={{
                                                        step: '1',
                                                        min: '0', // 최소값을 0으로 설정 (음수 입력 방지)
                                                        pattern: '\\d*' // 숫자만 입력받도록 정규식 패턴 설정
                                                    }}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            height: '30px',
                                                            padding: '5px 10px' // 패딩 조정
                                                        }
                                                    }}
                                                    value={business !== '0' ? business : ''}
                                                    onChange={(e) => setBusiness(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} marginTop={'10px'}>
                                            <Grid item xs={6}>
                                                <div>Charity</div>
                                                <TextField
                                                    type="number"
                                                    inputProps={{
                                                        step: '1',
                                                        min: '0', // 최소값을 0으로 설정 (음수 입력 방지)
                                                        pattern: '\\d*' // 숫자만 입력받도록 정규식 패턴 설정
                                                    }}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            height: '30px',
                                                            padding: '5px 10px' // 패딩 조정
                                                        }
                                                    }}
                                                    value={charity !== '0' ? charity : ''}
                                                    onChange={(e) => setCharity(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>School</div>
                                                <TextField
                                                    type="number"
                                                    inputProps={{
                                                        step: '1',
                                                        min: '0', // 최소값을 0으로 설정 (음수 입력 방지)
                                                        pattern: '\\d*' // 숫자만 입력받도록 정규식 패턴 설정
                                                    }}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            height: '30px',
                                                            padding: '5px 10px' // 패딩 조정
                                                        }
                                                    }}
                                                    value={school !== '0' ? school : ''}
                                                    onChange={(e) => setSchool(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} mt={'20px'} mb={'10px'}>
                                            <Typography variant={'body1'} width={'200px'}>
                                                {`Remark`}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <OutlinedInput
                                                multiline
                                                style={{ background: '#fff' }}
                                                value={memo}
                                                onChange={(e) => setMemo(e.currentTarget.value)}
                                                id={`remarkInput`}
                                                placeholder="Remark"
                                                size="small"
                                                sx={{ width: '100%', pr: 1, pl: 1 }}
                                            />
                                        </Grid>
                                        <Grid
                                            marginTop={'20px'}
                                            container
                                            item
                                            xs={12}
                                            display={'flex'}
                                            flexDirection={'row'}
                                            justifyContent={'center'}
                                        >
                                            <Button
                                                size="large"
                                                sx={{ color: '#fff', width: '40%' }}
                                                color="primary"
                                                variant={'contained'}
                                                onClick={() => saveData()}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                size="large"
                                                sx={{ color: '#fff', backgroundColor: '#e2e2e2', marginLeft: '10px', width: '40%' }}
                                                variant={'contained'}
                                                onClick={() => setDeleteOpen(true)}
                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </motion.div>
                        )}
                    </>
                </DialogContent>

                <DialogActions sx={{ mr: 2 }}>
                    <Button onClick={() => handleClose(selectedData)} color="error">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <AlertDialog
                title={'Delete suburb'}
                content={`Do you want to delete suburb?`}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteFetch()}
            />
        </>
    );
};

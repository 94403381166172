import { IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc } from '@tabler/icons';

const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc
};

const userMenu = {
    id: 'user',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'User',
            type: 'collapse',
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'user-default',
                    title: 'User',
                    type: 'item',
                    url: '/user/default'
                },
                {
                    id: 'user-driver',
                    title: 'Driver',
                    type: 'item',
                    url: '/user/driver'
                },
                {
                    id: 'user-drivers',
                    title: 'Driver Applications',
                    type: 'item',
                    url: '/user/driverWait'
                },
                {
                    id: 'user-depot',
                    title: 'Depot',
                    type: 'item',
                    url: '/user/depot'
                },
                {
                    id: 'user-manager',
                    title: 'Managers',
                    type: 'item',
                    url: '/user/manager'
                },
                {
                    id: 'user-beforeList',
                    title: 'Prepare List',
                    type: 'item',
                    url: '/user/beforeList'
                }
            ]
        }
    ]
};

export default userMenu;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getItemStyle, RouteRootList } from '../pickListWithDepot';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Button, Typography } from '@mui/material';
import { IconCircleMinus, IconEye, IconEyeOff } from '@tabler/icons';
import { PolyLineDataType, RouteLineColorPalette } from '../route.optimasation';
import { bagListType } from '../../../types/pick';
import { allBagCalc, bagCalc, numberWithCommas, timeCalcHelper, totalTimeGroupCalc } from '../../../utils/common';
import RouteGroupRow from './route.group.row';
import { groupRoutes, groupRoutesByDepot } from '../../../utils/format/pick.group';

interface Props {
    valueList: RouteRootList[];
    handleDeleteRouteList: (uuid: string, index: number) => void;
    getPolyLineData: (group: any) => Promise<void>;
    viewPoly: number[];
    setViewPoly: Dispatch<SetStateAction<number[]>>;
    setCenterList: Dispatch<SetStateAction<google.maps.LatLng[]>>;
    polylineData: PolyLineDataType[];
}

export interface RouteGroupListType {
    group: number;
    list: RouteRootList[];
}

const RouteGroupList = ({
    setCenterList,
    viewPoly,
    setViewPoly,
    polylineData,
    valueList,
    handleDeleteRouteList,
    getPolyLineData
}: Props) => {
    const [convertList, setConvertList] = useState<RouteRootList[]>([]);
    const [groupList, setGroupList] = useState<RouteGroupListType[]>([]);

    const handleConvert = (list: RouteRootList[]) => {
        const dataList = groupRoutesByDepot(list);
        const updateList = groupRoutes(dataList);
        setGroupList([...updateList]);
    };

    useEffect(() => {
        handleConvert(valueList);
    }, [valueList]);
    const getIndex = (uuid) => {
        const index = valueList.findIndex((v) => v.uuid === uuid);
        return index;
    };

    const handleRenderPolyView = (index) => {
        if (!viewPoly[index - 1]) {
            return false;
        } else {
            return true;
        }
    };

    const handleRenderPolySet = (index) => {
        const temp = [...viewPoly];
        temp[index - 1] = temp[index - 1] === 0 ? 1 : 0;
        setViewPoly([...temp]);
    };

    const rendingTotalBag = (list: RouteRootList[]) => {
        let total = 0;
        for (const row of list) {
            const add = row?.bag ?? 0;
            total += add;
        }
        return total;
    };

    const rendingTotalTime = (list: PolyLineDataType[], group) => {
        const find = list.find((v) => v.group === group);
        if (!find) {
            return 0;
        }
        return find.totalTime;
    };

    const handleCenterChange = (item: RouteRootList) => {
        setCenterList([...[item.location]]);
    };

    return (
        <>
            {groupList.map((groupItem, i) => (
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography typography="h6">Group {groupItem.group ?? 0}</Typography>
                            <Box
                                sx={{
                                    width: '30px',
                                    height: '3px',
                                    marginLeft: '10px',
                                    backgroundColor: RouteLineColorPalette[groupItem?.group ?? 0] ?? '#fff'
                                }}
                            />

                            <Button
                                onClick={() => handleRenderPolySet(groupItem.group)}
                                size="small"
                                startIcon={handleRenderPolyView(groupItem.group) ? <IconEye /> : <IconEyeOff />}
                            />
                            {totalTimeGroupCalc(polylineData, groupItem.group) > 0 && (
                                <Typography marginRight={'10px'}>
                                    Time: {timeCalcHelper(totalTimeGroupCalc(polylineData, groupItem.group))}
                                </Typography>
                            )}
                            {allBagCalc(groupItem.list ?? []) > 0 && (
                                <Typography>Litre : {`${numberWithCommas(allBagCalc(groupItem.list ?? []))}L`}</Typography>
                            )}
                        </Box>
                        <Button
                            onClick={() => getPolyLineData(groupItem.group)}
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{
                                marginBottom: '10px',
                                marginRight: '10px'
                            }}
                        >
                            <Typography typography="h5" color={'#fff'}>
                                {`Optimise Group ${groupItem.group ?? 0}`}
                            </Typography>
                        </Button>
                    </Box>

                    <>
                        {groupItem?.list &&
                            groupItem.list.length > 0 &&
                            groupItem.list.map((item, index) => (
                                <Draggable
                                    key={`${item.type}-${item.idx}-${index}-${i}`}
                                    draggableId={`${item.type}-${item.idx}-${index}=${i}`}
                                    index={getIndex(item.uuid)}
                                >
                                    {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            <RouteGroupRow
                                                handleCenterChange={handleCenterChange}
                                                item={item}
                                                handleDeleteRouteList={handleDeleteRouteList}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                    </>
                </Box>
            ))}
        </>
    );
};

export default RouteGroupList;

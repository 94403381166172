import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { gridSpacing } from '../../store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { userListProfile } from '../../_mockApis/user-profile/types';
import MaskedInput from 'react-text-mask';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import MapDialog from '../../ui-component/dialog/MapDialog';
import { Search } from '@mui/icons-material';
import useAxiosServices from '../../hooks/useAxios';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ReactDraftWysiwyg from '../../ui-component/editor/ReactDraftWysiwyg';
import ImageUploader from '../../ui-component/uploader/imageUploader';
import ChairtyUserAdd from './chairty.userAdd';

const validationSchema = yup.object({
    title: yup.string().min(2, 'title should be of minimum 2 characters length').required('title is required'),
    subTitle: yup.string().min(2, 'subTitle should be of minimum 2 characters length').required('subTitle is required'),
    fund: yup.number().required('fund is required')
});

const CharityAdd = () => {
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const navigate = useNavigate();
    const [depotType, setDepotType] = useState<number>(0);
    const [profile, setProfile] = useState<userListProfile | null>(null);
    const formik = useFormik({
        initialValues: {
            title: '',
            subTitle: '',
            fund: 0
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const handlerAddress = (add, lat, lot) => {
        formik.setFieldValue('userAddress', add);
        setOpen((p) => !p);
    };

    useEffect(() => {}, []);

    const [alertOpen, setAlertOpen] = useState(false);
    const [mainImage, setMainImage] = useState(null);
    const [logoImage, setLogoImage] = useState(null);

    const imageHandler = (file: any) => {
        setMainImage(file[0]);
    };
    const imageHandler2 = (file: any) => {
        setLogoImage(file[0]);
    };
    const onCancel = () => {
        setMainImage(null);
    };
    const onCancel2 = () => {
        setLogoImage(null);
    };

    const insertFetch = async () => {
        const { title, fund, subTitle } = formik.values;
        if (!profile) {
            return alert('Please add Charity User');
        }

        if (content === '') {
            return alert('Please add Content');
        }
        if (!logoImage) {
            return alert('Please add Logo Image');
        }
        if (!mainImage) {
            return alert('Please add Main Image');
        }

        try {
            const form = new FormData();
            form.append('title', title);
            form.append('subTitle', subTitle);
            form.append('fund', String(fund));
            form.append('content', content);
            form.append('mainImage', mainImage);
            form.append('logoImage', logoImage);
            form.append('userIdx', String(profile.idx));
            const api = await axiosService.post('charity/add', form, {
                headers: { 'Content-type': 'multipart/form-data' }
            });

            const { status } = api;
            if (status) {
                toast.info('Success Create Chairty', { ...ToastSetting.default });
                navigate('/charity/list');
            } else {
                toast.info('Error', { ...ToastSetting.default });
            }
            return;
        } catch (e) {
            toast.info('Error', { ...ToastSetting.default });
        } finally {
            setAlertOpen(false);
        }
    };

    const handleProfile = (data) => {
        setProfile({ ...data });
    };

    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Add</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button size="large" color="primary">
                                        Create
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing} p={2}>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant={'h4'}>MainImage</Typography>
                            <ImageUploader onChange={imageHandler} onCancel={onCancel} />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant={'h4'}>Logo</Typography>
                            <ImageUploader onChange={imageHandler2} onCancel={onCancel2} />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'}>
                            <ChairtyUserAdd driverHandler={handleProfile} initProfile={profile} />
                        </Grid>

                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="title"
                                fullWidth
                                label="Title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="subTitle"
                                fullWidth
                                label="Sub Title"
                                value={formik.values.subTitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.subTitle && Boolean(formik.errors.subTitle)}
                                helperText={formik.touched.subTitle && formik.errors.subTitle}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="fund"
                                type={'number'}
                                fullWidth
                                label="Fund Amount"
                                value={formik.values.fund}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.fund && Boolean(formik.errors.fund)}
                                helperText={formik.touched.fund && formik.errors.fund}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                '& .rdw-editor-wrapper': {
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                                    border: '1px solid',
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                    borderRadius: '12px',
                                    overflow: 'hidden',
                                    '& .rdw-editor-main': {
                                        px: 2,
                                        py: 0.5,
                                        border: 'none',
                                        minHeight: '300px'
                                    },
                                    '& .rdw-editor-toolbar': {
                                        pt: 1.25,
                                        border: 'none',
                                        borderBottom: '1px solid',
                                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                        bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                        '& .rdw-option-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                        },

                                        '& .rdw-dropdown-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                            '& .rdw-dropdown-selectedtext': {
                                                color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            <Stack spacing={gridSpacing}>
                                <ReactDraftWysiwyg value={content} setValue={setContent} />
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button variant="contained" type={'submit'}>
                                        Create Charity
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>

            <AlertDialog
                title={'CreateCharity'}
                content={`Do you want to create Charity?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => insertFetch()}
            />
        </>
    );
};

export default CharityAdd;

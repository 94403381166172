import { IconQrcode } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

const icons = {
    IconQrcode
};

const transactionRoute = {
    id: 'setting-transaction',
    type: 'group',
    children: [
        {
            id: 'transaction',
            title: 'Transaction List',
            type: 'item',
            icon: icons.IconQrcode,
            url: '/transaction/list'
        }
    ]
};

export default transactionRoute;

export const MessageTypeConst = [
    { value: 1, label: 'Mail' },
    { value: 2, label: 'Push' },
    { value: 3, label: 'Sms' }
];

export const FindMessageTypeConst = (value) => MessageTypeConst.find((v) => v.value === value);
export const FindMessageTypeStyle = (theme, type) => {
    switch (type) {
        case 1:
            return {
                background: theme.palette.warning.light,
                color: theme.palette.warning.dark
            };
        case 2:
            return {
                background: theme.palette.success.light + 60,
                color: theme.palette.success.dark
            };
        case 3:
            return {
                background: theme.palette.orange.light + 80,
                color: theme.palette.orange.dark
            };
        case 4:
            return {
                background: theme.palette.orange.light + 120,
                color: theme.palette.success.dark
            };
        case 5:
            return {
                background: theme.palette.orange.light + 120,
                color: theme.palette.info.dark
            };
        default:
            return {
                background: theme.palette.warning.light,
                color: theme.palette.warning.dark
            };
    }
};

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { DefaultRootState, useSelector } from 'react-redux';
import useAuth from '../../../../hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const menuSwitch = (i, adminRole) => {};

const MenuList = () => {
    const { logout, user, isLoggedIn } = useAuth();
    const adminRole = user?.adminRole ?? 0;
    console.log('ADMIN ROLE', adminRole);

    const navItems = menuItem.items.map((item, i) => {
        switch (item.type) {
            case 'group':
                if (i === 6) {
                    if (adminRole === 6 || adminRole === 5) {
                        return null;
                    }
                }
                if (i === 7) {
                    if (adminRole === 7 || adminRole === 5) {
                        return null;
                    }
                }
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;

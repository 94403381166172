import { Chip } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/styles';

interface Props {
    type: number;
}
export const UserTypeHelper = ({ type }: Props) => {
    const theme = useTheme();
    return (
        <>
            {type === 2 && (
                <Chip
                    label="Business"
                    size="small"
                    sx={{
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                        color: theme.palette.success.dark
                    }}
                />
            )}
            {type === 1 && (
                <Chip
                    label="Personal"
                    size="small"
                    sx={{
                        background: theme.palette.info.light + 80,
                        color: theme.palette.info.dark
                    }}
                />
            )}
        </>
    );
};

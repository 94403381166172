import React from 'react';
import { userListProfile } from '../../_mockApis/user-profile/types';
import {
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { userBeforeTypes } from '../../types/before';
import { AnimatePresence, motion } from 'framer-motion';

interface props {
    row: userBeforeTypes;
    index: number;
    selectRows: number[];
    handleSelectRow: (idx: number) => void;
}

const PushRow = ({ row, index, selectRows, handleSelectRow }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };

    const TransId = (dat: userListProfile) => {
        console.log(dat.snsType);
        switch (dat.snsType) {
            case 1:
                return dat.userId;
            case 2:
                return 'Google';
            case 3:
                return 'Apple';
            case 4:
                return 'Paypal';
            default:
                return dat.userId;
        }
    };

    return (
        <TableRow hover key={index} sx={{ padding: 0 }}>
            <TableCell sx={{ padding: '0.5rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        sx={{ width: '100%', height: '100%', padding: 0 }}
                        checked={selectRows.includes(row.idx)}
                        onClick={() => handleSelectRow(row.idx)}
                    />
                </div>
            </TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>{row.userType === 1 ? 'personal' : 'business'}</TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>{row.userEmail}</TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>{`${row.userAddress} ${row.userZip}` ?? 'N/A'}</TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>
                {row.userRole === 1 && (
                    <Chip
                        label="Active"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                            color: theme.palette.success.dark
                        }}
                    />
                )}
                {row.userRole === 2 && (
                    <Chip
                        label="Rejected"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                            color: theme.palette.orange.dark
                        }}
                    />
                )}
                {row.userRole === 3 && (
                    <Chip
                        label="Pending"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                            color: theme.palette.warning.dark
                        }}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

export default PushRow;

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { gridSpacing } from '../../store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { userListProfile } from '../../_mockApis/user-profile/types';
import MaskedInput from 'react-text-mask';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import MapDialog from '../../ui-component/dialog/MapDialog';
import { Search, Visibility, VisibilityOff } from '@mui/icons-material';
import useAxiosServices from '../../hooks/useAxios';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
    userId: yup.string().min(2, 'userId should be of minimum 2 characters length').required('userId is required'),
    userPwd: yup.string().min(7, 'Name should be of minimum 7 characters length').required('Name is required'),
    userPwdCheck: yup.string().oneOf([yup.ref('userPwd'), null], 'Passwords must match'),
    depotName: yup.string().min(2, 'DepotName should be of minimum 2 characters length').required('DepotName is required'),
    userEmail: yup.string().email('Enter a valid email').required('Email is required'),
    userPhone: yup.string().min(10, 'Phone should be of minimum 8 characters length').required('Phone is required'),
    userAddress: yup.string().required('Address is required'),
    note: yup.string()
});

const CreateDepot = () => {
    const { axiosService } = useAxiosServices();
    const navigate = useNavigate();
    const [depotType, setDepotType] = useState<number>(0);
    const formik = useFormik({
        initialValues: {
            userId: '',
            userName: '',
            userPwdCheck: '',
            userPwd: '',
            depotName: '',
            userEmail: '',
            userPosition: '',
            userPhone: '',
            userAddress: '',
            note: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const [profile, setProfile] = useState<userListProfile | null>(null);
    const [open, setOpen] = useState(false);
    const handlerDriverAccess = () => {
        console.log('idx', profile.idx);
        setOpen((p) => !p);
    };

    const handlerAddress = (add, lat, lot) => {
        formik.setFieldValue('userAddress', add);
        setOpen((p) => !p);
    };

    const depotEmailCreate = () => {
        const createMail = `${formik.values.userName}@${formik.values.depotName}.recan`;
        formik.setFieldValue('userEmail', createMail);
    };

    // const memo = useMemo(() => depotEmailCreate(), [formik.values.userName, formik.values.depotName]);

    useEffect(() => {}, []);

    const createDepot = async () => {
        try {
            const v = formik.values;
            const api = await axiosService.post('/users/admin/depotCreate', v);
            const { status, data } = api.data;
            if (status) {
                toast.info('Success Create Depot', { ...ToastSetting.default });
                navigate('/user/depot');
            } else {
                toast.error(data, { ...ToastSetting.default });
            }
        } catch (e) {
            toast.error('Error', { ...ToastSetting.default });
        } finally {
            setAlertOpen(false);
        }
    };

    const [alertOpen, setAlertOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const halderShow = (type) => {
        if (type === 1) {
            setShow((p) => !p);
        } else {
            setShow1((p) => !p);
        }
    };

    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Depot List</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button size="large" color="primary">
                                        Create
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing} p={2}>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="depotName"
                                fullWidth
                                label="Depot Name"
                                value={formik.values.depotName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.depotName && Boolean(formik.errors.depotName)}
                                helperText={formik.touched.depotName && formik.errors.depotName}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="userId"
                                fullWidth
                                label="Depot Id"
                                value={formik.values.userId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userId && Boolean(formik.errors.userId)}
                                helperText={formik.touched.userId && formik.errors.userId}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="userEmail"
                                fullWidth
                                label="Email"
                                value={formik.values.userEmail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userEmail && Boolean(formik.errors.userEmail)}
                                helperText={formik.touched.userEmail && formik.errors.userEmail}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                type={show ? 'text' : 'password'}
                                id="userPwd"
                                fullWidth
                                label="Password"
                                value={formik.values.userPwd}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userPwd && Boolean(formik.errors.userPwd)}
                                helperText={formik.touched.userPwd && formik.errors.userPwd}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton aria-label="toggle password visibility" onClick={() => halderShow(1)} edge="end">
                                            {show ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                type={show1 ? 'type' : 'password'}
                                id="userPwdCheck"
                                fullWidth
                                label="Confirm Password"
                                value={formik.values.userPwdCheck}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userPwdCheck && Boolean(formik.errors.userPwdCheck)}
                                helperText={formik.touched.userPwdCheck && formik.errors.userPwdCheck}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton aria-label="toggle password visibility" onClick={() => halderShow(2)} edge="end">
                                            {show1 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <MaskedInput
                                mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]}
                                className="form-control"
                                guide={false}
                                placeholder={'0000-000-000'}
                                id="userPhone"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                render={(ref, props) => (
                                    <TextField
                                        error={formik.touched.userPhone && Boolean(formik.errors.userPhone)}
                                        helperText={formik.touched.userPhone && formik.errors.userPhone}
                                        fullWidth
                                        label="Phone No."
                                        value={formik.values.userPhone}
                                        inputRef={ref}
                                        {...props}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="userAddress"
                                fullWidth
                                label="Address"
                                value={formik.values.userAddress}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userAddress && Boolean(formik.errors.userAddress)}
                                helperText={formik.touched.userAddress && formik.errors.userAddress}
                            />
                            <Button onClick={() => setOpen((p) => !p)}>Search</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="note"
                                fullWidth
                                label="Note"
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button variant="contained" type={'submit'}>
                                        Create Depot
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>
            {open && <MapDialog open={open} handleClose={setOpen} handleSuccess={handlerAddress} />}
            <AlertDialog
                title={'Create depot'}
                content={`Do you want to create an account for Depot with your ${formik.values.userId}`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => createDepot()}
            />
        </>
    );
};

export default CreateDepot;

import React from 'react';
import { userListProfile } from '../../_mockApis/user-profile/types';
import {
    Checkbox,
    Chip,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { userBeforeTypes } from '../../types/before';

interface props {
    row: userBeforeTypes;
    index: number;
    selectRows: number[];
    handleSelectRow: (idx: number) => void;
}

const BeforeRow = ({ row, index, selectRows, handleSelectRow }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };

    const TransId = (dat: userListProfile) => {
        console.log(dat.snsType);
        switch (dat.snsType) {
            case 1:
                return dat.userId;
            case 2:
                return 'Google';
            case 3:
                return 'Apple';
            case 4:
                return 'Paypal';
            default:
                return dat.userId;
        }
    };

    return (
        <TableRow hover key={index}>
            <TableCell sx={{ p: 0, pl: 3 }}>
                <Checkbox checked={selectRows.includes(row.idx)} onClick={() => handleSelectRow(row.idx)} />
            </TableCell>
            <TableCell>
                {row.userChange === 1 && (
                    <Chip
                        label="Change"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                            color: theme.palette.success.dark
                        }}
                    />
                )}
                {row.userChange === 0 && (
                    <Chip
                        label="None"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                            color: theme.palette.orange.dark
                        }}
                    />
                )}
            </TableCell>
            <TableCell>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs zeroMinWidth>
                        <Typography align="left" variant="subtitle1" component="div">
                            {row.userFirstName ?? ''} {row.userName ?? ''}
                            {row.userName && row.userStatus === 1 && (
                                <CheckCircleIcon sx={{ color: 'success.dark', width: 14, height: 14 }} />
                            )}
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {row.userNickName}
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            {row.userEmail}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {row.beforeCollection ?? 'N/A'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {row.beforeACredit} / {row.beforeLifeCredit}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography align="left" variant="subtitle2" noWrap>
                    {row.mailSend}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default BeforeRow;

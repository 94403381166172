import { useTheme } from '@mui/system';
import { Chip } from '@mui/material';
import React from 'react';

const PickTypeConst = [
    { value: 1, label: 'BOOKED', color: '#9e9e9e' },
    { value: 2, label: 'ASSIGNED', color: '#ff8807' },
    { value: 3, label: 'COLLECTED', color: '#3fc040' },
    { value: 4, label: 'DELIVERED', color: 'red' },
    { value: 6, label: 'PROCESSING', color: '#606060' },
    { value: 5, label: 'CREDITED', color: 'blue' },
    { value: 9, label: 'CANCELLED', color: '#8B0000' }
];

export const PayoutConst = [
    { value: 1, label: 'Payout Pending', color: '#9e9e9e' },
    { value: 2, label: 'Payout Completed', color: '#ff8807' }
];

export const PickProfileConst = [
    { value: 'R', label: 'Recan' },
    { value: 'D', label: 'Depot' },
    { value: 'C', label: 'Coex' }
];

export const FindPickTypeConst = (value) => PickTypeConst.find((v) => v.value === value);
export const FindPayoutTypeConst = (value) => PayoutConst.find((v) => v.value === value);
export const FindProfileTypeConst = (value) => PickProfileConst.find((v) => v.value === value);

export const FindPayoutTypeStyle = (theme, type) => {
    switch (type) {
        case 1:
            return {
                background: theme.palette.success.light + 60,
                color: theme.palette.success.dark
            };
        case 2:
            return {
                background: 'transfer',
                color: 'blue'
            };
        default:
            return {
                background: theme.palette.warning.light,
                color: '#808080'
            };
    }
};

export const FindPickTypeStyle = (theme, type) => {
    switch (type) {
        case 1:
            return {
                background: theme.palette.warning.light,
                color: '#9e9e9e'
            };
        case 2:
            return {
                background: theme.palette.success.light + 60,
                color: '#ff8807'
            };
        case 3:
            return {
                background: theme.palette.orange.light + 80,
                color: '#3fc040'
            };
        case 4:
            return {
                background: theme.palette.orange.light + 120,
                color: 'red'
            };
        case 5:
            return {
                background: theme.palette.orange.light + 120,
                color: 'blue'
            };
        case 6:
            return {
                background: theme.palette.orange.light + 120,
                color: '#606060'
            };
        case 9:
            return {
                background: theme.palette.orange.light + 120,
                color: '#8B0000'
            };
        default:
            return {
                background: theme.palette.warning.light,
                color: '#808080'
            };
    }
};
export const FindPickProfileTypeStyle = (theme, type) => {
    switch (type) {
        case 'R':
            return {
                background: theme.palette.info.dark,
                color: theme.palette.background.default
            };
        case 'D':
            return {
                background: theme.palette.success.dark,
                color: theme.palette.background.default
            };
        case 'C':
            return {
                background: theme.palette.warning.dark,
                color: theme.palette.background.default
            };
        default:
            return {
                background: theme.palette.warning.light,
                color: theme.palette.warning.dark
            };
    }
};
export default PickTypeConst;

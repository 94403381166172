import React from 'react';
import { useTheme } from '@mui/styles';
import { Button, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import CharityList from './charity.list';
import { useNavigate } from 'react-router-dom';

const CharityPageWrapper = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">List</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => navigate('/charity/add')} style={{ marginRight: 10 }}>
                            ADD
                        </Button>
                        <OutlinedInput
                            id="input-search"
                            placeholder="Search"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={0.5} size="0.5rem" />
                                </InputAdornment>
                            }
                            size="small"
                        />
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <CharityList />
        </MainCard>
    );
};

export default CharityPageWrapper;

import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import axios from 'utils/axios';
import { userListProfile, UserProfile } from '_mockApis/user-profile/types';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import useAxios from '../../hooks/useAxios';

import FaceIcon from '@mui/icons-material/Face';
import { gridSpacing } from '../../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import UserDownload from '../../components/csv/user.download';

import { IconSearch } from '@tabler/icons';
import { userBeforeTypes } from '../../types/before';
import BeforeRow from './before.row';
import { PickProfileChip } from '../../ui-component/swith/pickTypeCard';
import { PickProfileConst } from '../../utils/const/pick.const';
import { SelectChangeEvent } from '@mui/material/Select';
import PickDownLoad from '../../components/csv/pick.download';
import { useNavigate, useRoutes } from 'react-router-dom';
import BeforeInRow from './before.in.row';
// ==============================|| USER LIST 1 ||============================== //
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export const BeforeListSelectConst = [
    { value: 'none', label: 'Default' },
    { value: 'collection', label: 'Collection' },
    { value: 'credit', label: 'credit' }
];
export const BeforeListSelectDesc = [
    { value: 'DESC', label: 'DESC' },
    { value: 'ASC', label: 'ASC' }
];
export const BeforeListSelectChange = [
    { value: 'All', label: 'All' },
    { value: 'Complete', label: 'Change' },
    { value: 'Non', label: 'None' }
];
interface IBeforeInList {
    inList: number[];
    onDelete: (idx: number) => void;
    refresh: boolean;
}
const BeforeInList = ({ inList, onDelete, refresh }: IBeforeInList) => {
    const theme = useTheme();

    const { axiosService } = useAxios();
    const [data, setData] = React.useState<userBeforeTypes[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        search: string;
        change: 'All' | 'Complete' | 'Non';
        send: 'All' | 'Complete' | 'Non';
        sort: 'credit' | 'collection' | 'none';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        search: '',
        change: 'All',
        send: 'All',
        sort: 'none'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/before-store/list/in', { in: inList });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
        setSelectRows([...[]]);
    };

    React.useEffect(() => {
        getData();
    }, [listParam, inList, refresh]);

    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const rowChange = (row) => {
        setListParam((p) => ({ ...p, take: row }));
    };
    const handleChangeSortSelect = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, sort: value }));
    };
    const handleChangeSortSelectDESC = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, nameSort: value }));
    };
    const handleChangeCheck = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, change: value }));
    };
    const handleChangeTake = (value) => {
        setListParam((p) => ({ ...p, take: value }));
        setAnchorEl(null);
    };
    const [selectRows, setSelectRows] = useState<number[]>([]);
    const handleSelectRow = (idx) => {
        const find = selectRows.find((v) => v === idx);
        if (find) {
            const changeData = selectRows.filter((v) => v !== idx);
            setSelectRows([...changeData]);
        } else {
            const changeData = [...selectRows, idx];
            setSelectRows([...changeData]);
        }
    };
    const handleSelectAllRow = () => {
        const find = data.map((v) => v.idx);
        if (selectRows.length === find.length) {
            setSelectRows([...[]]);
        } else {
            const changeData = [...find];
            setSelectRows([...changeData]);
        }
    };
    const [search, setSearch] = useState('');
    const handleSelectSearch = () => {
        setListParam((p) => ({ ...p, search }));
    };
    const navigation = useNavigate();
    const goToMailSend = () => {
        if (selectRows.length > 0) {
            navigation('/messaging/user/send', { state: selectRows });
        }
    };
    console.log('data', data);
    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ p: 0, pl: 3, cursor: 'pointer', width: 100 }} onClick={() => handleSelectAllRow()}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>Delete</div>
                            </TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>User Profile</TableCell>
                            <TableCell>Collections</TableCell>
                            <TableCell>Credit</TableCell>
                            <TableCell>Sends</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            data.map((row, index) => (
                                <BeforeInRow handleDeleteRow={onDelete} selectRows={selectRows} row={row} index={index} />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default BeforeInList;

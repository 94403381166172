import React from 'react';
import { useTheme } from '@mui/styles';
import { Button, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import { useNavigate } from 'react-router-dom';
import PushList from './push.list';
import PushListWrapper from './pushList.wrapper';

const PushWrapper = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    return <PushListWrapper />;
};

export default PushWrapper;

import { useEffect, useState } from 'react';

// material-ui
import { Button, Grid } from '@mui/material';

// project imports
import EarningCard from '../dashboard/EarningCard';

import { gridSpacing } from 'store/constant';
import useAxios from '../../hooks/useAxios';
import SubCard from '../../ui-component/cards/SubCard';
import MinimalList from '../dashboard/minimal.list';
import { collectionList, pickListTypes } from '../../types/pick';
import { useNavigate } from 'react-router-dom';
import PostcodeChart from '../dashboard/postcode.chart';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const SuburbStastics = () => {
    const { axiosService } = useAxios();
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        getData();
    }, []);
    const [yesterDayValue, setYesterDayValue] = useState(0);
    const [todayDayValue, setTodayValue] = useState(0);
    const [orderValue, setOrderValue] = useState(0);

    const [salesValue, setSalesValue] = useState(0);
    const [waitPickupList, setWaitPickupList] = useState<pickListTypes[]>([]);
    const [waitPickupListCount, setWaitPickupListCount] = useState<number>(0);

    const getData = async () => {
        try {
            const api = await axiosService.post('/statistics/main');
            const { status, users, drivers, bags, report, pickList, pickListNumber } = api.data;
            if (status) {
                setYesterDayValue(users ?? 0);
                setTodayValue(drivers ?? 0);
                setOrderValue(report ?? 0);
                setSalesValue(bags ?? 0);
                setWaitPickupList([...(pickList ?? [])]);
                setWaitPickupListCount(pickListNumber ?? 0);
            }
        } catch (e) {
        } finally {
            setLoading(false);
        }
    };

    const navigate = useNavigate();

    const [init, setInit] = useState<{ users: boolean; drivers: boolean; bags: boolean; report: boolean }>({
        users: true,
        drivers: false,
        bags: false,
        report: false
    });
    const [iinit, setiinit] = useState(true);

    const choiceHandler = (value) => {
        if (iinit && init.bags && init.drivers && init.bags && init.report) {
            const tempData = {};
            tempData[`${value}`] = true;
            console.log('temp', tempData);
            const temp = {
                bags: false,
                users: false,
                drivers: false,
                report: false,
                ...tempData
            };
            setInit({ ...temp });
            setiinit(false);
        } else {
            const tempData = {};
            tempData[`${value}`] = !init[value];

            const temp = {
                ...init,
                ...tempData
            };
            setInit({ ...temp });
            setiinit(false);
        }
    };

    console.log(init);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <EarningCard
                            bool={init.users}
                            choiceType={'users'}
                            choiceHandler={choiceHandler}
                            value={yesterDayValue}
                            title={'Users'}
                            isLoading={isLoading}
                        />
                    </Grid>{' '}
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <EarningCard
                            bool={init.drivers}
                            choiceType={'drivers'}
                            choiceHandler={choiceHandler}
                            value={todayDayValue}
                            title={'Drivers'}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <EarningCard
                            bool={init.bags}
                            choiceType={'bags'}
                            choiceHandler={choiceHandler}
                            value={salesValue}
                            title={'Bags'}
                            isLoading={isLoading}
                        />
                    </Grid>{' '}
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <EarningCard
                            bool={init.report}
                            choiceType={'report'}
                            choiceHandler={choiceHandler}
                            value={orderValue}
                            title={'Contamination \n' + 'Report'}
                            isLoading={isLoading}
                        />
                    </Grid>{' '}
                    {/* <Grid item lg={4} md={12} sm={12} xs={12}> */}
                    {/*    <Grid container spacing={gridSpacing}> */}
                    {/*        <Grid item sm={6} xs={12} md={6} lg={12}> */}
                    {/*            <TotalIncomeDarkCard isLoading={isLoading} /> */}
                    {/*        </Grid> */}
                    {/*        <Grid item sm={6} xs={12} md={6} lg={12}> */}
                    {/*            <TotalIncomeLightCard isLoading={isLoading} /> */}
                    {/*        </Grid> */}
                    {/*    </Grid> */}
                    {/* </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={12}>
                        <PostcodeChart isLoading={isLoading} init={init} />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}> */}
            {/*    <Grid container spacing={gridSpacing}> */}
            {/*        <Grid item xs={12} md={12}> */}
            {/*            <PopularCard isLoading={isLoading} /> */}
            {/*        </Grid> */}
            {/*    </Grid> */}
            {/* </Grid> */}
        </Grid>
    );
};

export default SuburbStastics;

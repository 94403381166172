import React, { useState } from 'react';
import { Box, InputBase, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props {
    sx: SxProps<Theme>;
    i: number;
    formik: any;
    checkFromError: (i: number, id: string, type: string) => any;
    checkFromTouched: (i: number, id: string) => boolean;
}

const ManualGroupInput = ({ sx, i, formik, checkFromError, checkFromTouched }: Props) => {
    const [temp, setTemp] = useState(false);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column', marginRight: '5px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '10px' }}>
                            RECAN Bag
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            <InputBase
                                placeholder={'0'}
                                type={'number'}
                                inputMode={'numeric'}
                                sx={sx}
                                id={`items[${i}].bag1`}
                                value={formik.values?.items[i].bag1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={checkFromError(i, 'bag1', 'check') && checkFromTouched(i, 'bag1')}
                            />
                        </Typography>
                    </Box>
                    {checkFromTouched(i, 'bag1') && checkFromError(i, 'bag1', 'check') && (
                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                            {checkFromTouched(i, 'bag1') && checkFromError(i, 'bag1', 'render')}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '10px' }}>
                            Rubbish Bag
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            <InputBase
                                placeholder={'0'}
                                type={'number'}
                                inputMode={'numeric'}
                                sx={sx}
                                id={`items[${i}].bag2`}
                                value={formik.values?.items[i].bag2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={checkFromError(i, 'bag2', 'check') && checkFromTouched(i, 'bag2')}
                            />
                        </Typography>
                    </Box>
                    {checkFromTouched(i, 'bag2') && checkFromError(i, 'bag2', 'check') && (
                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                            {checkFromTouched(i, 'bag2') && checkFromError(i, 'bag2', 'render')}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column', marginRight: '5px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '10px' }}>
                            Wheelie Bin
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            <InputBase
                                placeholder={'0'}
                                type={'number'}
                                inputMode={'numeric'}
                                sx={sx}
                                id={`items[${i}].bag3`}
                                value={formik.values?.items[i].bag3}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={checkFromError(i, 'bag3', 'check') && checkFromTouched(i, 'bag3')}
                            />
                        </Typography>
                    </Box>
                    {checkFromTouched(i, 'bag3') && checkFromError(i, 'bag3', 'check') && (
                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                            {checkFromTouched(i, 'bag3') && checkFromError(i, 'bag3', 'render')}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ display: 'flex', width: '50%', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography align="left" variant="subtitle2" noWrap sx={{ marginRight: '10px' }}>
                            Bulk Bag
                        </Typography>
                        <Typography align="left" variant="subtitle2" noWrap>
                            <InputBase
                                placeholder={'0'}
                                type={'number'}
                                inputMode={'numeric'}
                                sx={sx}
                                id={`items[${i}].bag4`}
                                value={formik.values?.items[i].bag4}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={checkFromError(i, 'bag4', 'check') && checkFromTouched(i, 'bag4')}
                            />
                        </Typography>
                    </Box>
                    {checkFromTouched(i, 'bag4') && checkFromError(i, 'bag4', 'check') && (
                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                            {checkFromTouched(i, 'bag4') && checkFromError(i, 'bag4', 'render')}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ManualGroupInput;

import { Box, Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Profile from '../userProfile/Profile';
import PushMail from './push.mail';
import PushPhone from './push.phone';
import PushSms from './push.sms';

function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`user-tabpanel-${index}`} aria-labelledby={`user-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `push-tab-${index}`,
        'aria-controls': `user-tabpanel-${index}`
    };
}
interface Props {
    selectRows: number[];
    onRefresh: () => void;
}

export const PushSender = ({ selectRows, onRefresh }: Props) => {
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Tabs
                value={value}
                indicatorColor={'primary'}
                onChange={handleChange}
                sx={{
                    mb: 3,
                    minHeight: 'auto',
                    '& button': {
                        minWidth: 100
                    },
                    '& a': {
                        minHeight: 'auto',
                        minWidth: 10,
                        py: 1.5,
                        px: 1,
                        mr: 2.25,
                        color: 'grey.600'
                    },
                    '& a.Mui-selected': {
                        color: 'primary.main'
                    }
                }}
                aria-label="simple tabs example"
                variant="scrollable"
            >
                <Tab component={Link} to="#" label="Mail" {...a11yProps(0)} />
                <Tab component={Link} to="#" label="Phone" {...a11yProps(1)} />
                <Tab component={Link} to="#" label="SMS" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <PushMail selectRows={selectRows} onRefresh={onRefresh} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PushPhone selectRows={selectRows} onRefresh={onRefresh} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PushSms selectRows={selectRows} onRefresh={onRefresh} />
            </TabPanel>
        </div>
    );
};

// material-ui
import {
    Box,
    Button,
    Drawer,
    Grid,
    Typography,
    Autocomplete,
    CardMedia,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    OutlinedInput,
    InputAdornment,
    IconButton,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress,
    ListItemText,
    TableHead,
    Pagination,
    Menu
} from '@mui/material';
import { BeforeListSelectChange, BeforeListSelectConst, BeforeListSelectDesc } from './before.list';
import { userBeforeTypes } from '../../types/before';
import { useTheme } from '@mui/system';
import useAxios from '../../hooks/useAxios';
import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { IconSearch } from '@tabler/icons';
import BeforeRow from './before.row';
import { gridSpacing } from '../../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

interface Props {
    refresh: boolean;
    open: boolean;
    outRow: number[];
    handleDrawerOpen: () => void;
    addUser: (rows: number[]) => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const BeforeDrawerWrapper = ({ refresh, open, outRow, handleDrawerOpen, addUser }: Props) => {
    console.log('Refresh', refresh);
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<userBeforeTypes[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        search: string;
        change: 'All' | 'Complete' | 'Non';
        send: 'All' | 'Complete' | 'Non';
        sort: 'credit' | 'collection' | 'none';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        search: '',
        change: 'All',
        send: 'All',
        sort: 'none'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/before-store/list/out', { ...listParam, out: outRow });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
        setSelectRows([...[]]);
    };

    React.useEffect(() => {
        getData();
    }, [listParam, outRow]);
    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const rowChange = (row) => {
        setListParam((p) => ({ ...p, take: row }));
    };
    const handleChangeSortSelect = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, sort: value }));
    };
    const handleChangeSortSelectDESC = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, nameSort: value }));
    };
    const handleChangeCheck = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setListParam((p) => ({ ...p, change: value }));
    };
    const handleChangeTake = (value) => {
        setListParam((p) => ({ ...p, take: value }));
        setAnchorEl(null);
    };
    const [selectRows, setSelectRows] = useState<number[]>([]);
    const handleSelectRow = (idx) => {
        const find = selectRows.find((v) => v === idx);
        if (find) {
            const changeData = selectRows.filter((v) => v !== idx);
            setSelectRows([...changeData]);
        } else {
            const changeData = [...selectRows, idx];
            setSelectRows([...changeData]);
        }
    };
    const handleSelectAllRow = () => {
        const find = data.map((v) => v.idx);
        if (selectRows.length === find.length) {
            setSelectRows([...[]]);
        } else {
            const changeData = [...find];
            setSelectRows([...changeData]);
        }
    };
    const [search, setSearch] = useState('');
    const handleSelectSearch = () => {
        setListParam((p) => ({ ...p, search }));
    };
    const navigation = useNavigate();
    const goToMailSend = () => {
        if (selectRows.length > 0) {
            addUser(selectRows);
            handleDrawerOpen();
        }
    };
    return (
        <Drawer
            sx={{
                ml: open ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: '70vw' },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: '70vw' },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={open}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            <Box sx={{ p: 3 }}>
                <>
                    <Grid container justifyContent={'space-between'} p={'1rem'} alignItems={'center'}>
                        <Grid item>
                            <div>
                                <FormControl sx={{ m: 1, width: 250 }}>
                                    <Select
                                        labelId="beforeListSelect"
                                        id="beforeListSelect"
                                        value={listParam.sort}
                                        onChange={handleChangeSortSelect}
                                        // input={<OutlinedInput label="Tag" />}
                                        MenuProps={MenuProps}
                                    >
                                        {BeforeListSelectConst.map((value, i) => (
                                            <MenuItem key={`demp-dfdsfid-${i}`} value={value.value}>
                                                <ListItemText primary={value.label} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 100 }}>
                                    <Select
                                        labelId="beforeListSelectDESC"
                                        id="beforeListSelectDESC"
                                        value={listParam.nameSort}
                                        onChange={handleChangeSortSelectDESC}
                                        // input={<OutlinedInput label="Tag" />}
                                        MenuProps={MenuProps}
                                    >
                                        {BeforeListSelectDesc.map((value, i) => (
                                            <MenuItem key={`demsp-dfdsfid-${i}`} value={value.value}>
                                                <ListItemText primary={value.label} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 100 }}>
                                    <Select
                                        labelId="beforeListSelectChange"
                                        id="beforeListSelectChange"
                                        value={listParam.change}
                                        onChange={handleChangeCheck}
                                        // input={<OutlinedInput label="Tag" />}
                                        MenuProps={MenuProps}
                                    >
                                        {BeforeListSelectChange.map((value, i) => (
                                            <MenuItem key={`demsp-dfdsfid-${i}`} value={value.value}>
                                                <ListItemText primary={value.label} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item>
                            <OutlinedInput
                                id="input-search"
                                placeholder="Search"
                                value={search}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSelectSearch();
                                    }
                                }}
                                onChange={(e) => setSearch(e.currentTarget.value)}
                                startAdornment={
                                    <InputAdornment position="start" onClick={() => handleSelectSearch()} sx={{ cursor: 'pointer' }}>
                                        <IconSearch stroke={0.5} size="0.5rem" />
                                    </InputAdornment>
                                }
                                size="small"
                            />
                            <Button disabled={selectRows.length === 0} onClick={() => goToMailSend()} variant={'outlined'} sx={{ ml: 1 }}>
                                ADD
                            </Button>
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ p: 0, pl: 3, cursor: 'pointer', width: 100 }} onClick={() => handleSelectAllRow()}>
                                        <div>Check ({selectRows.length})</div>
                                    </TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>User Profile</TableCell>
                                    <TableCell>Collections</TableCell>
                                    <TableCell>Credit</TableCell>
                                    <TableCell>Sends</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.map((row, index) => (
                                        <BeforeRow handleSelectRow={handleSelectRow} selectRows={selectRows} row={row} index={index} />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid item xs={12} sx={{ p: 3 }}>
                        <Grid container justifyContent="space-between" spacing={gridSpacing}>
                            <Grid item>
                                <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                            </Grid>
                            <Grid item>
                                <Button
                                    size="large"
                                    sx={{ color: theme.palette.grey[900] }}
                                    color="secondary"
                                    endIcon={<ExpandMoreRoundedIcon />}
                                    onClick={handleClick}
                                >
                                    {`${listParam.take} Rows`}
                                </Button>
                                <Menu
                                    id="menu-user-list"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    variant="selectedMenu"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                >
                                    {new Array(10).fill(10).map((v, i) => (
                                        <MenuItem onClick={() => handleChangeTake(v + i * v)}> {v + i * v}+</MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </Box>
        </Drawer>
    );
};
export default BeforeDrawerWrapper;

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Button,
    Checkbox,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import { jsPDF } from 'jspdf';
import useAxiosServices from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
import { LOADING_END, LOADING_START } from '../../store/actions';
import Calendar from 'react-calendar';
import FormControl from '../../ui-component/extended/Form/FormControl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';

const validationSchema = yup.object({
    textLink: yup.string(),
    feedLink: yup.string(),
    copyLink: yup.string(),
    moreLink: yup.string()
});
const CommonSetting = () => {
    const formik = useFormik({
        initialValues: {
            textLink: '',
            feedLink: '',
            copyLink: '',
            moreLink: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            console.log('value', values);
            await updateInfo(values);
        }
    });

    const theme = useTheme();
    const dispatch = useDispatch();
    const { axiosService } = useAxiosServices();
    const [value, onChange] = useState(new Date());

    const [credit, setCredit] = useState(false);
    const [paypal, setPayPal] = useState(false);
    const [giftPay, setGiftPay] = useState(false);
    const [bank, setBank] = useState(false);

    const [week, setWeek] = useState([0, 0, 0, 0, 0, 0, 0]);

    const weekHandler = (i) => {
        const temp = [...week];
        const target = temp[i] === 0 ? 1 : 0;
        const convert = [...temp];
        convert[i] = target;
        setWeek([...convert]);
    };

    const dateDisabled = (date: any) => {};

    const getInfo = async () => {
        const api = await axiosService.post('/statistics/setting/get');
        const { status, data } = api.data;
        if (status) {
            formik.setFieldValue('textLink', data.textLink ?? '');
            formik.setFieldValue('feedLink', data.feedLink ?? '');
            formik.setFieldValue('moreLink', data.moreLink ?? '');
            formik.setFieldValue('copyLink', data.copyLink ?? '');
        }
    };
    const updateInfo = async (values) => {
        const api = await axiosService.post('/statistics/share/update', values);
        const { status } = api.data;
        if (status) {
            toast.info('Success Link update', { ...ToastSetting.default });
            getInfo();
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Recan Setting</Typography>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <Grid container alignItems={'center'} justifyContent={'center'} p={10}>
                {/* <Grid item xs={12} md={6}> */}
                {/*    <Grid xs={12} mb={2}> */}
                {/*        <Typography variant={'h4'}>Credit will be paid immediately</Typography> */}
                {/*        <Switch */}
                {/*            checked={credit} */}
                {/*            onChange={(event, checked) => setCredit(checked)} */}
                {/*            inputProps={{ 'aria-label': 'checked' }} */}
                {/*        /> */}
                {/*    </Grid> */}
                {/*    <Grid xs={12} mb={2}> */}
                {/*        <Typography variant={'h4'}>Gift Pay Enabled</Typography> */}
                {/*        <Switch */}
                {/*            checked={giftPay} */}
                {/*            onChange={(event, checked) => setGiftPay(checked)} */}
                {/*            inputProps={{ 'aria-label': 'checked' }} */}
                {/*        /> */}
                {/*    </Grid> */}
                {/*    <Grid xs={12} mb={2}> */}
                {/*        <Typography variant={'h4'}>Bank Transfer Enabled</Typography> */}
                {/*        <Switch checked={bank} onChange={(event, checked) => setBank(checked)} inputProps={{ 'aria-label': 'checked' }} /> */}
                {/*    </Grid> */}
                {/*    <Grid xs={12} mb={2}> */}
                {/*        <Typography variant={'h4'}>Paypal Enabled</Typography> */}
                {/*        <Switch */}
                {/*            checked={paypal} */}
                {/*            onChange={(event, checked) => setPayPal(checked)} */}
                {/*            inputProps={{ 'aria-label': 'checked' }} */}
                {/*        /> */}
                {/*    </Grid> */}
                {/* </Grid> */}
                <Grid item xs={12} md={6}>
                    <Typography variant={'h3'} mb={2}>
                        Social Link Setting
                    </Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={gridSpacing}>
                            <Grid container item xs={12}>
                                <Grid item xs={10}>
                                    <TextField
                                        id="textLink"
                                        fullWidth
                                        label="Text Link"
                                        value={formik.values.textLink}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.textLink && Boolean(formik.errors.textLink)}
                                        helperText={formik.touched.textLink && formik.errors.textLink}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={10}>
                                    <TextField
                                        id="feedLink"
                                        fullWidth
                                        label="Feed Link"
                                        value={formik.values.feedLink}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.feedLink && Boolean(formik.errors.feedLink)}
                                        helperText={formik.touched.feedLink && formik.errors.feedLink}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={10}>
                                    <TextField
                                        id="copyLink"
                                        fullWidth
                                        label="Copy Link"
                                        value={formik.values.copyLink}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.copyLink && Boolean(formik.errors.copyLink)}
                                        helperText={formik.touched.copyLink && formik.errors.copyLink}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={10}>
                                    <TextField
                                        id="moreLink"
                                        fullWidth
                                        label="More Link"
                                        value={formik.values.moreLink}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.moreLink && Boolean(formik.errors.moreLink)}
                                        helperText={formik.touched.moreLink && formik.errors.moreLink}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid container item xs={12}>
                        <Grid item xs={4} my={4}>
                            <Button onClick={() => formik.handleSubmit()}>Update</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default CommonSetting;

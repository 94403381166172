import React, { useState } from 'react';
import {
    Box,
    Button,
    ButtonBase,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    NativeSelect,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { gridSpacing } from '../../store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { PickItemMaterialInitValue } from '../../types/cal.type';
import {
    AllBagItemCal,
    BagMaterialCalResult,
    BagMaterialPercentage,
    getRandomIntInclusive,
    MinimalBagMaterialTypeSwitch,
    roundToTwo,
    RowBagItemCal
} from '../../utils/common';
import { IconSquareMinus } from '@tabler/icons';

const calSelectMenu = [
    { label: 'On-demand Pick up', value: '1' },
    { label: 'Regular Pick up ', value: '2' },
    { label: 'Charity & Schools', value: '3' }
];

const addRowType = [
    { label: 'Manual Input', value: '1' },
    { label: 'Random Input', value: '2' }
];

const itemSchema = {
    items: yup.array().of(
        yup.object().shape({
            all: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero')

            // clearPlastic: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero'),
            //
            // aluminium: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero'),
            //
            // coloredPlastic: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero'),
            //
            // hdpe: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero'),
            //
            // lpb: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero'),
            //
            // steel: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero'),
            //
            // ineligible: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero'),
            //
            // other: yup.number().typeError('you must specify a number').moreThan(-1, 'should not be zero')
        })
    )
};

const itemValidationSchema = yup.object().shape(itemSchema);

const CalPage = () => {
    const itemFormik = useFormik({
        initialValues: { items: [{ all: 0 }] },
        validationSchema: itemValidationSchema,
        validate: () => {
            console.log('dd');
        },
        onSubmit: async (values) => {
            const { items: itemUpdateValue } = values;
        }
    });

    const checkFromTouched = (index, id) => {
        const list = itemFormik.touched.items as any[];

        if (list) {
            if (list.length > 0) {
                return itemFormik.touched.items[index]?.[`${id}`] ?? false;
            }
        }
        return false;
    };

    const checkFromError = (index, id, type) => {
        const list = itemFormik.errors.items as any[];

        if (list) {
            if (list.length > 0) {
                if (type === 'check') {
                    return Boolean(list[index]?.[`${id}`] ?? false);
                } else {
                    return list[index]?.[`${id}`] ?? '';
                }
            }
        }
        return undefined;
    };

    const [calType, setCalType] = useState('1');
    const [inputType, setInputType] = useState('1');
    const handleSortType = (event: any) => {
        event?.target.value && setCalType(event?.target.value);
    };

    const handleSortType2 = (event: any) => {
        event?.target.value && setInputType(event?.target.value);
    };
    const randomNumber = Math.random();
    const addDataItems = () => {
        const addData = { ...{ all: 0 } };
        const addData2 = Object.keys(PickItemMaterialInitValue).reduce(
            (prev, acc) => ({ ...prev, [acc]: getRandomIntInclusive(1, 100) }),
            {}
        );
        const list = [...itemFormik.values.items, inputType === '1' ? addData : addData2];
        itemFormik.setFieldValue('items', list);
    };
    const removeItems = (index) => {
        const list = [...itemFormik.values.items].filter((v, i) => i !== index);
        itemFormik.setFieldValue('items', list);
    };
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Calculator</Typography>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} sx={{ padding: '10px' }}>
                    {/* <FormControl> */}
                    {/*    <InputLabel variant="standard" htmlFor="uncontrolled-native"> */}
                    {/*        Add type */}
                    {/*    </InputLabel> */}
                    {/*    <NativeSelect */}
                    {/*        id="ncontrolled-native2" */}
                    {/*        name="ncontrolled-native2" */}
                    {/*        value={inputType} */}
                    {/*        onChange={(e) => handleSortType2(e)} */}
                    {/*        inputProps={{ 'aria-label': 'Without label' }} */}
                    {/*    > */}
                    {/*        {addRowType.map(({ value: monthValue, label }, index: number) => ( */}
                    {/*            <option key={index} value={monthValue}> */}
                    {/*                {label} */}
                    {/*            </option> */}
                    {/*        ))} */}
                    {/*    </NativeSelect> */}
                    {/* </FormControl> */}
                    {/* <Button variant="contained" onClick={() => addDataItems()} sx={{ marginLeft: '10px' }}> */}
                    {/*    Add row */}
                    {/* </Button> */}
                </Box>
                <Box display={'flex'} width={'100%'} sx={{ padding: '20px', flexDirection: 'column' }}>
                    <Box sx={{ width: '30%' }}>
                        <FormControl>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Credit type
                            </InputLabel>
                            <NativeSelect
                                id="ncontrolled-native"
                                name="ncontrolled-native"
                                value={calType}
                                onChange={(e) => handleSortType(e)}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {calSelectMenu.map(({ value: monthValue, label }, index: number) => (
                                    <option key={index} value={monthValue}>
                                        {label}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Box>

                    <Box display={'flex'} width={'100%'} sx={{ marginTop: '20px' }}>
                        <TableContainer sx={{ width: '600px' }}>
                            <Table size="small" aria-label="collapsible table">
                                <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                    <TableRow>
                                        <TableCell sx={{ maxWidth: '60px' }}>Containers</TableCell>
                                        <TableCell sx={{ maxWidth: '60px' }}>Refund Rate</TableCell>
                                        <TableCell sx={{ maxWidth: '60px' }}>Services Fee</TableCell>
                                        <TableCell sx={{ maxWidth: '60px' }}>Refund</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    sx={{
                                        '& th,& td': {
                                            whiteSpace: 'nowrap'
                                        }
                                    }}
                                >
                                    <TableCell component="th" scope="row" sx={{ maxWidth: '60px' }}>
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            <InputBase
                                                placeholder={'containers'}
                                                type={'number'}
                                                inputMode={'numeric'}
                                                sx={{ minWidth: '80px', width: '80px' }}
                                                id={`items[${0}][${'all'}]`}
                                                value={itemFormik.values?.items[0].all}
                                                onChange={itemFormik.handleChange}
                                                onBlur={itemFormik.handleBlur}
                                                error={checkFromError(0, 'all', 'check') && checkFromTouched(0, 'all')}
                                            />
                                        </Typography>
                                        {checkFromTouched(0, 'all') && checkFromError(0, 'all', 'check') && (
                                            <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                {checkFromTouched(0, 'all') && checkFromError(0, 'all', 'render')}
                                            </Typography>
                                        )}
                                    </TableCell>
                                    {BagMaterialPercentage(calType, AllBagItemCal(itemFormik.values.items)).map((v, i) => (
                                        <>
                                            {/* eslint-disable-next-line no-nested-ternary */}
                                            {i === 0 ? (
                                                <TableCell component="th" scope="row" sx={{ maxWidth: '60px' }}>
                                                    {v}%
                                                </TableCell>
                                            ) : i === 1 ? (
                                                <TableCell component="th" scope="row" sx={{ maxWidth: '60px' }}>
                                                    ${roundToTwo(v).toFixed(2)}
                                                </TableCell>
                                            ) : (
                                                <TableCell component="th" scope="row" sx={{ maxWidth: '60px' }}>
                                                    $ {MinimalBagMaterialTypeSwitch(calType, v).toFixed(2)}
                                                </TableCell>
                                            )}
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <Box sx={{ display: 'none' }}>
                    <TableContainer sx={{ transform: 'rotateX(180deg)', padding: '20px', width: '200px' }}>
                        <Table size="small" aria-label="collapsible table" sx={{ transform: 'rotateX(180deg)' }}>
                            <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                <TableRow>
                                    <TableCell sx={{ maxWidth: '60px' }}>#</TableCell>
                                    {/* <TableCell>All</TableCell> */}
                                    {Object.keys({ all: 0 }).map((v, i) => (
                                        <TableCell sx={{ maxWidth: '60px' }}>{'Containers'}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody
                                sx={{
                                    '& th,& td': {
                                        whiteSpace: 'nowrap'
                                    }
                                }}
                            >
                                {itemFormik.values?.items.map((v, i) => (
                                    <TableRow hover>
                                        <TableCell component="th" scope="row" sx={{ maxWidth: '60px', width: '60px' }}>
                                            <Typography
                                                align="left"
                                                variant="subtitle2"
                                                noWrap
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => removeItems(i)}
                                            >
                                                <IconSquareMinus />
                                            </Typography>
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row" sx={{ width: '60px' }}> */}
                                        {/*    {RowBagItemCal(v)} */}
                                        {/* </TableCell> */}
                                        {Object.entries(v).map(([itemKey, itemValue], index) => (
                                            <TableCell component="th" scope="row" sx={{ maxWidth: '60px' }}>
                                                <Typography align="left" variant="subtitle2" noWrap>
                                                    <InputBase
                                                        placeholder={itemKey}
                                                        type={'number'}
                                                        inputMode={'numeric'}
                                                        sx={{ minWidth: '80px', width: '80px' }}
                                                        id={`items[${i}][${itemKey}]`}
                                                        value={itemFormik.values?.items[i][itemKey]}
                                                        onChange={itemFormik.handleChange}
                                                        onBlur={itemFormik.handleBlur}
                                                        error={checkFromError(i, itemKey, 'check') && checkFromTouched(i, itemKey)}
                                                    />
                                                </Typography>
                                                {checkFromTouched(i, itemKey) && checkFromError(i, itemKey, 'check') && (
                                                    <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                        {checkFromTouched(i, itemKey) && checkFromError(i, itemKey, 'render')}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </MainCard>
        </>
    );
};

export default CalPage;

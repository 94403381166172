import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid, Input } from '@mui/material';
import { GoogleMap, Marker, Polyline, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { useTheme } from '@mui/material/styles';

// Temp
interface Props {
    initLatitude?: number;
    initLongitude?: number;
    postAddress: (add: string, lat: number, lng: number) => void;
}

const containerStyle = {
    width: '400px',
    height: '400px'
};
const mapLib: any = ['places'];
const SearchAddressBox = ({ initLatitude, initLongitude, postAddress }: Props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [center, setCenter] = useState({
        lat: -33.8688197,
        lng: 151.2092955
    });
    const [markerPosition, setMarkerPosition] = useState(null);
    const memoAddress = (add, lat, long) => {
        postAddress(add, lat, long);
    };

    const init = (m: google.maps.Map) => {
        const settingLat = initLatitude ?? -33.8688197;
        const settingLon = initLongitude ?? 151.2092955;
        setCenter({
            lat: settingLat,
            lng: settingLon
        });
        makeBounds(m, settingLat, settingLon);
    };

    const makeBounds = (m: google.maps.Map, lat: number, lng: number): void => {
        const make = new google.maps.LatLngBounds({ lat, lng }, { lat, lng });
        const aosition = new google.maps.LatLng({ lat, lng });
        setMarkerPosition(aosition);
        m.fitBounds(make);
        m.setCenter({ lat, lng });
        const zoom = m.getZoom();
        m.setZoom(zoom > 15 ? 15 : zoom);
        setMap(m);
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAD_g8VuFlAZkj7aBSPBRicjeT3AQtdPQI',
        libraries: mapLib
    });

    const onLoad = (m) => {
        init(m);
    };

    const onUnmount = React.useCallback(() => {
        setMap(null);
    }, []);

    const handleLoad = () => {
        console.log('a');
    };

    const [map, setMap] = React.useState(null);

    const handlePlacesChanged = () => {
        try {
            const place = mapRef.current?.state.searchBox.getPlaces();
            console.log(place);
            if (place.length > 0) {
                const lat = place[0].geometry.location.lat();
                const lng = place[0].geometry.location.lng();
                const addressText = place[0].formatted_address;
                makeBounds(map, lat, lng);
                memoAddress(addressText, lat, lng);
            }
        } catch (e) {
            console.log(e);
            alert('sorry, please try again');
        }
    };
    const mapRef = useRef<StandaloneSearchBox>(null);
    const options: google.maps.MapOptions = {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        maxZoom: 19,
        minZoom: 5
    };
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Box sx={{ height: 400, width: 400 }} maxWidth="100%" maxHeight="100%">
                {!isLoaded ? (
                    <div>loading</div>
                ) : (
                    <GoogleMap
                        options={options}
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        {/* Child components, such as markers, info windows, etc. */}
                        <StandaloneSearchBox ref={mapRef} onLoad={handleLoad} onPlacesChanged={handlePlacesChanged}>
                            <Input
                                type="text"
                                placeholder="Enter your placeholder"
                                style={{
                                    background: '#fff',
                                    boxSizing: `border-box`,
                                    border: `1px solid transparent`,
                                    width: `240px`,
                                    height: `32px`,
                                    padding: `0 12px`,
                                    borderRadius: `3px`,
                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                    fontSize: `14px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                    position: 'absolute',
                                    left: '50%',
                                    marginLeft: '-120px'
                                }}
                            />
                        </StandaloneSearchBox>
                        {markerPosition && <Marker position={markerPosition} />}
                    </GoogleMap>
                )}
            </Box>
        </Grid>
    );
};
const AnyReactComponent = ({ text, ...props }) => <div>{text}</div>;
export default SearchAddressBox;

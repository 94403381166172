import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Stack, TextareaAutosize, TextField, Typography } from '@mui/material';
import { gridSpacing } from '../../store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { userListProfile } from '../../_mockApis/user-profile/types';
import MaskedInput from 'react-text-mask';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import MapDialog from '../../ui-component/dialog/MapDialog';
import { Search } from '@mui/icons-material';
import useAxiosServices from '../../hooks/useAxios';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ReactDraftWysiwyg from '../../ui-component/editor/ReactDraftWysiwyg';
import ImageUploader from '../../ui-component/uploader/imageUploader';
import ChairtyUserAdd from './chairty.userAdd';

const validationSchema = yup.object({
    title: yup.string().min(2, 'title should be of minimum 2 characters length').required('title is required'),
    subTitle: yup.string().min(2, 'subTitle should be of minimum 2 characters length').required('subTitle is required'),
    fund: yup.number().required('fund is required')
});

const CharityUpdate = () => {
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const navigate = useNavigate();
    const { idx } = useParams();
    const [depotType, setDepotType] = useState<number>(0);
    const [profile, setProfile] = useState<userListProfile | null>(null);

    const formik = useFormik({
        initialValues: {
            title: '',
            subTitle: '',
            fund: 0
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const [open, setOpen] = useState(false);
    const [init, setInit] = useState('');
    const [content, setContent] = useState('');
    const handlerAddress = (add, lat, lot) => {
        formik.setFieldValue('userAddress', add);
        setOpen((p) => !p);
    };

    useEffect(() => {
        getFetch();
    }, [idx]);

    const getFetch = async () => {
        try {
            const api = await axiosService.post('charity/detail', { idx });
            const { status, data } = api.data;
            if (status) {
                console.log('data', data);
                await formik.setFieldValue('title', data.title);
                await formik.setFieldValue('subTitle', data.subTitle);
                await formik.setFieldValue('fund', data.fund);
                setInit(data.content);
                setContent(data.content);
                setImage1(data.mainImage);
                setImage2(data.logoImage);
                data.user && setProfile({ ...data.user });
            }
        } catch (e) {}
    };

    const createDepot = async () => {
        try {
            const v = formik.values;
            const api = await axiosService.post('/users/admin/depotCreate', v);
            const { status, data } = api.data;
            if (status) {
                toast.info('Success Create Depot', { ...ToastSetting.default });
                navigate('/user/depot');
            } else {
                toast.error(data, { ...ToastSetting.default });
            }
        } catch (e) {
            toast.error('Error', { ...ToastSetting.default });
        } finally {
            setAlertOpen(false);
        }
    };

    const [alertOpen, setAlertOpen] = useState(false);

    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);

    const [mainImage, setMainImage] = useState(null);
    const [logoImage, setLogoImage] = useState(null);

    const imageHandler = (file: any) => {
        setMainImage(file[0]);
    };
    const imageHandler2 = (file: any) => {
        setLogoImage(file[0]);
    };
    const onCancel = () => {
        setMainImage(null);
    };
    const onCancel2 = () => {
        setLogoImage(null);
    };

    const insertFetch = async () => {
        const { title, fund, subTitle } = formik.values;

        if (content === '') {
            return alert('Please add Content');
        }
        if (!profile) {
            return alert('Please add Charity User');
        }
        try {
            const form = new FormData();
            form.append('title', title);
            form.append('subTitle', subTitle);
            form.append('fund', String(fund));
            form.append('content', content);
            form.append('mainImage', mainImage);
            form.append('logoImage', logoImage);
            form.append('idx', idx);
            form.append('userIdx', String(profile.idx));

            const api = await axiosService.post('charity/update', form, {
                headers: { 'Content-type': 'multipart/form-data' }
            });

            const { status } = api;
            if (status) {
                toast.info('Success Update Chairty', { ...ToastSetting.default });
                navigate('/charity/list');
            } else {
                toast.info('Error', { ...ToastSetting.default });
            }
            return;
        } catch (e) {
            toast.info('Error', { ...ToastSetting.default });
        } finally {
            setAlertOpen(false);
        }
    };

    const [deleteOpen, setDeleteOpen] = useState(false);

    const deletetFetch = async () => {
        try {
            const api = await axiosService.post('charity/delete', { idx });
            const { status } = api;
            if (status) {
                toast.info('Success Delete Charity', { ...ToastSetting.default });
                navigate('/charity/list');
            } else {
                toast.info('Error', { ...ToastSetting.default });
            }
            return;
        } catch (e) {
            toast.info('Error', { ...ToastSetting.default });
        } finally {
            setDeleteOpen(false);
        }
    };

    const handleProfile = (data) => {
        setProfile({ ...data });
    };
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Detail</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button onClick={() => setDeleteOpen(true)} size="large" color="primary">
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing} p={2}>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant={'h4'}>MainImage</Typography>
                            <ImageUploader imageURL={image1} onChange={imageHandler} onCancel={onCancel} />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <Typography variant={'h4'}>Logo</Typography>
                            <ImageUploader imageURL={image2} onChange={imageHandler2} onCancel={onCancel2} />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'}>
                            <ChairtyUserAdd driverHandler={handleProfile} initProfile={profile} />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="title"
                                fullWidth
                                label="Title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="subTitle"
                                fullWidth
                                label="Sub Title"
                                value={formik.values.subTitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.subTitle && Boolean(formik.errors.subTitle)}
                                helperText={formik.touched.subTitle && formik.errors.subTitle}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                id="fund"
                                type={'number'}
                                fullWidth
                                label="Fund Amount"
                                value={formik.values.fund}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.fund && Boolean(formik.errors.fund)}
                                helperText={formik.touched.fund && formik.errors.fund}
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                '& .rdw-editor-wrapper': {
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                                    border: '1px solid',
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                    borderRadius: '12px',
                                    overflow: 'hidden',
                                    '& .rdw-editor-main': {
                                        px: 2,
                                        py: 0.5,
                                        border: 'none',
                                        minHeight: '300px'
                                    },
                                    '& .rdw-editor-toolbar': {
                                        pt: 1.25,
                                        border: 'none',
                                        borderBottom: '1px solid',
                                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                        bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                        '& .rdw-option-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                        },
                                        '& .rdw-dropdown-wrapper': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                            '& .rdw-dropdown-selectedtext': {
                                                color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            <Stack spacing={gridSpacing}>
                                {init !== '' && <ReactDraftWysiwyg init={init} value={content} setValue={setContent} />}
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button variant="contained" type={'submit'}>
                                        Update Charity
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>

            <AlertDialog
                title={'Update Charity'}
                content={`Do you want to Update Charity?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => insertFetch()}
            />
            <AlertDialog
                title={'Delete Charity'}
                content={`Do you want to Delete Charity?`}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deletetFetch()}
            />
        </>
    );
};

export default CharityUpdate;

// material-ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { pickListTypes } from '../../types/pick';
import MainCard from '../cards/MainCard';
import { addressFormat2, bagCalcOnlyNumber, bagCalcSwitch, BookStatusCase, numberWithCommas, TransactionBagCase } from '../../utils/common';
import dayFormat, { dayFormatTime } from '../../utils/times/timeFormats';
import Zoom from 'react-medium-image-zoom';
import { QrCodeOutlined } from '@mui/icons-material';
import { pickUpUserName } from '../../utils/users.helper';

// types
interface Props {
    title: string;
    content: string;
    open: boolean;
    handleClose: Dispatch<SetStateAction<Boolean>>;
    handleSuccess: () => void;
    row: pickListTypes;
}

export default function PickDialog({ title, content, open, handleClose, handleSuccess, row }: Props) {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="item-delete-title"
            aria-describedby="item-delete-description"
        >
            <DialogActions sx={{ mr: 2 }}>
                <Button onClick={() => handleClose(false)} color="error">
                    Close
                </Button>
            </DialogActions>
            <DialogContent>
                <MainCard title={'Pickup Information'} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid item xs zeroMinWidth>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2} xs={12}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Status
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="subtitle1" noWrap>
                                            {BookStatusCase(row.status)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2} xs={12}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {`${addressFormat2(row.addressZip ?? '', '', row.address ?? '')}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {row?.user.userType === 2 && (
                                    <Grid container alignItems={'center'} marginY={2}>
                                        <Grid item mr={2} xs={12}>
                                            <Typography align="left" variant="subtitle2" component="div">
                                                Business Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align="left" variant="subtitle1" noWrap>
                                                {row.user.businessName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2} xs={12}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Name
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="subtitle1" noWrap>
                                            {pickUpUserName(row)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2} xs={12}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="subtitle1" noWrap>
                                            {row.user.userEmail}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2} xs={12}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Date
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {dayFormat(row.pickDate)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2} xs={12}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Total Litre
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {`${numberWithCommas(bagCalcOnlyNumber(row?.bags ?? []))} L`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {row.bags && row.bags.length > 0 && (
                                            <Grid item xs={12} my={1}>
                                                <TableContainer>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                                            <TableRow>
                                                                <TableCell>Type</TableCell>
                                                                <TableCell>No.</TableCell>
                                                                <TableCell>Litre</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                                            {row.bags.map((v, i) => (
                                                                <TableRow hover>
                                                                    <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>
                                                                        {TransactionBagCase(v.bagType)}
                                                                    </TableCell>
                                                                    <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>
                                                                        {v.bagEa}
                                                                    </TableCell>
                                                                    <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>
                                                                        {`${numberWithCommas(bagCalcSwitch(v))}L`}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2} xs={12}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Pet
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {row.dogCheck === 1 ? 'Yes' : 'No'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={'center'} marginY={2}>
                                    <Grid item mr={2} xs={12}>
                                        <Typography align="left" variant="subtitle2" component="div">
                                            Description
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="subtitle1" component="div">
                                            {row.pickPlace}{' '}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {row.pickImage && (
                                    <Grid container alignItems={'center'} marginY={2}>
                                        <Grid item mr={2} xs={12}>
                                            <Typography align="left" variant="subtitle2" component="div">
                                                Image
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Zoom>
                                                <img
                                                    src={row.pickImage}
                                                    style={{ marginLeft: 10, width: 100, objectFit: 'contain', margin: '0 auto' }}
                                                />
                                            </Zoom>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </MainCard>
            </DialogContent>
        </Dialog>
    );
}

import React, { useState, CSSProperties, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    ButtonBase,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party

// project imports
import { SNACKBAR_OPEN, DELETE_STORY } from 'store/actions';

// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

// types
import { collectionList, pickListTypes } from 'types/pick';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayFormat, { timeFind } from '../../utils/times/timeFormats';
import PickTypeCard from '../../ui-component/swith/pickTypeCard';
import { useNavigate } from 'react-router-dom';
import { orderList } from '../../types/order.type';
import { OrderStatusText } from './order.list';
import OrderMore from './order.more';
import OrderInner from './order.inner';
import { FindOrderTypeConst } from '../../utils/const/order.const';

interface Props {
    value: orderList;
    index: number;
}

const OrderRow = ({ value, index }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [row, setRow] = useState<orderList | null>(null);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    useEffect(() => {
        setRow({ ...value });
    }, [value]);
    console.log('row', value);
    const handleChangeRow = (status: number) => {
        console.log('Chane', status);
        setRow({ ...value, status });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePreventOpen = (e) => {
        e.stopPropagation();
        setOpen((p) => !p);
    };
    const handlePreventGotoUser = (e, idx) => {
        e.stopPropagation();
        navigate(`/user/default/${idx}`);
    };
    console.log(value);
    return (
        <>
            <TableRow hover style={{ cursor: 'pointer' }} onClick={(e) => handlePreventOpen(e)}>
                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.idx}
                    </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography align="left" variant="subtitle2" noWrap>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="subtitle1" component="div">
                                    ORDER{value.orderCode}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs zeroMinWidth onClick={(e) => handlePreventGotoUser(e, value.user?.idx)}>
                            <Typography align="left" variant="subtitle1" component="div">
                                {value.user?.userName} {value.user?.userFirstName}
                            </Typography>

                            <Typography align="left" variant="subtitle2" noWrap>
                                {value.user?.userEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        ${value.totalAmount}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {value.payType === 1 ? 'credit' : value.payType === 2 ? 'paypal' : 'stripe'}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {row && FindOrderTypeConst(row.status).label}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {value.createdAt ? dayFormat(new Date(value.createdAt)) : dayFormat(new Date())}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: 30, minWidth: 60 }}>
                    <Menu
                        id="menu-comment"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                navigate(`/routing/detail/${value.idx}`);
                            }}
                        >
                            More
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                margin: 0
                            }}
                        >
                            {value?.orders && <OrderInner value={row} index={0} handleChangeRow={handleChangeRow} />}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default OrderRow;

import React, { useCallback, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MainCard from '../../ui-component/cards/MainCard';
import useAxios from '../../hooks/useAxios';
import { driverListProfile } from '../../_mockApis/user-profile/types';

import { ChangeEventFunc } from '../../types';
import { useNavigate } from 'react-router-dom';
import { locationListType } from '../../types/pick';
import SuburbRow from './suburb.row';
import { useStyles } from '../../utils/useStyles';
import { useStickyHeader } from '../../utils/hooks/useTabelHeaderSticky';
import MultipleSelect from '../../ui-component/select/multiple.select';
import ProfileMultipleSelect from '../../ui-component/select/profile.select';
import SururbMultipleSelect from './suburb-search-helper/sururb.multiple.select';
import SururbFrequencySelect from './suburb-search-helper/suburb.frequency.select';
import { SubSort } from './suburb-search-helper/suburb.sort';
import { SubAddBox } from './suburb-search-helper/sub.add.box';
import { SubModifyBox } from './suburb-search-helper/sub.modify.box';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

export function switchWeek(i: number) {
    switch (i) {
        case 0:
            return 'Sun';
        case 1:
            return 'Mon';
        case 2:
            return 'Tue';
        case 3:
            return 'Wed';
        case 4:
            return 'Tur';
        case 5:
            return 'Fri';
        case 6:
            return 'Sat';
        default:
            return '';
    }
}
export function switchBag(i: number) {
    switch (i) {
        case 0:
            return 'Basic';
        case 1:
            return 'Business';
        case 2:
            return 'Charity';
        case 3:
            return 'School';
        default:
            return '';
    }
}
const SuburbSettingList = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<locationListType[]>([]);
    const [activeList, setActiveList] = useState<number[]>([]);
    const [fList, setFlist] = useState<string[]>([]);
    const [sort, setSort] = useState<'DESC' | 'ASC'>('ASC');
    const [searchText, setSearchText] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        postSort: 'ASC' | 'DESC';
        status: number;
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        postSort: 'ASC',
        status: 0
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/address/list', { wait: 0, ...listParam, activeList, fList, sort, searchText });
        setData(response.data.list);
        console.log('data', response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    React.useEffect(() => {
        getData();
    }, [listParam, activeList, fList, sort]);
    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigation = useNavigate();
    const [hover, setHover] = useState(null);
    const handleMouseEnter = (index) => {
        setHover(index);
    };

    // 마우스가 셀을 빠져나갈 때 hover 상태를 해제합니다.
    const handleMouseLeave = () => {
        setHover(null);
    };

    const handleWeekList = useCallback(
        async (type) => {
            const idxList = data.map((v) => v.idx);
            const api = await axiosService.post('/address/weekList/change/all', { idx: idxList, type });
            const { data: apiData } = api.data;
            await getData();
            console.log('api', api.data);
        },
        [data, listParam]
    );

    const handleChangeTake = (value) => {
        setListParam((p) => ({ ...p, take: value }));
        setAnchorEl(null);
    };

    const startPageNumber = () => {
        const lowCount = listParam.take;
        const pageCount = listParam.skip;
        const allCount = paging.count;
        const startNumber = lowCount * pageCount;
        return startNumber;
    };

    const [selectedRow, setSelectedRow] = useState<locationListType | null>(null);

    const handleSelectedRow = (row: locationListType) => {
        if (row.idx === selectedRow?.idx) {
            setSelectedRow(null);
        } else {
            setSelectedRow(row);
        }
        getData();
    };

    const handleSortChange = async (type: 'suburb' | 'postcode') => {
        if (type === 'suburb') {
            setListParam((v) => ({ ...v, nameSort: v.nameSort === 'DESC' ? 'ASC' : 'DESC' }));
        }
        if (type === 'postcode') {
            setListParam((v) => ({ ...v, postSort: v.postSort === 'DESC' ? 'ASC' : 'DESC' }));
        }
    };

    const handleResetFetch = () => {
        setListParam((v) => ({
            ...v,
            nameSort: 'ASC',
            postSort: 'ASC',
            status: 0
        }));
        setActiveList([...[]]);
        setFlist([...[]]);
        setSearchText('');
        getData();
    };

    return (
        <MainCard content={false}>
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'space-between' }}>
                <Grid xs={6} display={'flex'}>
                    <SururbMultipleSelect selectMenus={activeList} setSelectTypes={setActiveList} />
                    <SururbFrequencySelect selectMenus={fList} setSelectTypes={setFlist} />
                    <Button size="large" sx={{ color: theme.palette.grey[900] }} color="secondary" onClick={() => handleResetFetch()}>
                        Reset
                    </Button>
                </Grid>
                <Grid xs={6} display={'flex'} justifyContent={'flex-end'}>
                    <Button size="large" sx={{ color: theme.palette.grey[900] }} color="secondary" onClick={() => setModalOpen(true)}>
                        Add
                    </Button>
                    {/* <SubSort sort={sort} setSort={setSort} /> */}
                    <OutlinedInput
                        style={{ marginRight: '10px', marginLeft: '10px' }}
                        id="input-search"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.currentTarget.value)}
                        endAdornment={
                            <InputAdornment position="start" sx={{ cursor: 'pointer' }} onClick={() => getData()}>
                                <IconSearch stroke={1} color={theme.palette.info.main} size="1.5rem" />
                            </InputAdornment>
                        }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                getData();
                            }
                        }}
                    />
                </Grid>

                <Box sx={{ position: 'absolute', top: -20, display: 'flex', flexDirection: 'row' }}>
                    Pick
                    <hr
                        style={{
                            marginLeft: 5,
                            width: '50px',
                            height: '2px',
                            backgroundColor: theme.palette.info.main,
                            border: 'none'
                        }}
                    />
                </Box>
            </Box>
            <div>
                <TableContainer style={{ maxHeight: '700px', tableLayout: 'fixed' }}>
                    <Table>
                        <TableHead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 999 }}>
                            <TableRow>
                                <TableCell sx={{ pl: 3, width: '40px' }}>#</TableCell>
                                <TableCell className={classes.width120}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Suburb
                                        {listParam.nameSort === 'ASC' ? (
                                            <VerticalAlignTopIcon fontSize={'small'} onClick={() => handleSortChange('suburb')} />
                                        ) : (
                                            <VerticalAlignBottomIcon fontSize={'small'} onClick={() => handleSortChange('suburb')} />
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell className={classes.width120}>Council</TableCell>
                                <TableCell className={classes.smallCell}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        Postcode
                                        {listParam.postSort === 'ASC' ? (
                                            <VerticalAlignTopIcon fontSize={'small'} onClick={() => handleSortChange('postcode')} />
                                        ) : (
                                            <VerticalAlignBottomIcon fontSize={'small'} onClick={() => handleSortChange('postcode')} />
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell className={classes.smallCell}>Area</TableCell>
                                <TableCell className={classes.smallCell}>Available</TableCell>
                                <TableCell sx={{ width: '130px' }}>Frequency</TableCell>
                                {[0, 1, 2, 3, 4, 5, 6].map((v, i) => (
                                    <TableCell
                                        sx={{
                                            width: '10px',
                                            padding: '0px',
                                            '&:hover': {
                                                backgroundColor: '#eeeeee'
                                            }
                                        }}
                                        onClick={() => handleWeekList(i)}
                                        onMouseEnter={() => handleMouseEnter(i)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <Typography variant={'h5'} textAlign={'center'}>
                                            {switchWeek(i)}
                                        </Typography>
                                    </TableCell>
                                ))}
                                {[0, 1, 2, 3].map((v, i) => (
                                    <TableCell
                                        sx={{
                                            width: '10px',
                                            padding: '0px'
                                        }}
                                        onClick={() => handleWeekList(i)}
                                    >
                                        <Typography variant={'h5'} textAlign={'center'}>
                                            {switchBag(i)}
                                        </Typography>
                                    </TableCell>
                                ))}
                                <TableCell
                                    align={'center'}
                                    sx={{
                                        width: '30px',
                                        padding: '0px'
                                    }}
                                >
                                    Modify
                                </TableCell>
                                <TableCell className={classes.smallCell}>Remark</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((v, i) => (
                                <SuburbRow
                                    value={v}
                                    index={i}
                                    hover={hover}
                                    key={`subrucRow-${i}`}
                                    rowIndex={startPageNumber()}
                                    handleSelectedRow={handleSelectedRow}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                {listParam.take} Rows
                            </Button>
                            <Menu
                                id="menu-user-list"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                {new Array(10).fill(10).map((v, i) => (
                                    <MenuItem onClick={() => handleChangeTake(v + i * v)}> {v + i * v}+</MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <SubAddBox open={modalOpen} handleClose={setModalOpen} />
            <SubModifyBox open={!!selectedRow} handleClose={handleSelectedRow} selectedData={selectedRow} />
        </MainCard>
    );
};

export default SuburbSettingList;

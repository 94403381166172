import React from 'react';
import { useTheme } from '@mui/styles';
import { Button, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, Typography } from '@mui/material';

import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import BeforeList from './before.list';

const BeforeListWrapper = () => {
    const theme = useTheme();
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Prepare List</Typography>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <BeforeList />
        </MainCard>
    );
};

export default BeforeListWrapper;

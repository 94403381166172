import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    // 여기서 특정 TableCell의 스타일을 조절합니다.
    wideCell: {
        width: '200px', // 너비를 원하는 크기로 조절합니다.,
        padding: '0px !important'
    },
    fixCell: {
        width: '70px',
        padding: '0px !important'
    },
    smallCell: {
        width: '40px',
        padding: '0px !important'
    },
    width120: {
        width: '70px',
        padding: '0px !important'
    },
    header: {
        position: 'sticky',
        top: 0,
        backgroundColor: 'white'
    },
    tableCell: {
        padding: '0px !important',
        width: '120px',
        maxWidth: '120px'
    }
});

import { useDispatch, useSelector } from 'react-redux';

// material-ui
import {
    Box,
    Button,
    Drawer,
    Grid,
    Typography,
    Autocomplete,
    CardMedia,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    OutlinedInput,
    InputAdornment,
    IconButton,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress,
    TableHead,
    Tooltip,
    Stack,
    Link,
    ButtonBase,
    Menu,
    Collapse
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// third party
import * as yup from 'yup';
import { Chance } from 'chance';
import { useFormik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { ADD_ITEM, SNACKBAR_OPEN } from 'store/actions';

// assets
import CancelIcon from '@mui/icons-material/Cancel';
// types
import { DefaultRootStateProps } from 'types';
import { KanbanProfile, KanbanColumn, KanbanUserStory } from 'types/kanban';
import { gridSpacing } from '../../../store/constant';
import { IconRecycle, IconSearch, IconRefresh } from '@tabler/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTheme } from '@mui/system';
import React, { useEffect, useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import AlertRouteDelete from '../AlertRouteDelete';
import { sub } from 'date-fns';
import PickListDetail from '../PickListDetail';
import AddPickUpInfo from '../AddPickupInfo';
import useAxiosServices from '../../../hooks/useAxios';
import { pickListTypes } from '../../../types/pick';

interface Props {
    open: boolean;
    handleDrawerOpen: () => void;
    addPick: (item: pickListTypes) => void;
    initList: pickListTypes[];
}

const chance = new Chance();

const avatarImage = require.context('assets/images/users', true);

const validationSchema = yup.object({
    search: yup.string().required('search text required')
});

const AddPickUpDrawer = ({ open, handleDrawerOpen, addPick, initList }: Props) => {
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const [list, setList] = useState<pickListTypes[]>([]);
    const [reload, setReload] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });

    useEffect(() => {
        getReadyList();
    }, [open, reload]);

    const getReadyList = async (search = false) => {
        const api = await axiosService.post('/pick/list/ready', { ...listParam, searchText: search ? '' : searchText });
        const { data } = api;
        setList([...filteringList(initList, data.list)]);
        setPaging((p) => ({
            ...p,
            allPage: data.allPage,
            count: data.count
        }));
    };

    const filteringList = (init: pickListTypes[], data: pickListTypes[]) => {
        const convertInit = init.map((v) => v.idx);
        return data.filter((v) => !convertInit.includes(v.idx));
    };

    const addItem = async (item: pickListTypes) => {
        addPick(item);
        setReload((p) => !p);
        // handleDrawerOpen();
    };

    const handleSearchCheck = (value: string) => {
        if (!value) {
            return false;
        }
        const lowerValue = value ? value.toLocaleLowerCase() : '';
        const lowerText = searchText.toLocaleLowerCase();
        return lowerValue.includes(lowerText);
    };

    const renderSearchText = (paramList: pickListTypes[]) => {
        if (searchText === '') {
            return paramList;
        }
        const resultList = [];
        for (const row of paramList) {
            if (handleSearchCheck(row.address)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.addressZip)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.addressMore)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.user.userName)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.user.userFirstName)) {
                resultList.push(row);
            }
        }

        return resultList;
    };
    return (
        <Drawer
            sx={{
                ml: open ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 1000 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 1000 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={open}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            <Box sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                    <Grid container justifyContent="space-between" alignItems="center" item xs={12}>
                        <Grid item xs={8}>
                            <Typography variant="h3">Pick Add</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton size="small" onClick={handleDrawerOpen}>
                                <CancelIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'}>
                        <IconRefresh
                            style={{ marginRight: '20px' }}
                            onClick={() => {
                                setSearchText('');
                                getReadyList(true);
                            }}
                        />
                        <OutlinedInput
                            value={searchText}
                            onChange={(e) => setSearchText(e.currentTarget.value)}
                            id="input-search-driver"
                            placeholder="Search Address & Id"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" />
                                </InputAdornment>
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    getReadyList();
                                }
                            }}
                            size="small"
                            sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                        />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table size="small" aria-label="collapsible table">
                        <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }} />
                                <TableCell>ID</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                            {list.map((value, index: number) => (
                                <AddPickUpInfo key={`ready_pick_${value.idx}`} value={value} index={index} addItem={addItem} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Drawer>
    );
};
export default AddPickUpDrawer;

import { RouteRootList } from '../../views/pick/pickListWithDepot';
import { RouteGroupListType } from '../../views/pick/map/route.group.list';

export const groupRoutesByDepot = (list: RouteRootList[]) => {
    const temp = [];
    let group = 1;
    for (const row of list) {
        const addData = { ...row, group };
        if (row.type === 'depot') {
            group++;
        }
        temp.push(addData);
    }
    return temp;
};

export const groupRoutes = (routes: RouteRootList[]): RouteGroupListType[] => {
    const groupedRoutes: RouteGroupListType[] = [];
    if (routes.length === 0) return groupedRoutes;

    let currentGroupNumber = routes[0].group;
    let currentGroupRoutes: RouteRootList[] = [];

    for (const route of routes) {
        if (route.group === currentGroupNumber) {
            currentGroupRoutes.push(route);
        } else {
            groupedRoutes.push({
                group: currentGroupNumber,
                list: currentGroupRoutes
            });
            currentGroupRoutes = [route];
            currentGroupNumber = route.group;
        }
    }

    if (currentGroupRoutes.length > 0) {
        groupedRoutes.push({
            group: currentGroupNumber,
            list: currentGroupRoutes
        });
    }

    return groupedRoutes;
};

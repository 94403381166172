import { Box, Checkbox, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { PickProfileChip } from '../../ui-component/swith/pickTypeCard';
import { PickProfileConst } from '../../utils/const/pick.const';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { locationListType } from '../../types/pick';
import useAxiosServices from '../../hooks/useAxios';

interface Props {
    weekly: string;
    setWeekly: Dispatch<SetStateAction<string>>;
}
export const SuburbFrequencyAddBox = ({ weekly, setWeekly }: Props) => {
    const label = [
        { value: '1', label: 'Weekly' },
        { value: '2', label: 'Bi-weekly(2-4)' },
        { value: '3', label: 'Bi-weekly(1-3-5)' }
    ];
    return (
        <Select
            style={{ background: '#fff' }}
            sx={{
                height: '30px',
                width: '100%',
                '& .MuiInputBase-input': {
                    background: '#fff'
                }
            }}
            labelId="selectsubWeekly"
            id="selectsubWee11klyID"
            value={weekly}
            onChange={(e) => setWeekly(e.target.value)}
        >
            {label.map((value, i) => (
                <MenuItem key={`demp-dfdsfid-${i}`} value={value.value}>
                    <ListItemText primary={value.label} />
                </MenuItem>
            ))}
        </Select>
    );
};

// material-ui
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MessageListType, MessageUserListType, userListProfile } from '../../_mockApis/user-profile/types';

import { useTheme } from '@mui/styles';
import { IconCircleMinus, IconList, IconMinus, IconSend } from '@tabler/icons';
import useAxiosServices from '../../hooks/useAxios';
import UserRows from '../userPage/UserRows';

// types
interface Props {
    open: boolean;
    handleClose: Dispatch<SetStateAction<Boolean>>;
    idx: number;
}

export default function MessageDetail({ open, handleClose, idx }: Props) {
    const theme = useTheme();
    const [userList, setUserList] = useState<MessageUserListType[]>([]);
    const [messageData, setMessageData] = useState<MessageListType | null>(null);

    const { axiosService } = useAxiosServices();
    const getData = async () => {
        const api = await axiosService.post('push/detail', { idx });
        const { status, userList: list, data } = api.data;
        if (status) {
            setUserList([...list]);
            setMessageData({ ...data });
        }
    };
    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open]);
    const mode = 'noList';

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            fullWidth
            maxWidth={'xl'}
            aria-labelledby="item-delete-title"
            aria-describedby="item-delete-description"
        >
            <DialogTitle id="item-delete-title">
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Box
                        sx={{
                            margin: '8px',
                            marginLeft: '10px',
                            width: '100%',
                            display: 'flex',

                            flexDirection: 'column'
                        }}
                    >
                        <Typography variant={'h5'} color={theme.palette.info.main}>
                            Title
                        </Typography>
                        <div>{messageData && messageData.title}</div>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogTitle id="item-delete-title">
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Box
                        sx={{
                            margin: '8px',
                            marginLeft: '10px',
                            width: '100%',
                            display: 'flex',

                            flexDirection: 'column'
                        }}
                    >
                        <Typography variant={'h5'} color={theme.palette.info.main}>
                            Content
                        </Typography>
                        <div>{messageData && <div dangerouslySetInnerHTML={{ __html: messageData.content }} />}</div>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogTitle id="item-delete-title">
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <Box sx={{ margin: '8px', marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant={'h3'} color={theme.palette.info.main} sx={{ marginRight: '3px' }}>
                            {userList.length}
                        </Typography>
                        {'Selected(s) '}
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>#</TableCell>
                                <TableCell>User Type</TableCell>
                                <TableCell>User Profile</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userList &&
                                userList.map((row, index) => (
                                    <UserRows
                                        checkList={[]}
                                        handleCheck={() => null}
                                        row={row.user}
                                        index={index}
                                        mailModeCheck={userList.length === -1}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{ mr: 2 }}>
                <Button onClick={() => handleClose(false)} color="error">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MainCard from '../../ui-component/cards/MainCard';
import useAxios from '../../hooks/useAxios';
import { driverListProfile } from '../../_mockApis/user-profile/types';
import DriverRows from './DriverRows';
import { ChangeEventFunc } from '../../types';
import { useNavigate } from 'react-router-dom';
import DepotRows from './DepotRows';
import UserDownload from '../../components/csv/user.download';
import ManagerRow from './Manager.row';
import useAuth from '../../hooks/useAuth';

const MangerWrapper = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<driverListProfile[]>([]);
    const [wait, setWait] = useState<0 | 1 | 2>(0);
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
        status: number;
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC',
        status: 0
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/users/list/manger', { wait, ...listParam });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    React.useEffect(() => {
        getData();
    }, [listParam, wait]);
    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlerCheck = (current: 0 | 1 | 2) => {
        if (current === wait) {
            setWait(0);
        } else {
            setWait(current);
        }
    };

    const { user } = useAuth();
    const admin = user?.admin ?? 0;
    const adminRole = user?.adminRole ?? 0;

    if (admin !== 1) {
        return <div />;
    }
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Managers List</Typography>
                    </Grid>
                    {admin === 1 && (
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button onClick={() => navigate('/user/managerAdd')} size="large" color="primary">
                                        Manager Create
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            }
            content={false}
        >
            <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Profile</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Hide Permission</TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{data && data.map((row, index) => <ManagerRow row={row} index={index} />)}</TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                        </Grid>
                        <Grid item>
                            <Button size="large" sx={{ color: theme.palette.grey[900] }} color="secondary" onClick={handleClick}>
                                10 Rows
                            </Button>
                            <UserDownload type={3} skip={listParam.skip} take={listParam.take} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </MainCard>
    );
};

export default MangerWrapper;

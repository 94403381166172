import React, { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/system';
import {
    Button,
    Grid,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { sub } from 'date-fns';
import { pickListTypes } from '../../types/pick';
import useAxios from '../../hooks/useAxios';
import { gridSpacing } from '../../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { CharityListTypes } from '../../types/charity';
import { useNavigate } from 'react-router-dom';

const getDropWrapper = (isDraggingOver: boolean, theme: Theme) => ({
    background: isDraggingOver ? theme.palette.secondary.light + 50 : 'transparent'
});

const CharityList = () => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const navigate = useNavigate();
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
        option: number;
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC',
        option: 1
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/charity/list', listParam);
        console.log(response);
        setList(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    useEffect(() => {
        getData();
    }, [listParam]);

    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };
    const [list, setList] = useState<CharityListTypes[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <TableContainer>
                <Table size="small" aria-label="collapsible table">
                    <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Fund</TableCell>
                            <TableCell>UserFund</TableCell>
                            <TableCell>Reach rate</TableCell>
                            <TableCell>More</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                        {}
                        {list.map((value, index: number) => (
                            <TableRow hover>
                                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>{value.idx}</TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography align="left" variant="subtitle2" noWrap>
                                        {value.title}
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography align="left" variant="subtitle2" noWrap>
                                        {value.fund}
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography align="left" variant="subtitle2" noWrap>
                                        {value.userFund}
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography align="left" variant="subtitle2" noWrap>
                                        {`${Number((value.userFund / value.fund) * 100)}%`}
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Button onClick={() => navigate(`/charity/update/${value.idx}`)}>Edit</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={12} sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            sx={{ color: theme.palette.grey[900] }}
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                        >
                            10 Rows
                        </Button>
                        <Menu
                            id="menu-user-list"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={handleClose}> 10+</MenuItem>
                            <MenuItem onClick={handleClose}> 20+</MenuItem>
                            <MenuItem onClick={handleClose}> 30+</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default CharityList;

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MainCard from '../../ui-component/cards/MainCard';
import UserDriverCard from '../../ui-component/cards/UserDriverCard';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import PickList from './PickList';
import { Map } from '@mui/icons-material';
import MapBox from './map/MapBox';
import { pickListTypes } from '../../types/pick';
import { DepotListProfileType, driverListProfile } from '../../_mockApis/user-profile/types';
import useAxiosServices from '../../hooks/useAxios';
import { ToastContainer, toast } from 'react-toastify';
import { allBagCalc, allBagCalcEa, bagTypeRending, numberWithCommas, ToastSetting, totalTimeAllCalc } from '../../utils/common';
import { useNavigate, useParams } from 'react-router-dom';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import SettingsIcon from '@mui/icons-material/Settings';
import { useDispatch } from 'react-redux';
import { LOADING_END, LOADING_START } from '../../store/actions';
import PickListWithDepot, { reorder, RouteRootList } from './pickListWithDepot';
import OptiMapBox from './map/opti.mab.box';
import StickyBox from 'react-sticky-box';
import RouteGroupList, { RouteGroupListType } from './map/route.group.list';
import RouteDownload from '../../components/csv/route.download';
import { groupRoutes, groupRoutesByDepot } from '../../utils/format/pick.group';

export interface PolyLineDataType {
    group: number;
    polyline: string;
    totalTime: any;
}

export const RouteLineColorPalette = [
    '#008080',
    '#0000FF',
    '#FF00FF',
    '#008000',
    '#808000',
    '#00FFFF',
    '#E13102',
    '#FF994E',
    '#43F7C0',
    '#6DB6FF',
    '#A43b76',
    '#FFD86C',
    '#8E1F20',
    '#326633'
];

const RouteOptimasation = () => {
    const { routeId } = useParams();
    const { axiosService } = useAxiosServices();
    const [depotList, setDepotList] = useState<DepotListProfileType[]>([]);
    const [startDepotList, setStartDepotList] = useState<DepotListProfileType[]>([]);
    const [zoomList, setZoomList] = useState<RouteRootList[]>([]);
    const theme = useTheme();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [modifyState, setModifyState] = useState(false);
    const [driverProfile, setDriverProfile] = useState<driverListProfile | null>(null);
    const [pickList, setPickList] = useState<pickListTypes[]>([]);
    const [routeList, setRouteList] = useState<RouteRootList[]>([]);
    const [polylineData, setPolineData] = useState<PolyLineDataType[]>([]);
    const [viewPoly, setViewPoly] = useState<number[]>([]);

    const [polyline, setPolyline] = useState<string | null>(null);
    const driverHandler = (profile: driverListProfile) => {
        setDriverProfile({ ...profile });
        const location = new google.maps.LatLng({ lat: profile.driver.startLat, lng: profile.driver.startLot });
        setCenterList([...[location]]);
    };

    const pickHandler = (change: pickListTypes[]) => {
        setPickList([...change]);
    };

    const [insertOpen, setInsertOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [optiOpen, setOptiOpen] = useState(false);
    const [checkOptimise, setCheckOptimise] = useState(true);

    const handleOptimise = () => {
        setCheckOptimise((p) => !p);
    };
    const alertHanler = (type: 'i' | 'u' | 'd' | 'o') => {
        if (type === 'i') {
            setInsertOpen(true);
        }
        if (type === 'u') {
            setUpdateOpen(true);
        }
        if (type === 'd') {
            setDeleteOpen(true);
        }
        if (type === 'o') {
            setOptiOpen(true);
        }
    };

    const saveRoutePick = async () => {
        dispatch({ type: LOADING_START });
        try {
            setInsertOpen(false);
            if (routeList.length === 0) {
                toast.error('please pick list', { ...ToastSetting.default });
                return;
            }
            if (!driverProfile) {
                toast.error('please pick driver', { ...ToastSetting.default });
                return;
            }
            if (startDepotList.length === 0) {
                toast.error('please add Departure location', { ...ToastSetting.default });
                return;
            }
            const check = checkEndDepot(routeList, null);
            if (!check.result) {
                toast.error(`${check.group} group need ${check.type} location, please add depot`, { ...ToastSetting.default });
                return;
            }
            const formList = groupRoutesByDepot(routeList);
            const paramData = {
                startDepot: startDepotList[0].idx,
                routeList: formList,
                polyLineData: polylineData,
                driverIdx: driverProfile.idx
            };
            const api = await axiosService.post('/optimise/save', paramData);
            const { result } = api.data;
            if (result) {
                toast.info('Save Route', { ...ToastSetting.default });
                navigation('/routing/list');
                return null;
            }
            toast.info('Error ', { ...ToastSetting.default });
        } catch (e) {
            toast.info('Error ', { ...ToastSetting.default });
        } finally {
            dispatch({ type: LOADING_END });
        }

        return null;
    };

    useEffect(() => {
        setViewPoly(routeList.map((v) => 1));
    }, [routeList]);

    const updateRoutePick = async () => {
        dispatch({ type: LOADING_START });
        try {
            setUpdateOpen(false);
            if (routeList.length === 0) {
                toast.error('please pick list', { ...ToastSetting.default });
                return;
            }
            if (!driverProfile) {
                toast.error('please pick driver', { ...ToastSetting.default });
                return;
            }
            if (startDepotList.length === 0) {
                toast.error('please add Departure location', { ...ToastSetting.default });
                return;
            }
            const check = checkEndDepot(routeList, null);
            if (!check.result) {
                toast.error(`${check.group} group need ${check.type} location, please add depot`, { ...ToastSetting.default });
                return;
            }
            const formList = groupRoutesByDepot(routeList);
            const paramData = {
                startDepot: startDepotList[0].idx,
                idx: routeId,
                routeList: formList,
                polyLineData: polylineData,
                driverIdx: driverProfile.idx
            };
            const api = await axiosService.post('/optimise/update', paramData);
            const { result } = api.data;
            if (result) {
                toast.info('Update Route', { ...ToastSetting.default });
                navigation('/routing/list');
                return null;
            }
            toast.info('Error ', { ...ToastSetting.default });
        } catch (e) {
            toast.info('Error ', { ...ToastSetting.default });
        } finally {
            dispatch({ type: LOADING_END });
        }

        return null;
    };

    const deleteRoute = async () => {
        setDeleteOpen(false);

        const paramData = {
            idx: routeId
        };
        const api = await axiosService.post('/optimise/delete', paramData);
        if (api.data) {
            toast.info('Delete Route', { ...ToastSetting.default });
            navigation('/routing/list');
            return null;
        }
        toast.info('Error ', { ...ToastSetting.default });

        return null;
    };

    const getRouteDetail = async (idx) => {
        dispatch({ type: LOADING_START });
        try {
            const api = await axiosService.post('/optimise/get', { idx });
            const { result, driver, rootData, depotList: dList, pick, polyLineData, startDepot } = api.data;
            if (result) {
                const changeRouteList = handleChangeApiData(rootData);
                console.log('changeRouteList', changeRouteList);
                setDriverProfile({ ...driver });
                setRouteList([...changeRouteList]);
                setPolineData([...polyLineData]);
                setPickList([...pick]);
                setDepotList([...dList]);
                setStartDepotList([...startDepot]);
                if (changeRouteList.length > 0) {
                    setZoomList([...changeRouteList]);
                }
            }
            setModifyState(true);
        } catch (e) {
        } finally {
            dispatch({ type: LOADING_END });
        }
    };

    const handleChangeApiData = (list: any[]) => {
        const temp = [];
        for (const row of list) {
            console.log('ROW', row);
            if (typeof row.location.lat === 'number' && (typeof row.location.lot === 'number' || typeof row.location.lng === 'number')) {
                const location = new google.maps.LatLng({ lat: row.location.lat, lng: row.location.lot || row.location.lng });
                temp.push({ ...row, location });
            } else {
                temp.push({ ...row });
            }
        }
        return temp;
    };

    const [totalTime, setTotalTime] = useState(0);
    const optimazationRouteNotSave = async () => {
        try {
            dispatch({ type: LOADING_START });
            const pickIdx = pickList.map((v) => v.idx);
            if (pickIdx.length === 0) {
                alert('please pick list');
                return;
            }
            if (!driverProfile) {
                alert('please pick driver');
                return;
            }
            const api = await axiosService.post('/pick/route/optimization/save', { driverIdx: driverProfile.idx, pickIdx });
            const { status, data } = api.data;
            if (status) {
                setPickList([...data]);
                setPolyline(api.data.polyline);
                if (api.data.totalTime && api.data.totalTime > 0) {
                    setTotalTime(api.data.totalTime);
                }
            }

            toast.info('Success ', { ...ToastSetting.default });
        } catch (e) {
            console.log(e);
            toast.info('Error?', { ...ToastSetting.default });
        } finally {
            dispatch({ type: LOADING_END });
            setOptiOpen(false);
        }
    };

    useEffect(() => {
        if (routeId !== 'new') {
            getRouteDetail(routeId);
        }
    }, [routeId]);
    const [time, setTime] = useState<Date | null>(new Date());

    const getPolyLineData = async (groupIdx = null) => {
        dispatch({ type: LOADING_START });
        try {
            if (startDepotList.length === 0) {
                toast.error('please add Departure location', { ...ToastSetting.default });
                return;
            }
            const formList = groupRoutesByDepot(routeList);
            const formData = {
                routeList: formList,
                groupIdx,
                startDepot: startDepotList[0].idx,
                optimise: checkOptimise
            };
            const check = checkEndDepot(routeList, groupIdx);
            if (!check.result) {
                toast.error(`${check.group} group need ${check.type} location, please add depot`, { ...ToastSetting.default });
                return;
            }
            const api = await axiosService.post('optimise/getPolyLine', formData);
            const { data } = api;

            const updateRouteList = [];
            const updatePolyLine = [];
            const indicesToRemove = [];
            for (const row of data) {
                const polylineDataTemp = row.polyline;
                const groupList = row.list;
                const tempData: PolyLineDataType = {
                    group: row.group,
                    polyline: polylineDataTemp,
                    totalTime: row.totalTime
                };
                for (const pickData of groupList) {
                    updateRouteList.push(pickData);
                }
                updatePolyLine.push(tempData);
            }
            console.log('updateRouteList', updateRouteList);
            if (groupIdx) {
                const existingZoomList = zoomList.filter((v) => v.group !== groupIdx);
                const existingPolineData = polylineData.filter((v) => v.group !== groupIdx);
                updateRouteList.forEach((newData) => {
                    const idx = routeList.findIndex((r) => r.optiIdx === newData.optiIdx);
                    if (idx !== -1) {
                        indicesToRemove.push(idx);
                    }
                });
                console.log('indicesToRemove', indicesToRemove);
                const updateGroupList = [...routeList];
                indicesToRemove
                    .sort((a, b) => b - a)
                    .forEach((index) => {
                        updateGroupList.splice(index, 1);
                    });

                // 새로운 데이터를 기록된 인덱스에 삽입
                indicesToRemove
                    .sort((a, b) => a - b)
                    .forEach((index, i) => {
                        updateGroupList.splice(index, 0, updateRouteList[i]);
                    });
                console.log('updateGroupList', updateGroupList);
                setZoomList([...existingZoomList, ...updateRouteList]);
                setRouteList([...handleChangeApiData(updateGroupList)]);
                setPolineData([...existingPolineData, ...updatePolyLine]);
            } else {
                const addLocationList = handleChangeApiData(updateRouteList);
                setZoomList([...updateRouteList]);
                setRouteList([...addLocationList]);
                setPolineData([...updatePolyLine]);
            }

            return;
        } catch (e) {
        } finally {
            dispatch({ type: LOADING_END });
        }
    };

    const checkEndDepot = (list: RouteRootList[], group) => {
        const one = groupRoutesByDepot(list);
        const two: RouteGroupListType[] = groupRoutes(one);

        if (group) {
            const groupOne = two.find((v) => v.group === group);
            const check = groupOne.list.find((v) => v.type === 'depot');
            if (!check) {
                return {
                    result: false,
                    type: 'depot',
                    group: groupOne.group
                };
            }
            const check2 = groupOne.list.find((v) => v.type === 'pick');
            if (!check2) {
                return {
                    result: false,
                    type: 'pick',
                    group: groupOne.group
                };
            }
        } else {
            for (const row of two) {
                const check = row.list.find((v) => v.type === 'depot');
                if (!check) {
                    return {
                        result: false,
                        type: 'depot',
                        group: row.group
                    };
                }
                const check2 = row.list.find((v) => v.type === 'pick');
                if (!check2) {
                    return {
                        result: false,
                        type: 'pick',
                        group: row.group
                    };
                }
            }
        }
        return {
            result: true,
            group: 0
        };
    };

    const [centerList, setCenterList] = useState<google.maps.LatLng[]>([]);

    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item container justifyContent="space-between" alignContent="center">
                            <Grid item xs={6}>
                                <Typography variant="h3">Route Detail</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {modifyState && (
                                        <Tooltip title="Import Xlsx">
                                            <RouteDownload
                                                routeList={routeList}
                                                date={time}
                                                driverProfile={driverProfile}
                                                polylineData={polylineData}
                                            />
                                        </Tooltip>
                                    )}

                                    <Tooltip title="save route">
                                        <Button
                                            sx={{ marginRight: '10px' }}
                                            size="small"
                                            startIcon={<SaveIcon />}
                                            onClick={() => (modifyState ? alertHanler('u') : alertHanler('i'))}
                                        >
                                            {modifyState ? (
                                                <Typography variant="h4">Update</Typography>
                                            ) : (
                                                <Typography variant="h4">SAVE</Typography>
                                            )}
                                        </Button>
                                    </Tooltip>
                                    {modifyState && (
                                        <Grid item xs={2} flexDirection={'row'}>
                                            <Tooltip title="save route">
                                                <Button size="small" startIcon={<DeleteIcon />} onClick={() => alertHanler('d')}>
                                                    <Typography variant="h4">Delete</Typography>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            >
                <UserDriverCard initProfile={driverProfile} driverHandler={driverHandler} />
                <Grid marginY={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                            label="Date"
                            value={time}
                            inputFormat={'yyyy/MM/dd'}
                            onChange={(newValue: Date | null) => {
                                setTime(newValue);
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <OptiMapBox
                            zoomList={zoomList}
                            centerList={centerList}
                            viewPoly={viewPoly}
                            routeList={routeList}
                            startDepotList={startDepotList}
                            driverProfile={driverProfile}
                            polylineData={polylineData}
                        />

                        <Box sx={{ display: 'flex', width: 400, paddingY: '20px', paddingX: '5px' }}>
                            <Typography variant={'h4'} sx={{ marginRight: '10px' }}>{`Total time : ${totalTimeAllCalc(
                                polylineData
                            )}`}</Typography>
                            <Typography variant={'h4'}>{`Total litre : ${numberWithCommas(allBagCalc(routeList))} L`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: 400, paddingY: '10px', paddingX: '5px' }}>
                            <Typography variant={'h4'}>{`Total Bag : ${numberWithCommas(allBagCalcEa(routeList))} EA`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: 400, paddingY: '10px', paddingX: '15px' }}>
                            <Typography variant={'h4'}>{`Recan Bag : ${numberWithCommas(bagTypeRending(routeList).one)} EA`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: 400, paddingY: '10px', paddingX: '15px' }}>
                            <Typography variant={'h4'}>{`Non-RECAN Bag : ${numberWithCommas(
                                bagTypeRending(routeList).two
                            )} EA`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: 400, paddingY: '10px', paddingX: '15px' }}>
                            <Typography variant={'h4'}>{`Wheelie Bin : ${numberWithCommas(
                                bagTypeRending(routeList).three
                            )} EA`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: 400, paddingY: '10px', paddingX: '15px' }}>
                            <Typography variant={'h4'}>{`Bulk Bag : ${numberWithCommas(bagTypeRending(routeList).four)} EA`}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: 400, paddingY: '10px', paddingX: '15px' }}>
                            <Typography variant={'h4'}>{`Coex Bag : ${numberWithCommas(bagTypeRending(routeList).five)} EA`}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        {totalTime > 0 && (
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="h5" color={'blue'}>
                                        Total Time : {totalTime} Min
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        <PickListWithDepot
                            optimise={checkOptimise}
                            handleOptimise={handleOptimise}
                            centerList={centerList}
                            setCenterList={setCenterList}
                            startDepotList={startDepotList}
                            setStartDepotList={setStartDepotList}
                            polylineData={polylineData}
                            viewPoly={viewPoly}
                            setViewPoly={setViewPoly}
                            depotList={depotList}
                            setDepotList={setDepotList}
                            initList={pickList}
                            listHandler={pickHandler}
                            routeList={routeList}
                            setRouteList={setRouteList}
                            getPolyLineData={getPolyLineData}
                        />
                    </Grid>
                </Grid>
            </MainCard>
            <AlertDialog
                title={'Insert'}
                content={'Do you want to add a route?'}
                open={insertOpen}
                handleClose={setInsertOpen}
                handleSuccess={() => saveRoutePick()}
            />
            <AlertDialog
                title={'Update'}
                content={'Do you want to change the route?'}
                open={updateOpen}
                handleClose={setUpdateOpen}
                handleSuccess={() => updateRoutePick()}
            />
            <AlertDialog
                title={'Delete'}
                content={'Do you want to delete a route?'}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteRoute()}
            />
            <AlertDialog
                title={'Optimization'}
                content={'Do you want to optimize the route?'}
                open={optiOpen}
                handleClose={setOptiOpen}
                handleSuccess={() => optimazationRouteNotSave()}
            />
        </>
    );
};

export default RouteOptimasation;

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, AlertTitle, Button, Grid, IconButton, TextField } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// ==============================|| PROFILE 1 - CHANGE PASSWORD ||============================== //

interface Props {
    id: any;
}

const validationSchema = yup.object({
    new: yup.string().min(7, 'Please enter at least 8 characters').required('new password is required'),
    check: yup
        .string()
        .oneOf([yup.ref('new'), null], 'password must match')
        .required('new password is required')
});

const NewPassword = ({ id }: Props) => {
    const theme = useTheme();

    const formik = useFormik({
        initialValues: {
            new: '',
            check: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            await submit(values);
        }
    });
    const { axiosService } = useAxiosServices();
    const submit = async (value) => {
        const { check } = value;
        const api = await axiosService.post('users/app/reset/password/admin', { idx: id, password: value.new });
        const { status, data } = api.data;
        if (status) {
            toast.info('Change password', { ...ToastSetting.default });
        } else {
            alert(data);
        }
    };
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const halderShow = (type) => {
        if (type === 1) {
            setShow((p) => !p);
        } else if (type == 2) {
            setShow1((p) => !p);
        } else {
            setShow2((p) => !p);
        }
    };
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Alert severity="warning" variant="outlined" sx={{ borderColor: 'warning.dark' }}>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <strong> The password set by the user's email will be sent</strong>
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <SubCard title="Change Password">
                    <form noValidate autoComplete="off">
                        <Grid container spacing={gridSpacing} sx={{ mb: 1.75 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    type={show ? 'text' : 'password'}
                                    id="new"
                                    fullWidth
                                    label="New Password"
                                    value={formik.values.new}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.new && Boolean(formik.errors.new)}
                                    helperText={formik.touched.new && formik.errors.new}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton aria-label="toggle password visibility" onClick={() => halderShow(1)} edge="end">
                                                {show ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    type={show1 ? 'text' : 'password'}
                                    id="check"
                                    fullWidth
                                    label="Confirm Password"
                                    value={formik.values.check}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.check && Boolean(formik.errors.check)}
                                    helperText={formik.touched.check && formik.errors.check}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton aria-label="toggle password visibility" onClick={() => halderShow(2)} edge="end">
                                                {show1 ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Grid spacing={2} container justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Grid item>
                            <AnimateButton>
                                <Button variant="contained" onClick={() => formik.handleSubmit()}>
                                    Change Password
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default NewPassword;

import React, { useState, CSSProperties, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonBase,
    Chip,
    Collapse,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party
import { format } from 'date-fns';
import { Droppable, Draggable } from 'react-beautiful-dnd';

// project imports
import AddDriverDrawer from './drawer/AddDriverDrawer';
import AlertRouteDelete from './AlertRouteDelete';
import PickItems from './PickItems';
import { SNACKBAR_OPEN, DELETE_STORY } from 'store/actions';

// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

// types
import { DefaultRootStateProps } from 'types';
import { KanbanUserStory } from 'types/kanban';
import AddIcon from '@mui/icons-material/Add';
import { pickListTypes } from '../../types/pick';
import PickListMore from '../pickPage/pickListMore';
import PickTypeCard from '../../ui-component/swith/pickTypeCard';
import { addressFormat, addressNullCheck } from '../../utils/common';
import dayFormat from '../../utils/times/timeFormats';
import { NameFormat } from '../../utils/format/name.format';
import { pickUpUserName } from '../../utils/users.helper';
import { UserTypeHelper } from '../../utils/user.type.helper';

interface Props {
    value: pickListTypes;
    index: number;
    addItem: (item: pickListTypes) => void;
}

const AddPickUpInfo = ({ value, index, addItem }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const kanban = useSelector((state: DefaultRootStateProps) => state.kanban);
    const [open, setOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openModal, setOpenModal] = useState(false);
    const [hover, setHover] = useState(false);
    const [overflowActive, setOverflowActive] = useState(false);

    const isOverflowActive = (event) => event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth;
    const textRef = useRef();
    useEffect(() => {
        if (isOverflowActive(textRef.current)) {
            setOverflowActive(true);
            return;
        }

        setOverflowActive(false);
    }, [isOverflowActive]);
    const handleAddPick = (event, v: pickListTypes) => {
        event.stopPropagation();
        addItem(v);
    };
    return (
        <>
            <TableRow hover onClick={() => setOpen(!open)} sx={{ cursor: 'pointer' }}>
                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>
                    <Tooltip title="Add Pick">
                        <Button
                            onClick={(e) => handleAddPick(e, value)}
                            variant="contained"
                            size="small"
                            color="secondary"
                            endIcon={<AddIcon />}
                        >
                            ADD
                        </Button>
                    </Tooltip>
                </TableCell>
                <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>
                    <Stack direction="row" spacing={0.25} alignItems="center">
                        <Typography variant="body2"> {`R-${value.idx}`}</Typography>
                    </Stack>
                </TableCell>
                <TableCell sx={{ minWidth: 40, width: 40, height: 46 }}>
                    <Stack direction="row" spacing={0.25} alignItems="center">
                        <Typography variant="body2"> {dayFormat(value.pickDate)}</Typography>
                    </Stack>
                </TableCell>
                <TableCell
                    ref={textRef}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    sx={{
                        maxWidth: 'calc(100vw - 1000px)',
                        minWidth: 140,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}
                    component="th"
                    scope="row"
                >
                    {value.address}
                    {addressFormat(value.addressZip, value.addressMore)}
                    {overflowActive && hover && (
                        <Box
                            sx={{
                                position: 'absolute',
                                boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                                padding: '10px',
                                backgroundColor: '#fff'
                            }}
                        >
                            {value.address}
                            {addressFormat(value.addressZip, value.addressMore)}
                        </Box>
                    )}
                </TableCell>
                <TableCell sx={{ width: 40, minWidth: 40 }}>
                    <UserTypeHelper type={value?.userType} />
                </TableCell>
                <TableCell sx={{ width: 40, minWidth: 40 }}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body2">{pickUpUserName(value)}</Typography>
                    </Stack>
                </TableCell>
                <TableCell sx={{ width: 40, minWidth: 40 }}>
                    <PickTypeCard type={value.status} />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                margin: 0
                            }}
                        >
                            <TableContainer>
                                <Table size="small" aria-label="purchases">
                                    <TableBody>
                                        <Box margin="20px">
                                            <PickListMore row={value} profileView />
                                        </Box>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AddPickUpInfo;

import React, { useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MainCard from '../../ui-component/cards/MainCard';
import useAxios from '../../hooks/useAxios';
import { driverListProfile } from '../../_mockApis/user-profile/types';
import DriverRows from './DriverRows';
import { ChangeEventFunc } from '../../types';
import UserDownload from '../../components/csv/user.download';

interface Props {
    wait: number;
}

const DriverListWrapper = ({ wait }: Props) => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [data, setData] = React.useState<driverListProfile[]>([]);

    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
        status: number;
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC',
        status: 0
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/users/list/driver', { wait, ...listParam });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    React.useEffect(() => {
        getData();
    }, [listParam, wait]);
    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };

    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handlerCheck = (current: 0 | 1 | 2) => {
    //     if (current === wait) {
    //         setWait(0);
    //     } else {
    //         setWait(current);
    //     }
    // };

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Driver List</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            {/* <Grid item> */}
                            {/*    <FormControlLabel */}
                            {/*        control={<Checkbox name={'ActiveChecked'} checked={wait === 1} onChange={() => handlerCheck(1)} />} */}
                            {/*        label="Active" */}
                            {/*    /> */}
                            {/* </Grid> */}
                            {/* <Grid item> */}
                            {/*    <FormControlLabel */}
                            {/*        control={<Checkbox name={'waitChecked'} checked={wait === 2} onChange={() => handlerCheck(2)} />} */}
                            {/*        label="Wait" */}
                            {/*    /> */}
                            {/* </Grid> */}

                            <OutlinedInput
                                id="input-search"
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={0.5} size="0.5rem" />
                                    </InputAdornment>
                                }
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }}>Id</TableCell>
                                <TableCell>Driver Profile</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Done</TableCell>
                                <TableCell>Wait</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{data && data.map((row, index) => <DriverRows row={row} index={index} />)}</TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                        </Grid>
                        <Grid item>
                            <Button size="large" sx={{ color: theme.palette.grey[900] }} color="secondary" onClick={handleClick}>
                                10 Rows
                            </Button>
                            <UserDownload type={2} skip={listParam.skip} take={listParam.take} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        </MainCard>
    );
};

export default DriverListWrapper;

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/system';
import MainCard from '../../ui-component/cards/MainCard';
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { gridSpacing } from '../../store/constant';
import { IconSearch } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import OrderRow from './order.row';
import { orderList } from '../../types/order.type';
import MultipleSelect from '../../ui-component/select/multiple.select';
import ProfileMultipleSelect from '../../ui-component/select/profile.select';
import OrderMultipleSelect from '../../ui-component/select/order.multiple.select';

export const OrderStatusText = (type) => {
    switch (type) {
        case 1:
            return 'Processing';
        case 2:
            return 'Delivered';
        case 3:
            return 'Completed';
        case 4:
            return 'Refund';
        case 5:
            return 'Cancellation';
        case 6:
            return 'Refund request';
        default:
            return 'Processing';
    }
};

const OrderList = () => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [selectTypes, setSelectTypes] = useState<number[]>([]);
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/order/list', {
            ...listParam,
            status: selectTypes
        });
        setList(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    useEffect(() => {
        getData();
    }, [listParam, selectTypes]);

    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };
    const [list, setList] = useState<orderList[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigation = useNavigate();
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Order List</Typography>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginX: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <OrderMultipleSelect selectMenus={selectTypes} setSelectTypes={setSelectTypes} />
                </Box>
            </Box>
            <TableContainer>
                <Table size="small" aria-label="collapsible table">
                    <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Order</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>PayType</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>PaymentAt</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                        {list.map((value, index: number) => (
                            <OrderRow key={value.idx} value={value} index={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item xs={12} sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                    </Grid>
                    <Grid item>
                        <Button
                            size="large"
                            sx={{ color: theme.palette.grey[900] }}
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                        >
                            10 Rows
                        </Button>
                        <Menu
                            id="menu-user-list"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={handleClose}> 10+</MenuItem>
                            <MenuItem onClick={handleClose}> 20+</MenuItem>
                            <MenuItem onClick={handleClose}> 30+</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default OrderList;

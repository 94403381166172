import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { DepotListProfileType, driverListProfile } from '../../../_mockApis/user-profile/types';
import polyline from '@mapbox/polyline';
import { RouteRootList, RouteRootWithPolyList } from '../pickListWithDepot';
import { PolyLineDataType, RouteLineColorPalette } from '../route.optimasation';
import useInterval from '../../../hooks/useInterval';

const AllShapesPolygonCoords = [
    [
        [-0.13235092163085938, 51.518250335096376],
        [-0.1174163818359375, 51.52433860667918],
        [-0.10591506958007812, 51.51974577545329],
        [-0.10831832885742188, 51.51429786349477],
        [-0.12531280517578122, 51.51429786349477],
        [-0.13200759887695312, 51.517823057404094]
    ]
];
const mapLib: any = ['places'];

const mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    maxZoom: 15,
    minZoom: 5
};

interface Props {
    zoomList: RouteRootList[];
    centerList: google.maps.LatLng[];
    routeList: RouteRootList[];
    driverProfile?: driverListProfile;
    polylineData: PolyLineDataType[];
    viewPoly: number[];
    startDepotList: DepotListProfileType[];
}
const OptiMapBox = ({ zoomList, routeList, driverProfile, polylineData, viewPoly, startDepotList, centerList }: Props) => {
    const [list, setList] = useState<RouteRootList[]>([]);
    const [map, setMap] = React.useState(null);
    const [center, setCenter] = useState({
        lat: 51.512501,
        lng: -0.088137
    });
    const [markerList, setMarkerList] = useState<google.maps.LatLng[]>([]);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAD_g8VuFlAZkj7aBSPBRicjeT3AQtdPQI',
        libraries: mapLib
    });
    useEffect(() => {
        if (isLoaded) {
            pickHandler();
        }
    }, [routeList, isLoaded]);
    useEffect(() => {
        driverHandler();
    }, [driverProfile]);

    const driverHandler = useCallback(() => {
        if (map && driverProfile) {
            const { startLat, startLot } = driverProfile.driver;
            const location = new google.maps.LatLng({ lat: driverProfile.driver.startLat, lng: driverProfile.driver.startLot });
            if (startLat && startLot) {
                // makeBounds(map, location);
            }
        }
    }, [driverProfile]);

    const pickHandler = useCallback(() => {
        handleConvert(routeList);
        if (map) {
            // changeMarkerHandler(map, routeList);
        }
    }, [routeList]);

    useEffect(() => {
        changeStartDepotMarkerHandler(map, startDepotList);
    }, [startDepotList]);

    const handleConvert = (l: RouteRootList[]) => {
        const temp = [];
        let group = 1;
        for (const row of l) {
            const addData = { ...row, group };
            if (row.type === 'depot') {
                group++;
            }
            temp.push(addData);
        }
        setList([...temp]);
    };
    const onLoad = (m) => {
        init(m);
    };
    const init = (m: google.maps.Map) => {
        setMap(m);
        // changeMarkerHandler(m, routeList);
    };

    useEffect(() => {
        handleCenterChange();
    }, [centerList]);

    useEffect(() => {
        handleZoomChange();
    }, [zoomList]);

    const handleCenterChange = () => {
        if (!map) {
            return;
        }
        if (centerList.length === 0) {
            return;
        }
        const [here] = centerList;
        makeBounds(map, here);
    };

    const handleZoomChange = () => {
        if (!map) {
            return;
        }
        if (zoomList.length === 0) {
            return;
        }
        const bounds = new google.maps.LatLngBounds();
        zoomList.forEach((marker) => {
            bounds.extend(marker.location);
        });
        map.fitBounds(bounds);
    };

    const changeMarkerHandler = (mapObj, picks: RouteRootList[]) => {
        if (picks.length > 0) {
            const lastValue = checkingListValue(picks);
            makeBounds(mapObj, lastValue.location);
        } else {
            setMarkerList([...[]]);
        }
    };

    const changeStartDepotMarkerHandler = (mapObj, picks: DepotListProfileType[]) => {
        if (picks.length > 0) {
            const lastValue = picks[0];
            if (lastValue) {
                // makeBounds(mapObj, addLocationGoogle(lastValue.depot.lat, lastValue.depot.lot));
            }
        }
    };

    const checkingListValue = (p: RouteRootList[]) => {
        let index = 0;
        const changeList = [...list];
        for (const row of p) {
            if (p[index] !== changeList[index]) {
                return row;
            }
            index += 1;
        }
        return p[p.length - 1];
    };

    const makeBounds = (m: google.maps.Map, location: google.maps.LatLng): void => {
        if (!m) {
            return;
        }
        const make = new google.maps.LatLngBounds(
            { lat: location.lat(), lng: location.lng() },
            { lat: location.lat(), lng: location.lng() }
        );
        m.fitBounds(make);
        m.setCenter({ lat: location.lat(), lng: location.lng() });
        setCenter({ lat: location.lat(), lng: location.lng() });
        setMap(m);
    };

    const onUnmount = React.useCallback(() => {
        setMap(null);
    }, []);
    const containerStyle = {
        width: '500px',
        height: '500px'
    };
    const [decode, setDecode] = useState<{ group: number; color: string; polyline: { lat: number; lng: number }[] }[]>([]);
    const handlePolylineDecode = () => {
        const temp = [];
        for (const row of polylineData) {
            const addData = {
                group: row.group,
                color: RouteLineColorPalette[row.group],
                polyline: polyline.decode(row.polyline).map(([lat, lng], i) => ({ lat, lng }))
            };
            temp.push(addData);
        }
        const sortTemp = temp.sort((a, b) => {
            if (a.group > b.group) {
                return 1;
            }
            if (a.group < b.group) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
        setDecode([...sortTemp]);
    };
    useEffect(() => {
        handlePolylineDecode();
    }, [polylineData]);
    console.log('decode', decode);

    const onLoadPoly = (d) => {
        console.log('polyline: ', d);
    };

    const addLocationGoogle = (lat, lot) => {
        const location = new google.maps.LatLng({ lat, lng: lot });
        return location;
    };
    const polyCommonOptions: google.maps.PolylineOptions = {
        strokeOpacity: 0.8,
        strokeWeight: 10,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: 1
    };
    const [mapOffset, setMapOffset] = useState(0);
    const [lastUpdateTime, lastUpdateTimeSet] = useState(new Date().getTime());
    const easeInOutQuad = (x, t, b, c, d) => {
        if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
        return (-c / 2) * (--t * (t - 2) - 1) + b;
    };
    const [currentZoom, setCurrentZoom] = useState(14);
    const zoomDelay = () => {
        const zoom = 30 - currentZoom;

        return zoom * 10;
    };

    const zoomRepat = () => {
        const zoom = 40 - currentZoom;

        return zoom * 2;
    };
    // useInterval(() => {
    //     setMapOffset((p) => (mapOffset >= 10000 ? 0 : p + 0.1));
    // }, zoomDelay());

    const viewRef = useRef<HTMLDivElement>(null);
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Box ref={viewRef} sx={{ height: '100%', width: '100%' }} maxWidth="100%" maxHeight="100%">
                {!isLoaded ? (
                    <div>loading</div>
                ) : (
                    <GoogleMap
                        onZoomChanged={() =>
                            setCurrentZoom(() => {
                                if (map) {
                                    const a = map.getZoom();
                                    console.log('diff', a);
                                    return a;
                                } else {
                                    return 15;
                                }
                            })
                        }
                        options={mapOptions}
                        mapContainerStyle={{
                            height: `${viewRef.current?.offsetWidth ?? 10}px`,
                            width: `${viewRef.current?.offsetWidth ?? 10}px`
                        }}
                        center={center}
                        zoom={14}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        {driverProfile && (
                            <Marker
                                position={
                                    new google.maps.LatLng({ lat: driverProfile.driver.startLat, lng: driverProfile.driver.startLot })
                                }
                                label={{ color: '#0000FF', fontSize: '12px', fontWeight: '600', text: `Driver` }}
                            />
                        )}
                        {startDepotList.map((v, index) => (
                            <Marker
                                icon={{
                                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                    strokeColor: '#b134db',
                                    scale: 4
                                }}
                                position={addLocationGoogle(v.depot?.lat, v.depot?.lot)}
                                label={{ color: '#b134db', fontSize: '15px', fontWeight: '600', text: 'Departure' }}
                            />
                        ))}
                        {list.map((v, index) => (
                            <Marker
                                icon={{
                                    path:
                                        v.type === 'depot'
                                            ? google.maps.SymbolPath.FORWARD_CLOSED_ARROW
                                            : google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                    strokeColor: RouteLineColorPalette[v.group],
                                    scale: 4
                                }}
                                position={v.location}
                                label={{ color: RouteLineColorPalette[v.group], fontSize: '12px', fontWeight: '600', text: v.type }}
                            />
                        ))}
                        {decode.length > 0 &&
                            decode.map((v, ii) => {
                                if (viewPoly[ii]) {
                                    console.log('v', v);
                                    return (
                                        <Polyline
                                            options={{
                                                ...polyCommonOptions,
                                                strokeColor: v.color,
                                                icons: [
                                                    {
                                                        icon: {
                                                            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                                                            strokeWeight: 2,
                                                            scale: 1.5,
                                                            strokeColor: '#fff'
                                                        },
                                                        offset: `${mapOffset}%`,
                                                        repeat: `${zoomRepat()}px`
                                                    }
                                                ]
                                            }}
                                            onLoad={onLoadPoly}
                                            path={v.polyline}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}

                        {/* Child components, such as markers, info windows, etc. */}
                    </GoogleMap>
                )}
            </Box>
        </Grid>
    );
};

export default OptiMapBox;

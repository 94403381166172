import React from 'react';
import { MessageListType, userListProfile } from '../../_mockApis/user-profile/types';
import { Box, Checkbox, Chip, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { MessageTypeCard } from './message.const.card';
import { dayFormatTime } from '../../utils/times/timeFormats';

interface props {
    handleModalOpen: (idx: number) => void;
    row: MessageListType;
    index: number;
}

const MessageListRow = ({ row, index, handleModalOpen }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };

    const TransId = (dat: MessageListType) => {
        switch (dat.type) {
            case 1:
                return <MessageTypeCard type={dat.type} />;
            case 2:
                return <MessageTypeCard type={dat.type} />;
            case 3:
                return <MessageTypeCard type={dat.type} />;
            default:
                return <MessageTypeCard type={dat.type} />;
        }
    };

    return (
        <TableRow hover key={index} sx={{ padding: 0 }}>
            <TableCell sx={{ padding: '0.5rem', pl: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>{TransId(row)}</div>
            </TableCell>
            <TableCell sx={{ padding: '0.5rem', minWidth: '300px', maxWidth: '300px', overflow: 'hidden' }}>{row.title}</TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>{dayFormatTime(row.createdAt)}</TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>
                {row.push === 1 && (
                    <Chip
                        label="Completed"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                            color: theme.palette.success.dark
                        }}
                    />
                )}
                {row.res === 0 && (
                    <Chip
                        label="Pending"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                            color: theme.palette.orange.dark
                        }}
                    />
                )}
            </TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>{row.res === 0 ? '-' : dayFormatTime(row.resTime)}</TableCell>
            <TableCell align="center" sx={{ padding: 0.5, pr: 3 }}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    {/* <Tooltip placement="top" title="Message"> */}
                    {/*    <IconButton color="primary" aria-label="delete" size="large"> */}
                    {/*        <ChatBubbleTwoToneIcon sx={{ fontSize: '1.1rem' }} /> */}
                    {/*    </IconButton> */}
                    {/* </Tooltip> */}
                    <Tooltip placement="top" title="More">
                        <IconButton
                            onClick={() => handleModalOpen(row.idx)}
                            color="primary"
                            sx={{
                                color: theme.palette.success.dark,
                                borderColor: theme.palette.success.main,
                                '&:hover ': { background: theme.palette.success.light }
                            }}
                            size="large"
                        >
                            <MoreTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default MessageListRow;

import React, { useState, CSSProperties, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonBase,
    Checkbox,
    Chip,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    ListItemText,
    Menu,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party

// project imports
import { SNACKBAR_OPEN, DELETE_STORY } from 'store/actions';

// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import ModeIcon from '@mui/icons-material/Mode';

import { useNavigate } from 'react-router-dom';
import { TransactionListTypes } from '../../types/charity';
import { ToastSetting, TransactionTypeCase } from '../../utils/common';
import { locationListType } from '../../types/pick';
import { format } from 'date-fns';
import { useStyles } from '../../utils/useStyles';
import useAxiosServices from '../../hooks/useAxios';
import { PickProfileChip } from '../../ui-component/swith/pickTypeCard';
import { PickProfileConst } from '../../utils/const/pick.const';
import { SuburbWeeklySelect } from './suburb.select';
import { SuburbWeekList } from './suburb.week.list';
import { IconSearch } from '@tabler/icons';
import TextsmsTwoToneIcon from '@mui/icons-material/TextsmsTwoTone';
import { toast } from 'react-toastify';

interface Props {
    value: locationListType;
    index: number;
    hover?: number;
    rowIndex: number;
    handleSelectedRow: (row: locationListType | null) => void;
}

const SuburbRow = ({ value, index, hover, rowIndex, handleSelectedRow }: Props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { axiosService } = useAxiosServices();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const [active, setActive] = useState(value.active);
    const [dataMemo, setDataMemo] = useState('');
    const handleActive = useCallback(async () => {
        const api = await axiosService.post('/address/active/change', { idx: value.idx });
        const { data } = api.data;
        console.log('api', api.data);
        setActive(data);
        toast.info(`${value.title} Available Updated!`, { ...ToastSetting.default });
    }, [active]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigation = useNavigate();
    const [memo, setMemo] = useState('');
    useEffect(() => {
        setMemo(value.remark);
        setActive(value.active);
        setDataMemo(value.remark);
    }, [value]);
    const handleMemo = useCallback(
        async (v) => {
            const api = await axiosService.post('/address/memo/change', { idx: value.idx, value: v });
            const { data } = api.data;
            handleClose();
            setDataMemo(v);
            console.log('api', api.data);
            toast.info(`${value.title} remark Updated!`, { ...ToastSetting.default });
        },
        [value]
    );
    return (
        <>
            <TableRow hover>
                <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46, paddingTop: '5px', paddingBottom: '5px' }}>
                    {rowIndex + index + 1}
                </TableCell>
                <TableCell scope="row" className={classes.tableCell}>
                    <Typography align="left" variant="subtitle1" component="div">
                        {value.title}{' '}
                    </Typography>
                </TableCell>
                <TableCell scope="row" className={classes.smallCell}>
                    <Typography align="left" variant="subtitle1" component="div">
                        {value.council}{' '}
                    </Typography>
                </TableCell>
                <TableCell scope="row" className={classes.width120}>
                    <Typography align="left" variant="subtitle1" component="div" sx={{ paddingLeft: '20px' }}>
                        {value.postcode}{' '}
                    </Typography>
                </TableCell>
                <TableCell scope="row" className={classes.smallCell}>
                    <Typography align="left" variant="subtitle1" component="div">
                        {value.area}{' '}
                    </Typography>
                </TableCell>
                <TableCell className={classes.smallCell}>
                    <Typography align="left" variant="subtitle2" noWrap>
                        {active === 1 ? (
                            <Chip
                                onClick={() => handleActive()}
                                label="Active"
                                size="small"
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                                    color: theme.palette.success.dark
                                }}
                            />
                        ) : (
                            <Chip
                                onClick={() => handleActive()}
                                label="None"
                                size="small"
                                sx={{
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                                    color: theme.palette.warning.dark
                                }}
                            />
                        )}
                    </Typography>
                </TableCell>
                <TableCell scope="row" className={classes.fixCell} sx={{}}>
                    <SuburbWeeklySelect data={value} />
                </TableCell>
                <SuburbWeekList data={value} hover={hover} />
                <TableCell sx={{ width: '30px', padding: '5px', textAlign: 'center' }}>{value.basic}</TableCell>
                <TableCell sx={{ width: '30px', padding: '5px', textAlign: 'center' }}>{value.business}</TableCell>
                <TableCell sx={{ width: '30px', padding: '5px', textAlign: 'center' }}>{value.charity}</TableCell>
                <TableCell sx={{ width: '30px', padding: '5px', textAlign: 'center' }}>{value.school}</TableCell>
                <TableCell
                    className={classes.fixCell}
                    sx={{
                        width: '10px',
                        padding: '0px'
                    }}
                    align={'center'}
                >
                    <ButtonBase
                        className="more-button"
                        onClick={() => handleSelectedRow(value)}
                        aria-controls="menu-comment"
                        aria-haspopup="true"
                    >
                        <IconButton component="span" size="small" disableRipple>
                            <ModeIcon fontSize="inherit" />
                        </IconButton>
                    </ButtonBase>
                </TableCell>

                <TableCell
                    sx={{
                        width: '40px',
                        maxWidth: '40px',
                        padding: '0px'
                    }}
                >
                    <ButtonBase
                        className="more-button"
                        sx={{ borderRadius: '12px' }}
                        onClick={handleClick}
                        aria-controls="menu-comment"
                        aria-haspopup="true"
                    >
                        <IconButton component="span" size="small" disableRipple>
                            <TextsmsTwoToneIcon fontSize="inherit" color={dataMemo && dataMemo.length > 0 ? 'primary' : 'disabled'} />
                        </IconButton>
                    </ButtonBase>
                    <Menu
                        id="menu-comment"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <Grid display={'flex'} flexDirection={'column'} item style={{ width: '300px', height: 'auto', padding: '5px' }}>
                            <Grid xs={12} p={2}>
                                <Typography variant={'h5'} flexWrap={'wrap'}>
                                    <pre>{value.remark}</pre>
                                </Typography>
                            </Grid>
                            <Button onClick={handleClose} size="small" color="primary">
                                Close
                            </Button>
                        </Grid>
                    </Menu>
                </TableCell>
            </TableRow>
        </>
    );
};

export default SuburbRow;

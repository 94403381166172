import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { bagCalc } from '../../../utils/common';
import { IconCircleMinus, IconMapPin } from '@tabler/icons';
import { RouteRootList } from '../pickListWithDepot';
import AlertDialog from '../../../ui-component/dialog/AlertDialog';
import PickDialog from '../../../ui-component/dialog/pick.dialog';

interface Props {
    item: RouteRootList;
    handleDeleteRouteList: (uuid: string, index: number) => void;
    handleCenterChange: (item: RouteRootList) => void;
}

const RouteGroupRow = ({ item, handleDeleteRouteList, handleCenterChange }: Props) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Box
                sx={{
                    maxWidth: '500px',
                    display: 'flex',
                    flexDirection: 'column',

                    width: '100%',
                    boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
                    '&:hover': {
                        boxShadow: 'rgb(0 0 0 / 16%) 0px 6px 14px'
                    },
                    padding: '10px 20px',
                    margin: '10px 0px',
                    alignItems: 'center',
                    borderRadius: '12px',
                    height: '100%'
                }}
            >
                <Box
                    sx={{
                        marginTop: '10px',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            height: item.type === 'depot' ? '90px' : '50px',
                            width: '5px',
                            backgroundColor: item.type === 'depot' ? '#ff0000' : '#3498db',
                            marginRight: '20px'
                        }}
                    />
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                        {item.type === 'depot' && (
                            <Typography typography="h4" marginBottom={'10px'}>
                                Return
                            </Typography>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ marginBottom: '10px', marginRight: '10px', width: '80%' }}>
                                <Typography typography="h4" marginBottom={'10px'}>
                                    {item.address}
                                </Typography>
                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <Typography typography="h4">{item.name}</Typography>
                                    {item.type === 'pick' && <Typography typography="h4">Bag : {bagCalc(item.info.bags)}</Typography>}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', marginLeft: '30px', flexDirection: 'column', width: '20%' }}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    disabled={item.type === 'depot'}
                                    onClick={() => setOpen(true)}
                                    sx={{
                                        marginBottom: '10px',
                                        marginRight: '10px',

                                        backgroundColor: '#3498db',
                                        '&:disabled': {
                                            backgroundColor: item.type === 'depot' ? '#ff0000' : '#3498db'
                                        }
                                    }}
                                >
                                    <Typography typography="h5" color={'#fff'}>
                                        {item.type === 'depot' ? 'Depot' : 'Detail'}
                                    </Typography>
                                </Button>
                                <Box sx={{ display: 'flex', width: '100%' }}>
                                    <Button
                                        onClick={() => handleDeleteRouteList(item.uuid, item.idx)}
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        sx={{ marginBottom: '10px', minWidth: '10px', padding: '5px', marginRight: '10px', width: '50%' }}
                                    >
                                        <IconCircleMinus />
                                    </Button>
                                    <Button
                                        onClick={() => handleCenterChange(item)}
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        sx={{ marginBottom: '10px', minWidth: '10px', padding: '5px', marginRight: '10px', width: '50%' }}
                                    >
                                        <IconMapPin />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {item.type === 'pick' && (
                <PickDialog
                    row={item.info}
                    title={'ddd'}
                    content={'ddddddddd'}
                    open={open}
                    handleClose={setOpen}
                    handleSuccess={() => setOpen(false)}
                />
            )}
        </>
    );
};

export default RouteGroupRow;

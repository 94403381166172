import React, { useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/system';
import {
    Button,
    Grid,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { sub } from 'date-fns';
import { pickListTypes } from '../../types/pick';
import useAxios from '../../hooks/useAxios';
import { gridSpacing } from '../../store/constant';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { CharityListTypes, taskListType } from '../../types/charity';
import { useNavigate } from 'react-router-dom';
import timeFormats, { dayFormatTime } from '../../utils/times/timeFormats';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { values } from 'lodash';
import MainCard from '../../ui-component/cards/MainCard';

const getDropWrapper = (isDraggingOver: boolean, theme: Theme) => ({
    background: isDraggingOver ? theme.palette.secondary.light + 50 : 'transparent'
});

const TaskList = () => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const navigate = useNavigate();
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
        option: number;
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC',
        option: 1
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/push/list', listParam);
        console.log(response);
        setList(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    useEffect(() => {
        getData();
    }, [listParam]);

    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };
    const [list, setList] = useState<taskListType[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [tempId, setTempId] = useState('');

    const openHandler = (id) => {
        setTempId(id);
        setDeleteOpen(true);
    };
    const deleteApi = async () => {
        const api = await axiosService.post('/push/task/delete', { idx: tempId });
        const { status } = api.data;
        if (status) {
            toast.info('Success Delete', { ...ToastSetting.default });
            await getData();
        }
        setDeleteOpen(false);
    };

    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Schedule List</Typography>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <TableContainer>
                    <Table size="small" aria-label="collapsible table">
                        <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Targets</TableCell>
                                <TableCell>Send</TableCell>
                                <TableCell>Pushing Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                            {}
                            {list.map((value, index: number) => (
                                <TableRow hover>
                                    <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>{value.idx}</TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            {value.title}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            {/* eslint-disable-next-line no-nested-ternary */}
                                            {value?.type === 0 ? 'Users' : value.type === 1 ? 'Drivers' : 'Depots'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            {/* eslint-disable-next-line no-nested-ternary */}
                                            {value?.sendType === 1 ? 'Push' : 'Mail'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            {dayFormatTime(value.pushDate)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            {value.pushing === 0 ? 'wait' : 'pushing'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Button onClick={() => openHandler(value.idx)}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid item xs={12} sx={{ p: 3 }}>
                    <Grid container justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                        </Grid>
                        <Grid item>
                            <Button
                                size="large"
                                sx={{ color: theme.palette.grey[900] }}
                                color="secondary"
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                            >
                                10 Rows
                            </Button>
                            <Menu
                                id="menu-user-list"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                variant="selectedMenu"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                            >
                                <MenuItem onClick={handleClose}> 10+</MenuItem>
                                <MenuItem onClick={handleClose}> 20+</MenuItem>
                                <MenuItem onClick={handleClose}> 30+</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
            <AlertDialog
                title={'Delte'}
                content={`Do you want to Delete Push?`}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteApi()}
            />
        </>
    );
};

export default TaskList;

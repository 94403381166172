import React, { Dispatch, SetStateAction } from 'react';
import PickTypeConst from '../../utils/const/pick.const';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { MessageTypeCard } from './message.const.card';
import { MessageTypeConst } from './message.const';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

interface Props {
    type: 1 | 2;
    selectMenus: number[];
    setSelectTypes: Dispatch<SetStateAction<number[]>>;
}
const MessageSelectMultiple = ({ selectMenus, setSelectTypes, type }: Props) => {
    const handleChange = (event: SelectChangeEvent<typeof selectMenus>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setSelectTypes([...value]);
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 150 }}>
                <InputLabel id="demo-multiple-checkbox-label" sx={{ background: '#fff !important', width: 50 }}>
                    {type === 1 ? 'Status' : 'Type'}
                </InputLabel>
                <Select
                    sx={{ background: '#fff !important', '& .MuiOutlinedInput-input': { background: '#fff !important' } }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectMenus}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(s) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {s.map((v) => (type === 1 ? <MessageTypeCard type={v} /> : <MessageTypeCard type={v} />))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {(type === 1 ? MessageTypeConst : MessageTypeConst).map((value, i) => (
                        <MenuItem key={`demp-dfdsfid-${i}`} value={value.value}>
                            <Checkbox checked={selectMenus.includes(value.value)} />
                            <ListItemText primary={value.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MessageSelectMultiple;

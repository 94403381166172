// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, AlertTitle, Button, Grid, TextField } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React from 'react';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import { userListProfile } from '../../_mockApis/user-profile/types';

// ==============================|| PROFILE 1 - CHANGE PASSWORD ||============================== //

const validationSchema = yup.object({
    email: yup.string().email('Enter a valid email').required('password is required')
});

interface Props {
    profile: userListProfile;
}

const ChangeEmail = ({ profile }: Props) => {
    const theme = useTheme();
    const { logout, user, updateProfile } = useAuth();
    const formik = useFormik({
        initialValues: {
            email: user?.userEmail ?? ''
        },
        validationSchema,
        onSubmit: async (values) => {
            await submit(values);
        }
    });
    const { axiosService } = useAxiosServices();
    const submit = async (value) => {
        const { email } = value;
        const api = await axiosService.post('users/app/reset/email', { email });
        const { status, data } = api.data;
        if (status) {
            toast.info('Change email', { ...ToastSetting.default });
            updateProfile(data);
        } else {
            alert(data);
        }
    };
    console.log(formik.errors);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Alert severity="success" variant="outlined" sx={{ borderColor: '#fff' }}>
                    <strong>{profile.userEmail}</strong>
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <SubCard title="Change Email">
                    <form noValidate autoComplete="off">
                        <Grid container spacing={gridSpacing} sx={{ mb: 1.75 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    type="text"
                                    id="email"
                                    fullWidth
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Grid spacing={2} container justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Grid item>
                            <AnimateButton>
                                <Button variant="contained" onClick={() => formik.handleSubmit()}>
                                    Change Email
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default ChangeEmail;

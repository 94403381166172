import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, FormControlLabel, Grid, MenuItem, RadioGroup, TextField, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';

// chart data
import { DefaultRootStateProps } from '../../types';
import useAxiosServices from '../../hooks/useAxios';
import { DateRange, DateRangePicker, LocalizationProvider, StaticDateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const status = [
    {
        value: 'today',
        label: 'Today'
    },
    {
        value: 'month',
        label: 'This Month'
    },
    {
        value: 'year',
        label: 'This Year'
    }
];

// =======================ARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const ShareChartData = (data, range, view = { text: true, feed: true, link: true, more: true }) => {
    const dataView = () => {
        const result = [];
        result.push({
            name: 'SMS',
            data: view.text ? data.text.data ?? [] : []
        });

        result.push({
            name: 'Feed',
            data: view.feed ? data.feed.data ?? [] : []
        });
        result.push({
            name: 'Link',
            data: view.link ? data.link.data ?? [] : []
        });

        result.push({
            name: 'Other ',
            data: view.more ? data.more.data ?? [] : []
        });

        return result;
    };

    return {
        height: 480,
        type: 'area',
        options: {
            chart: {
                id: 'bar-chart',
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            stroke: {
                curve: 'smooth'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 80, 100]
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],

            xaxis: {
                type: 'index',
                categories: range
            },
            legend: {
                show: true,
                fontSize: '0.875rem',
                fontFamily: `'Roboto', sans-serif`,
                position: 'top',
                offsetX: 20,
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            dataLabels: {
                enabled: false
            }
        },
        series: dataView()
    };
};

export interface TotalGrowthBarChartProps {
    isLoading: boolean;
    init: any;
}

const monthData = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
];

const radioData = [
    { label: 'Last 7days', value: '2' },
    { label: 'Last 30days', value: '55' },
    { label: 'Monthly', value: '3' },
    { label: 'Quarterly', value: '4' },
    { label: 'Custom', value: '5' }
];

const ShareChart = ({ isLoading, init }: TotalGrowthBarChartProps) => {
    const [value, setValue] = React.useState('today');
    const theme = useTheme();
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);

    const { navType } = customization;
    const { primary } = theme.palette.text;
    const darkLight = theme.palette.dark.light;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];

    const primary200 = theme.palette.primary[200];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const secondaryLight = theme.palette.secondary.light;

    const [month, setMonth] = useState('3');
    const [type, setType] = useState('2');
    const [rangeDate, setRangeDate] = React.useState<DateRange<Date>>([null, null]);
    const handleChange = (event: SelectChangeEvent) => {
        console.log(event?.target.value);
        event?.target.value && setMonth(event.target.value as string);
    };

    const handleChangeType = (event: SelectChangeEvent) => {
        console.log(event?.target.value);
        event?.target.value && setType(event.target.value as string);
    };

    const { axiosService } = useAxiosServices();
    const [apiData, setApiData] = useState({
        text: {
            data: []
        },
        link: {
            data: []
        },
        feed: {
            data: []
        },
        more: {
            data: []
        }
    });

    const [dataRange, setDataRange] = useState([]);

    const getData = async () => {
        const post = { type, range: month, custom: [] };
        if (rangeDate[1]) {
            post.custom = [...rangeDate];
        }
        const api = await axiosService.post('/statistics/dashboard/share', post);
        console.log('ddd');
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { range, data, status } = api.data;
        if (status) {
            data && setApiData({ ...data });
            range && setDataRange([...range]);
        }
    };

    useEffect(() => {
        getData();
    }, [type, month, rangeDate[1]]);

    const chartDataOpti = ShareChartData(apiData, dataRange, init);

    React.useEffect(() => {
        const newChartData = {
            ...chartDataOpti.options,
            colors: [primary200, primaryDark, secondaryMain, secondaryLight],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary]
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                }
            },
            grid: {
                borderColor: navType === 'dark' ? darkLight + 20 : grey200
            },
            tooltip: {
                theme: navType === 'dark' ? 'dark' : 'light'
            },
            legend: {
                labels: {
                    colors: grey500
                }
            }
        };

        // do not load chart when loading
        if (!isLoading) {
            ApexCharts.exec(`bar-chart`, 'updateOptions', newChartData);
        }
    }, [apiData, navType, primary200, primaryDark, secondaryMain, secondaryLight, primary, darkLight, grey200, isLoading, grey500]);

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container flexDirection={'row'}>
                                <Grid item>
                                    <Select
                                        id="columnIsd"
                                        name="columnIad"
                                        value={type}
                                        onChange={handleChangeType}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {radioData.map(({ value: monthValue, label }, index: number) => (
                                            <MenuItem key={index} value={monthValue}>
                                                {label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                {type === '88' && (
                                    <Grid item ml={2}>
                                        <Select
                                            id="columnId"
                                            name="columnId"
                                            value={month}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            {monthData.map(({ value: monthValue, label }, index: number) => (
                                                <MenuItem key={index} value={monthValue}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                )}
                                {type === '5' && (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Grid item ml={2}>
                                            <DateRangePicker
                                                startText="Start"
                                                endText="End"
                                                value={rangeDate}
                                                onChange={(newValue) => {
                                                    setRangeDate(newValue);
                                                }}
                                                renderInput={(startProps, endProps) => (
                                                    <>
                                                        <TextField {...startProps} />
                                                        <Box sx={{ mx: 2 }}> to </Box>
                                                        <TextField {...endProps} />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                )}
                            </Grid>
                            {/* @ts-ignore */}
                            <Chart {...ShareChartData(apiData, dataRange, init)} />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

export default ShareChart;

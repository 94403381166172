import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import useAxiosServices from '../../hooks/useAxios';
import PushList from './push.list';
import TaskList from './task.list';

function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`user-tabpanel-${index}`} aria-labelledby={`user-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `push-tab-${index}`,
        'aria-controls': `push-tabpanel-${index}`
    };
}

const PushListWrapper = () => {
    const { axiosService } = useAxiosServices();
    const [value, setValue] = useState(0);
    const [userRole, setUserRole] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue) => {
        setValue(newValue);
    };

    const handlerUpdateProfile = (updateData) => {};
    const navigation = useNavigate();

    return (
        <>
            <MainCard title={'List'}>
                <div>
                    <>
                        <Tabs
                            value={value}
                            indicatorColor={'primary'}
                            onChange={handleChange}
                            sx={{
                                mb: 3,
                                minHeight: 'auto',
                                '& button': {
                                    minWidth: 100
                                },
                                '& a': {
                                    minHeight: 'auto',
                                    minWidth: 10,
                                    py: 1.5,
                                    px: 1,
                                    mr: 2.25,
                                    color: 'grey.600'
                                },
                                '& a.Mui-selected': {
                                    color: 'primary.main'
                                }
                            }}
                            aria-label="simple tabs example"
                            variant="scrollable"
                        >
                            <Tab component={Link} to="#" label="Push" {...a11yProps(0)} />
                            <Tab component={Link} to="#" label="Schedule" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <PushList />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <TaskList />
                        </TabPanel>
                    </>
                </div>
            </MainCard>
        </>
    );
};

export default PushListWrapper;

import React, { useState, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import {
    Box,
    ButtonBase,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// third-party

// project imports
import { SNACKBAR_OPEN, DELETE_STORY } from 'store/actions';

// assets
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

// types
import { collectionList, pickListTypes } from 'types/pick';
import Avatar from '../../ui-component/extended/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayFormat, { timeFind } from '../../utils/times/timeFormats';
import PickTypeCard from '../../ui-component/swith/pickTypeCard';
import { useNavigate } from 'react-router-dom';
import { orderList } from '../../types/order.type';
import { OrderStatusText } from './order.list';
import OrderMore from './order.more';
import MainCard from '../../ui-component/cards/MainCard';

interface Props {
    value: orderList;
    index: number;
    handleChangeRow: (status: number) => void;
}

const OrderInner = ({ value, index, handleChangeRow }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePreventOpen = (e) => {
        e.stopPropagation();
        setOpen((p) => !p);
    };
    const handlePreventGotoUser = (e, idx) => {
        e.stopPropagation();
        navigate(`/user/default/${idx}`);
    };
    console.log(value);
    return (
        <>
            <MainCard title={'Detail'}>
                <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box
                                sx={{
                                    margin: 0
                                }}
                            >
                                <OrderMore row={value} profileView={false} handleChangeRow={handleChangeRow} />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
                <Box ml={'20px'} my={'20px'}>
                    <Typography variant={'h4'}>Products</Typography>
                </Box>

                <TableContainer>
                    <Table size="small" aria-label="collapsible table">
                        <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                            <TableRow>
                                <TableCell> </TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        {value.orders &&
                            value.orders.map((v, i) => (
                                <TableRow hover style={{ cursor: 'pointer' }}>
                                    <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            {' '}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            <Grid container spacing={2} alignItems="center" style={{ margin: 0 }}>
                                                <img
                                                    style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                                                    src={`${v.product?.image.original}`}
                                                    srcSet={`${v.product?.image.original}`}
                                                    loading="lazy"
                                                />
                                                <Grid item xs zeroMinWidth>
                                                    <Typography align="left" variant="subtitle1" component="div">
                                                        {v.product?.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            {v.qty}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography align="left" variant="subtitle2" noWrap>
                                            {v.createdAt ? dayFormat(new Date(v.createdAt)) : dayFormat(new Date())}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ width: 30, minWidth: 60 }}>
                                        <Menu
                                            id="menu-comment"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            variant="selectedMenu"
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    handleClose();
                                                    navigate(`/routing/detail/${value.idx}`);
                                                }}
                                            >
                                                More
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </Table>
                </TableContainer>

                <Box m={'50px'} />
            </MainCard>
        </>
    );
};

export default OrderInner;

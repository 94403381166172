import React from 'react';
import { userListProfile } from '../../_mockApis/user-profile/types';
import { Box, Checkbox, Chip, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreTwoToneIcon from '@mui/icons-material/MoreTwoTone';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

interface props {
    row: userListProfile;
    checkList: number[];
    handleCheck: (data: userListProfile) => void;
    index: number;
    mailModeCheck?: boolean;
}

const UserRows = ({ row, index, checkList, handleCheck, mailModeCheck }: props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const handlerGoToProfile = (idx: number) => {
        navigation(`/user/default/${idx}`);
    };

    const TransId = (dat: userListProfile) => {
        switch (dat.snsType) {
            case 1:
                return 'Email';
            case 2:
                return 'Google';
            case 3:
                return 'Apple';
            case 4:
                return 'Paypal';
            default:
                return dat.userId;
        }
    };

    return (
        <TableRow hover key={index} sx={{ padding: 0 }}>
            <TableCell sx={{ pl: 2, padding: '0.5rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AnimatePresence>
                        {mailModeCheck && (
                            <motion.div
                                initial={{ opacity: 0, width: 0 }}
                                animate={{ opacity: 1, width: 50 }}
                                exit={{ opacity: 0, width: 0 }}
                                transition={{ ease: 'easeOut', duration: 0.5 }}
                            >
                                <Checkbox
                                    sx={{ width: '100%', height: '100%', padding: 0 }}
                                    checked={checkList.includes(row.idx)}
                                    onChange={(event) => handleCheck(row)}
                                    name="checked"
                                    color="primary"
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {row.userFirstName ?? ''} {row.userName ?? ''}
                </div>
            </TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>
                {TransId(row)}
                {/* {row.userType === 1 ? 'personal' : 'business'} */}
            </TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>
                {/* <Typography align="left" variant="subtitle1" component="div"> */}
                {/*    {row.userFirstName ?? ''} {row.userName ?? ''} */}
                {/*    {row.userName && row.userStatus === 1 && ( */}
                {/*        <CheckCircleIcon sx={{ color: 'success.dark', width: 14, height: 14 }} /> */}
                {/*    )} */}
                {/* </Typography> */}
                {/* <Typography align="left" variant="subtitle2" noWrap> */}
                {/*    {row.userNickName} */}
                {/* </Typography> */}
                {row.userEmail}
            </TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>{`${row?.userAddress ?? ''} ${row?.userZip ?? ''}` ?? 'N/A'}</TableCell>
            <TableCell sx={{ padding: '0.5rem' }}>
                {row.userType === 2 && (
                    <Chip
                        label="Business"
                        size="small"
                        sx={{
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                            color: theme.palette.success.dark
                        }}
                    />
                )}
                {row.userType === 1 && (
                    <Chip
                        label="Personal"
                        size="small"
                        sx={{
                            background: theme.palette.info.light + 80,
                            color: theme.palette.info.dark
                        }}
                    />
                )}
            </TableCell>
            <TableCell align="center" sx={{ padding: 0.5, pr: 3 }}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    {/* <Tooltip placement="top" title="Message"> */}
                    {/*    <IconButton color="primary" aria-label="delete" size="large"> */}
                    {/*        <ChatBubbleTwoToneIcon sx={{ fontSize: '1.1rem' }} /> */}
                    {/*    </IconButton> */}
                    {/* </Tooltip> */}
                    <Tooltip placement="top" title="More">
                        <IconButton
                            onClick={() => handlerGoToProfile(row.idx)}
                            color="primary"
                            sx={{
                                color: theme.palette.success.dark,
                                borderColor: theme.palette.success.main,
                                '&:hover ': { background: theme.palette.success.light }
                            }}
                            size="large"
                        >
                            <MoreTwoToneIcon sx={{ fontSize: '1.1rem' }} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default UserRows;

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import DepotListWrapper from '../views/userPage/DepotListWrapper';
import CreateDepot from '../views/depot/create.depot';
import CharityPageWrapper from '../views/charity/charity.pageWrapper';
import CharityAdd from '../views/charity/charity.add';
import CharityUpdate from '../views/charity/charity.update';
import QrPage from '../views/settings/QrPage';
import TransactionWrapper from '../views/transaction/transactionWrapper';
import CommonSetting from '../views/settings/CommonSetting';
import SuburbSettingList from '../views/settings/suburb.setting';
import SuburbDetail from '../views/settings/suburb.detail';
import QrList from '../views/settings/QrList';
import DriverListWrapper from '../views/userPage/DriverListWrapper';
import OrderList from '../views/order/order.list';
import OrderStatstics from '../views/order/order.statstics';
import ShareStatstics from '../views/stat/share.statstics';
import PushDetail from '../views/settings/push.detail';
import AdminProfile from '../views/userProfile/admin.profile';
import SuburbStastics from '../views/stat/suburb.statstics';
import MessageWrapper from '../views/message/message.wrapper';
import WaitDriverList from '../views/pickPage/wait.driverList';
import WaitDepotList from '../views/pickPage/wait.depotList';
import CreateManager from '../views/userPage/Create.Manger';
import MangerWrapper from '../views/userPage/Manager.Wrapper';
import ManagerProfile from '../views/userProfile/Manager.Profile';
import SuburbRoot from '../views/settings/suburb.root';
import RouteOptimasation from '../views/pick/route.optimasation';
import XlsxRoot from '../views/xlsxPage/xlsx.root';
import ManualRoot from '../views/xlsxPage/manual.root';
import CalPage from '../views/calPage/cal.page';
import FailedDriverList from '../views/pickPage/failed.driverList';
import BeforeListWrapper from '../views/before/before.wrapper';
import MessageUserWapper from '../views/message/message.user.wrapper';
import UserPushWrapper from '../views/message/user.push.wrapper';
import MessageListWrapper from '../views/message/message.list.wrapper';
import { MapPointScreen } from '../devs/map-point';
import XlsxRootType2 from '../views/xlsxPage/xlsx.root.type2';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const DefaultDashBoard = Loadable(lazy(() => import('views/dashboard')));
const UserList = Loadable(lazy(() => import('views/userPage/UserListWrapper')));

const RouteDetail = Loadable(lazy(() => import('views/pick/RouteDetail')));
const PickList = Loadable(lazy(() => import('views/pickPage/PickPageWrapper')));
const PayoutList = Loadable(lazy(() => import('views/payout/payout.page.wrapper')));
const UserProfile = Loadable(lazy(() => import('views/userProfile')));
const RoutingList = Loadable(lazy(() => import('views/pick-routing-pages/RoutingList')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dash-board',
            element: <DefaultDashBoard />
        },
        {
            path: '/user/default',
            element: <UserList />
        },
        {
            path: '/user/beforeList',
            element: <BeforeListWrapper />
        },
        {
            path: '/user/default/:id',
            element: <UserProfile />
        },
        { path: '/user/manager/profile/:id', element: <ManagerProfile /> },
        {
            path: '/user/driver',
            element: <DriverListWrapper wait={1} />
        },
        {
            path: '/user/driverWait',
            element: <DriverListWrapper wait={2} />
        },
        {
            path: '/user/depot',
            element: <DepotListWrapper />
        },
        {
            path: '/user/CreateDepot',
            element: <CreateDepot />
        },
        {
            path: '/user/manager',
            element: <MangerWrapper />
        },
        {
            path: '/user/managerAdd',
            element: <CreateManager />
        },
        {
            path: '/routing/list',
            element: <RoutingList />
        },
        {
            path: '/routing/detail/:routeId',
            element: <RouteDetail />
        },
        {
            path: '/routing/opti/:routeId',
            element: <RouteOptimasation />
        },
        {
            path: '/routing/newOpti/:routeId',
            element: <RouteOptimasation />
        },
        {
            path: '/routing/new/',
            element: <RouteDetail />
        },
        {
            path: '/pick/list',
            element: <PickList />
        },
        {
            path: '/payout/list',
            element: <PayoutList />
        },
        {
            path: '/pick/xlsx',
            element: <XlsxRootType2 />
        },
        {
            path: '/pick/manual',
            element: <ManualRoot />
        },
        {
            path: '/pick/cal',
            element: <CalPage />
        },
        {
            path: '/pick/list/driverWait',
            element: <WaitDriverList />
        },
        {
            path: '/pick/list/failed',
            element: <FailedDriverList />
        },
        {
            path: '/pick/list/depotWait',
            element: <WaitDepotList />
        },
        {
            path: '/charity/list',
            element: <CharityPageWrapper />
        },
        {
            path: '/charity/add',
            element: <CharityAdd />
        },
        {
            path: '/charity/update/:idx',
            element: <CharityUpdate />
        },
        {
            path: '/setting/qrList',
            element: <QrList />
        },
        {
            path: '/setting/qrCode',
            element: <QrPage />
        },
        {
            path: '/setting/settings',
            element: <CommonSetting />
        },
        {
            path: '/setting/suburb',
            element: <SuburbSettingList />
        },
        {
            path: '/setting/suburb/new',
            element: <SuburbDetail />
        },
        {
            path: '/setting/suburb/:idx',
            element: <SuburbDetail />
        },
        {
            path: '/setting/suburbRoot/:idx',
            element: <SuburbRoot />
        },
        {
            path: '/transaction/list',
            element: <TransactionWrapper />
        },
        {
            path: '/order/list',
            element: <OrderList />
        },
        {
            path: '/order/financial',
            element: <OrderStatstics />
        },
        {
            path: '/setting/messaging',
            element: <PushDetail />
        },
        {
            path: '/share/financial',
            element: <ShareStatstics />
        },
        {
            path: '/admin/:id',
            element: <AdminProfile />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        { path: '/stat/postcode', element: <SuburbStastics /> },
        { path: '/messaging/setting', element: <MessageWrapper /> },
        { path: '/messaging/user/send', element: <MessageUserWapper /> },
        { path: '/messaging/push/send', element: <UserPushWrapper /> },
        { path: '/messaging/push/list', element: <MessageListWrapper /> }
        /// DEV
        // { path: '/dev/map-point', element: <MapPointScreen /> }
    ]
};

export default MainRoutes;

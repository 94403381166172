import { IconBusinessplan, IconMail } from '@tabler/icons';

const icons = {
    IconMail
};

const MessageRoute = {
    id: 'message',
    type: 'group',
    children: [
        {
            id: 'message',
            title: 'Message',
            type: 'collapse',
            icon: icons.IconMail,
            children: [
                {
                    id: 'message-all',
                    title: 'All',
                    type: 'item',
                    url: '/setting/messaging'
                },
                {
                    id: 'message-select',
                    title: 'Select',
                    type: 'item',
                    url: '/messaging/push/send'
                },
                {
                    id: 'message-select',
                    title: 'List',
                    type: 'item',
                    url: '/messaging/push/list'
                },
                {
                    id: 'message-setting',
                    title: 'Setting',
                    type: 'item',
                    url: '/messaging/setting'
                }
            ]
        }
    ]
};

export default MessageRoute;

import other from './other';
import { NavItemType } from 'types';
import dashboard from './dashboard';
import userMenu from './userList';
import pickMenu, { importMenu, payoutMenu } from './pick';
import routingRoute from './routing.route';
import charityRoute from './charity.route';
import settingRoute from './setting.route';
import transactionRoute from './transaction.route';
import orderRouting from './order.route';
import statRouting from './stat.route';
import MessageRoute from './message.route';
import devRoute from './dev.route';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [
        dashboard,
        pickMenu,
        routingRoute,
        importMenu,
        userMenu,
        payoutMenu,

        charityRoute,
        orderRouting,
        transactionRoute,
        statRouting,
        MessageRoute,
        settingRoute
        // devRoute
    ]
};

export default menuItems;

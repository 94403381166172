import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    InputBase,
    InputLabel,
    MenuItem,
    NativeSelect,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import MainCard from '../../ui-component/cards/MainCard';
import { gridSpacing } from '../../store/constant';
import { IconCodeMinus, IconSearch, IconSquareMinus } from '@tabler/icons';
import useFileUpload from 'react-use-file-upload';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { addressFormat, sleep, ToastSetting } from '../../utils/common';
import { WorkBook } from 'xlsx';
import * as yup from 'yup';
import { useFormik } from 'formik';
import dayFormat, { dayFormatNotConvert } from '../../utils/times/timeFormats';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddressInput from '../../ui-component/address/address.input';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { LOADING_END, LOADING_START } from '../../store/actions';
import { useDispatch } from 'react-redux';
import AddDepotDrawer from '../pick/drawer/add.depot.drawer';
import { DepotListProfileType } from '../../_mockApis/user-profile/types';
import useAxiosServices from '../../hooks/useAxios';
import ManualGroupInput from '../../ui-component/inputs/manual.group.input';
import XlsxGroupInput from '../../ui-component/inputs/xlsx.group.input';
import { parseExcelDate, splitName } from '../../utils/xlsx.time.format';

const xlsxHeader = [
    { value: 'SchemeID', label: 'SchemeID' },
    { value: 'FirstName', label: 'FirstName' },
    { value: 'LastName', label: 'LastName' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Address', label: 'Address' },
    { value: 'BagLocation', label: 'BagLocation' },
    { value: 'PickupDate', label: 'PickupDate' },
    { value: 'Bags', label: 'Bags' }
] as const;
const xlsxHeaderImportType = [
    { value: 'member number', label: 'membernumber' },
    { value: 'customer name', label: 'customername' },
    { value: 'customer phone', label: 'customerphone' },
    { value: 'customer email', label: 'customeremail' },
    { value: 'address', label: 'address' },
    { value: 'collection instructions', label: 'collectioninstructions' },
    { value: 'collection date', label: 'collectiondate' },
    { value: 'bags small', label: 'bagssmall' },
    { value: 'bags medium', label: 'bagsmedium' },
    { value: 'bags large', label: 'bagslarge' }
] as const;

const xlsxHeader2 = [
    { value: 'SchemeID', label: 'SchemeID' },
    { value: 'FirstName', label: 'FirstName' },
    { value: 'LastName', label: 'LastName' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Address', label: 'Address' },
    { value: 'PickupDate', label: 'PickupDate' },
    { value: 'RECANBag', label: 'RECANBag' },
    { value: 'RubbishBag', label: 'RubbishBag' },
    { value: 'WheelieBin', label: 'WheelieBin' },
    { value: 'BlukBag', label: 'BlukBag' },
    { value: 'lot', label: 'lot' },
    { value: 'lat', label: 'lat' }
] as const;
type excelLabelType = typeof xlsxHeader[number]['label'];
type excelValueType = typeof xlsxHeader2[number]['value'];
type excel2ImportType = {
    [x in excelLabelType]: string;
};
type excel2ExportType = {
    [x in excelValueType]: any;
};

type xlsxHeaderImportType = {
    [x in typeof xlsxHeaderImportType[number]['label']]: any;
};

const typeLevel = [
    { label: 'Xlsx', value: 'C' },
    { label: 'Depot', value: 'D' }
];
const itemSchema = {
    items: yup.array().of(
        yup.object().shape({
            SchemeID: yup.string().when('type', (type) => {
                if (type === '1') return yup.string().required('SchemeID is required');
            }),
            depotId: yup.string().when('type', (type) => {
                if (type === '2') return yup.string().required('DepotId is required');
            }),
            depotName: yup.string().when('type', (type) => {
                if (type === '2') return yup.string().required('Depot name is required');
            }),
            type: yup.string().required(''),
            FirstName: yup.string().required('FirstName is required'),
            LastName: yup.string().required('LastName is required'),
            Mobile: yup
                .number()
                .typeError('you must specify a number')
                .moreThan(999999, '7 characters required')
                .required('Mobile is required'),
            Address: yup.string().required('Address is required'),
            PickupDate: yup.date().required('PickupDate is required'),
            RECANBag: yup.number().typeError('you must specify a number').required('RECANBag is required'),
            RubbishBag: yup.number().typeError('you must specify a number').required('RubbishBag is required'),
            WheelieBin: yup.number().typeError('you must specify a number').required('WheelieBin is required'),
            BlukBag: yup.number().typeError('you must specify a number').required('BlukBag is required'),
            lat: yup.number(),
            lot: yup.number()
        })
    )
};
const initData = {
    Location: '',
    SchemeID: '',
    FirstName: '',
    Mobile: '',
    Address: '',
    BagLocation: '',
    PickupDate: new Date(),
    Bags: 0
};
const itemValidationSchema = yup.object().shape(itemSchema);

const XlsxRootType2 = () => {
    const itemFormik = useFormik({
        initialValues: { items: [] },
        validationSchema: itemValidationSchema,
        validate: () => {},
        onSubmit: async (values) => {
            const { items: itemUpdateValue } = values;
        }
    });
    const generateXlsx = () => {
        const xlsxHeaderCreateHeader = [
            { value: 'member number', label: 'membernumber' },
            { value: 'customer name', label: 'customername' },
            { value: 'customer phone', label: 'customerphone' },
            { value: 'customer email', label: 'customeremail' },
            { value: 'address', label: 'address' },
            { value: 'collection instructions', label: 'collectioninstructions' },
            { value: 'collection date', label: 'collectiondate' },
            { value: 'bags small', label: 'bagssmall' },
            { value: 'bags medium', label: 'bagsmedium' },
            { value: 'bags large', label: 'bagslarge' }
        ];

        // Sample data with `collection date` as Date object
        const data = [
            {
                'member number': '12345',
                'customer name': 'John Doe',
                'customer phone': '0400000000',
                'customer email': 'john.doe@example.com',
                address: '123 Sample St, City',
                'collection instructions': 'Leave at door',
                'collection date': new Date(2024, 8, 1),
                'bags small': 0,
                'bags medium': 0,
                'bags large': 0
            }
        ];

        const worksheet = XLSX.utils.json_to_sheet(data, { header: xlsxHeaderCreateHeader.map((item) => item.value) });

        XLSX.utils.sheet_add_aoa(worksheet, [xlsxHeaderCreateHeader.map((item) => item.value)], { origin: 'A1' });

        Object.keys(worksheet).forEach((cell) => {
            if (cell.startsWith('G') && !isNaN(Number(cell.substring(1)))) {
                worksheet[cell].z = 'm/d/yy'; // Set date format to MM/DD/YY
            }
        });

        // Adjust column widths to fit content
        worksheet['!cols'] = [
            { wch: 15 }, // Width for 'member number'
            { wch: 20 }, // Width for 'customer name'
            { wch: 15 }, // Width for 'customer phone'
            { wch: 25 }, // Width for 'customer email'
            { wch: 30 }, // Width for 'address'
            { wch: 30 }, // Width for 'collection instructions'
            { wch: 15 }, // Width for 'collection date'
            { wch: 12 }, // Width for 'bags small'
            { wch: 12 }, // Width for 'bags medium'
            { wch: 12 } // Width for 'bags large'
        ];

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, 'template.xlsx');
    };
    const [data, setData] = useState<excel2ExportType[]>([]);
    const dispatch = useDispatch();
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile
    } = useFileUpload();
    const [resetConst, setResetConst] = useState(false);
    const { suggestions } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */

            componentRestrictions: { country: 'AU' }
        },
        debounce: 300
    });

    const { axiosService } = useAxiosServices();
    const handleSave = async (value) => {
        try {
            dispatch({ type: LOADING_START });
            const api = await axiosService.post('pick/xlsx/save', { data: value });
            const { result } = api.data;
            if (result) {
                await itemFormik.setFieldValue('items', []);
                toast.info(`Complete`, { ...ToastSetting.default });
            }
        } catch (e) {
            toast.error(`Error`, { ...ToastSetting.default });
        } finally {
            dispatch({ type: LOADING_END });
        }
    };

    const inputRef = useRef(null);
    useEffect(() => {
        onImportExcel();
    }, [resetConst]);
    const replaceText = (text: string) => {
        if (!text) {
            return 'No Header';
        }
        return text.trim().replace(/ /g, '');
    };

    const locationCheckAndUpdate = async () => {
        setErrorList([...[]]);
        const list: excel2ExportType[] = [...itemFormik.values.items];
        const resultList = [];
        for (const row of list) {
            const tempData = { ...row, bag1: row.RECANBag, bag2: row.RubbishBag, bag3: row.WheelieBin, bag4: row.BlukBag };
            if (row.lot === 0) {
                const address = row.Address;
                // eslint-disable-next-line no-await-in-loop
                const result = await searchLocation(address);
                if (result) {
                    tempData.lot = result.lng;
                    tempData.lat = result.lat;
                    resultList.push(tempData);
                } else {
                    resultList.push(tempData);
                }
            } else {
                resultList.push(tempData);
            }
        }
        const eList = await noLocationSearch(resultList);

        setErrorList([...eList]);
        await itemFormik.setFieldValue('items', [...resultList]);
        if (eList.length > 0) {
            toast.error(`Please check the list`, { ...ToastSetting.default });
        } else {
            await handleSave(resultList);
        }
    };
    const [errorList, setErrorList] = useState<string[]>([]);

    const noLocationSearch = async (list: any[]) => {
        const searchList = [];
        for (const row of list) {
            if (row.lot === 0) {
                searchList.push(row.SchemeID);
                const findIndex = list.findIndex((v) => v.SchemeID === row.SchemeID);
            }
        }
        return searchList;
    };

    const searchLocation = async (address) => {
        try {
            const search = await getGeocode({ address });
            const { lat, lng } = getLatLng(search[0]);
            return { lat, lng };
        } catch (e) {
            return false;
        } finally {
            sleep(100);
        }
    };

    const checkImportExcel = (list: excel2ImportType[]) => {
        const result = [];
        for (const r of list) {
            const object = Object.keys(r);
            const objectList = object.map((v) => replaceText(v));
            const addData = {
                lot: 0,
                lat: 0,
                type: '1',
                depotName: '',
                depotId: ''
            };

            // XLSX Import CODE PART
            for (const row of xlsxHeaderImportType) {
                const { label, value }: any = row;
                const replaceLabel = replaceText(label);

                const check = objectList.includes(label); // 여기에서 label을 사용하여 확인합니다.
                if (!check) {
                    toast.error(`${label} is not included. check please`, { ...ToastSetting.default });
                    return { result: false, list: result };
                }
                if (label === 'collectiondate') {
                    addData[label] = parseExcelDate(r[value]);
                } else {
                    addData[label] = r[value];
                }
            }
            result.push(addData);
        }
        return { result: true, list: convertExcelDataList(result) };
    };

    const convertExcelDataList = (list: xlsxHeaderImportType[]) => {
        const convertList = [];
        for (const row of list) {
            const [first, last] = splitName(row.customername);
            const addConvertData = {
                Address: row.address,
                BlukBag: 0,
                FirstName: first,
                LastName: last,
                Mobile: row.customerphone,
                PickupDate: row.collectiondate,
                RECANBag: row.bagssmall,
                RubbishBag: row.bagsmedium,
                WheelieBin: row.bagslarge,
                SchemeID: row.membernumber,
                lat: 0,
                lot: 0,
                type: '1',
                depotName: '',
                depotId: ''
            };
            convertList.push(addConvertData);
        }
        return convertList;
    };

    const onImportExcel = () => {
        if (!files) {
            return;
        }
        if (files?.length <= 0) {
            return;
        }
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            try {
                const { result } = event.target;
                const workbook: WorkBook = XLSX.read(result, { type: 'binary' });
                if (!workbook.Sheets) {
                    toast.error('parsing error', { ...ToastSetting.default });
                    return;
                }

                // 첫 번째 시트만 처리합니다.
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
                const check = checkImportExcel(jsonData as any);

                if (check.result) {
                    setData([...check.list]);
                    itemFormik.setFieldValue('items', [...check.list]);
                }
            } catch (e) {
                console.log(e, 'error');
            }
        };
        fileReader.readAsBinaryString(files[0]);
    };
    const reset = () => {
        inputRef.current.value = '';
    };
    const scrollRef = useRef(null);

    const [sortType, setSortType] = useState(['ASC', 'ASC']);
    const handleSortType = (event: any, number) => {
        const sort = [...sortType];
        sort[number] = event.target.value as string;
        event?.target.value && setSortType([...sort]);
        listSortingItems(number, event?.target.value);
    };
    const listSortingItems = (type: number, sort: 'ASC' | 'DESC') => {
        const list = itemFormik.values?.items ?? [];
        const [bag, date] = sortType;
        if (type === 0) {
            const bagSort = list.sort((a, b) => {
                if (a.Bags < b.Bags) {
                    return sort === 'ASC' ? 1 : -1;
                }
                if (a.Bags > b.Bags) {
                    return sort === 'ASC' ? -1 : 1;
                }
                return 0;
            });
            itemFormik.setFieldValue('items', [...bagSort]);
        } else {
            const dateSort = list.sort((a, b) => {
                const aTime = dayFormatNotConvert(a.PickupDate);
                const bTime = dayFormatNotConvert(b.PickupDate);
                if (aTime === '' || bTime === '') {
                    return -1;
                }
                if (aTime > bTime) {
                    return sort === 'ASC' ? 1 : -1;
                }
                if (aTime < bTime) {
                    return sort === 'ASC' ? -1 : 1;
                }
                return 0;
            });
            itemFormik.setFieldValue('items', [...dateSort]);
        }
    };

    const errorCheck = async () => {
        dispatch({ type: LOADING_START });
        const error = itemFormik.errors;
        if (error?.items) {
            for (let i = 0; i < error.items.length; i++) {
                if (error.items[i]) {
                    const object = Object.values(error.items[i]);
                    if (object.length > 0) {
                        toast.error(`${i + 1} row ${object[0]}`, { ...ToastSetting.default });
                        dispatch({ type: LOADING_END });
                        return;
                    }
                }
            }
        }
        await locationCheckAndUpdate();
        dispatch({ type: LOADING_END });
    };

    const checkFromTouched = (index, id) => {
        const list = itemFormik.touched.items as any[];

        if (list) {
            if (list.length > 0) {
                return itemFormik.touched.items[index]?.[`${id}`] ?? false;
            }
        }
        return false;
    };

    const checkFromError = (index, id, type) => {
        const list = itemFormik.errors.items as any[];

        if (list) {
            if (list.length > 0) {
                if (type === 'check') {
                    return Boolean(list[index]?.[`${id}`] ?? false);
                } else {
                    return list[index]?.[`${id}`] ?? '';
                }
            }
        }
        return undefined;
    };

    const removeItems = (index) => {
        const list = [...itemFormik.values.items].filter((v, i) => i !== index);
        itemFormik.setFieldValue('items', list);
    };

    const [depotOpen, setDepotOpen] = useState(false);
    const [targetIndex, setTargetIndex] = useState(-1);
    const addStartDepot = (item: DepotListProfileType) => {
        setDepotOpen(false);
    };

    const handleDrawerOpenStartDepot = () => {
        setDepotOpen((prevState) => !prevState);
    };
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Import Xlsx</Typography>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} sx={{ padding: '10px' }}>
                    <Button>
                        <Typography variant={'h4'} color={'red'} onClick={() => inputRef.current?.click()}>
                            Import Xlsx
                        </Typography>
                    </Button>
                    <Button variant="contained" onClick={generateXlsx}>
                        Download XLSX Template
                    </Button>
                    <input
                        accept={'.csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                        ref={inputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onClick={(e) => {
                            reset();
                        }}
                        onChange={(e: any) => {
                            setResetConst((p) => !p);
                            setFiles(e);
                        }}
                    />
                </Box>
                {itemFormik.values.items.length > 0 && (
                    <>
                        <Box
                            display={'flex'}
                            width={'100%'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            sx={{ padding: '10px' }}
                        >
                            <Box display={'flex'} width={'100%'} justifyContent={'flex-start'} sx={{ padding: '10px' }}>
                                <FormControl>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Bags
                                    </InputLabel>
                                    <NativeSelect
                                        id="ncontrolled-native"
                                        name="ncontrolled-native"
                                        value={sortType[0]}
                                        onChange={(e) => handleSortType(e, 0)}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {[
                                            { label: 'Low to high', value: 'DESC' },
                                            { label: 'High to low', value: 'ASC' }
                                        ].map(({ value: monthValue, label }, index: number) => (
                                            <option key={index} value={monthValue}>
                                                {label}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                                <FormControl sx={{ marginLeft: '30px' }}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Date
                                    </InputLabel>
                                    <NativeSelect
                                        id="ncontrolled-native"
                                        name="ncontrolled-native"
                                        value={sortType[1]}
                                        onChange={(e) => handleSortType(e, 1)}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {[
                                            { label: 'Latest', value: 'DESC' },
                                            { label: 'Early', value: 'ASC' }
                                        ].map(({ value: monthValue, label }, index: number) => (
                                            <option key={index} value={monthValue}>
                                                {label}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                                {/* <Button variant="contained" sx={{ marginLeft: '30px' }} onClick={() => listSorting()}> */}
                                {/*    <Typography variant={'h4'} color={'white'}> */}
                                {/*        Sort */}
                                {/*    </Typography> */}
                                {/* </Button> */}
                                {/* <Button variant="contained" sx={{ marginLeft: '30px' }} onClick={() => addDataImtes()}> */}
                                {/*    <Typography variant={'h4'} color={'white'}> */}
                                {/*        Add */}
                                {/*    </Typography> */}
                                {/* </Button> */}
                            </Box>
                            <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} sx={{ padding: '10px' }}>
                                <Button variant="contained" sx={{ marginLeft: '20px' }} onClick={() => errorCheck()}>
                                    <Typography variant={'h4'} color={'white'}>
                                        Save data
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            <TableContainer ref={scrollRef} sx={{ transform: 'rotateX(180deg)' }}>
                                <Table size="small" aria-label="collapsible table" sx={{ transform: 'rotateX(180deg)' }}>
                                    <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                        <TableRow>
                                            <TableCell sx={{ maxWidth: '60px' }}>#</TableCell>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Bag</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Mobile</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            '& th,& td': {
                                                whiteSpace: 'nowrap'
                                            }
                                        }}
                                    >
                                        {itemFormik.values?.items.map((v, i) => (
                                            <TableRow
                                                hover
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: errorList.includes(v.SchemeID) ? '#ff460026' : '#fff'
                                                }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ maxWidth: '60px', width: '60px' }}>
                                                    <Typography
                                                        align="left"
                                                        variant="subtitle2"
                                                        noWrap
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => removeItems(i)}
                                                    >
                                                        <IconSquareMinus />
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '120px' }}>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        {itemFormik.values?.items[i].type === '1' ? (
                                                            <InputBase
                                                                placeholder={
                                                                    itemFormik.values?.items[i].type === '1' ? 'SchemeID' : 'DepotId'
                                                                }
                                                                id={`items[${i}].SchemeID`}
                                                                value={itemFormik.values?.items[i].SchemeID}
                                                                onChange={itemFormik.handleChange}
                                                                onBlur={itemFormik.handleBlur}
                                                                error={
                                                                    checkFromError(i, 'SchemeID', 'check') &&
                                                                    checkFromTouched(i, 'SchemeID')
                                                                }
                                                            />
                                                        ) : (
                                                            <Button onClick={() => handleDrawerOpenStartDepot()}>{'Add Depot'}</Button>
                                                        )}
                                                    </Typography>
                                                    {checkFromTouched(i, 'SchemeID') && checkFromError(i, 'SchemeID', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'SchemeID') && checkFromError(i, 'SchemeID', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '320px' }}>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        <AddressInput
                                                            placeholder={'Search address'}
                                                            sx={{ minWidth: '320px' }}
                                                            locationId={`items[${i}]`}
                                                            formikValue={itemFormik.values?.items[i].Address}
                                                            formikHandler={itemFormik.setFieldValue}
                                                            id={`items[${i}].Address`}
                                                            onBlur={itemFormik.handleBlur}
                                                            error={checkFromError(i, 'Address', 'check') && checkFromTouched(i, 'Address')}
                                                        />
                                                    </Typography>
                                                    {errorList.includes(v.SchemeID) ? (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                                                            This address can't find it. Please search again
                                                        </Typography>
                                                    ) : (
                                                        checkFromTouched(i, 'Address') &&
                                                        checkFromError(i, 'Address', 'check') && (
                                                            <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                                {checkFromTouched(i, 'Address') && checkFromError(i, 'Address', 'render')}
                                                            </Typography>
                                                        )
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '80px' }}>
                                                    <InputBase
                                                        placeholder={'First name'}
                                                        sx={{ minWidth: '40px', width: '80px' }}
                                                        id={`items[${i}].FirstName`}
                                                        value={itemFormik.values?.items[i].FirstName}
                                                        onChange={itemFormik.handleChange}
                                                        onBlur={itemFormik.handleBlur}
                                                        error={checkFromError(i, 'FirstName', 'check') && checkFromTouched(i, 'FirstName')}
                                                    />
                                                    {checkFromTouched(i, 'FirstName') && checkFromError(i, 'FirstName', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'FirstName') && checkFromError(i, 'FirstName', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '80px' }}>
                                                    <InputBase
                                                        placeholder={'Last name'}
                                                        sx={{ minWidth: '40px', width: '80px' }}
                                                        id={`items[${i}].LastName`}
                                                        value={itemFormik.values?.items[i].LastName}
                                                        onChange={itemFormik.handleChange}
                                                        onBlur={itemFormik.handleBlur}
                                                        error={checkFromError(i, 'LastName', 'check') && checkFromTouched(i, 'LastName')}
                                                    />
                                                    {checkFromTouched(i, 'LastName') && checkFromError(i, 'LastName', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'LastName') && checkFromError(i, 'LastName', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>

                                                <TableCell component="th" scope="row" sx={{ minWidth: '80px' }}>
                                                    <XlsxGroupInput
                                                        sx={{ minWidth: '40px', width: '40px' }}
                                                        i={i}
                                                        formik={itemFormik}
                                                        checkFromError={checkFromError}
                                                        checkFromTouched={checkFromTouched}
                                                    />
                                                </TableCell>

                                                <TableCell component="th" scope="row" sx={{ minWidth: '200px' }}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            renderInput={(props) => <TextField id={`items[${i}].PickupDate`} {...props} />}
                                                            value={itemFormik.values?.items[i].PickupDate}
                                                            inputFormat={'dd MMM yy'}
                                                            onChange={(newValue: Date | null) => {
                                                                itemFormik.setFieldValue(`items[${i}].PickupDate`, newValue);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '100px' }}>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        <InputBase
                                                            placeholder={'Mobile'}
                                                            sx={{ minWidth: '40px', width: '100px' }}
                                                            id={`items[${i}].Mobile`}
                                                            value={itemFormik.values?.items[i].Mobile}
                                                            onChange={itemFormik.handleChange}
                                                            onBlur={itemFormik.handleBlur}
                                                            error={checkFromError(i, 'Mobile', 'check') && checkFromTouched(i, 'Mobile')}
                                                        />
                                                    </Typography>
                                                    {checkFromTouched(i, 'Mobile') && checkFromError(i, 'Mobile', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'Mobile') && checkFromError(i, 'Mobile', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
                )}
            </MainCard>
            <AddDepotDrawer open={depotOpen} handleDrawerOpen={handleDrawerOpenStartDepot} addPick={addStartDepot} initList={[]} />
        </>
    );
};

export default XlsxRootType2;

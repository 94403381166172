import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Box,
    Button,
    ButtonBase,
    Checkbox,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconArrowBack, IconArrowLeft, IconArrowRight, IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import { jsPDF } from 'jspdf';
import useAxiosServices from '../../hooks/useAxios';
import { useDispatch } from 'react-redux';
import '../../assets/scss/Calender.css';
import { LOADING_END, LOADING_START } from '../../store/actions';
import Calendar from 'react-calendar';
import FormControl from '../../ui-component/extended/Form/FormControl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MaskedInput from 'react-text-mask';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
// import './rootCalender.css';

const validationSchema = yup.object({
    postCode: yup.string().required('postcode is required'),
    postCode2: yup.string(),
    title: yup.string().min(2, 'Name should be of minimum 2 characters length').required('Name is required'),
    basic: yup.string().required('basic minimum  is required'),
    business: yup.string().required('business minimum  is required'),
    charity: yup.string().required('charity minimum  is required'),
    school: yup.string().required('school minimum is required')
});

function swithWeek(i: number) {
    switch (i) {
        case 0:
            return 'Sun';
        case 1:
            return 'Mon';
        case 2:
            return 'Tue';
        case 3:
            return 'Wed';
        case 4:
            return 'Tur';
        case 5:
            return 'Fri';
        case 6:
            return 'Sat';
        default:
            return '';
    }
}

const SuburbRoot = () => {
    const formik = useFormik({
        initialValues: {
            postCode: '',
            title: '',
            postCode2: '',
            basic: '',
            business: '',
            charity: '',
            school: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            await postHandler();
            console.log('value', values);
        }
    });
    const [currentYear, setCurrentYear] = useState(2022);
    const theme = useTheme();

    const dispatch = useDispatch();
    const { axiosService } = useAxiosServices();
    const navigation = useNavigate();
    const [value, setValue] = useState(new Date());

    const { idx } = useParams();
    const [modify, setModify] = useState(false);

    console.log(idx);

    useEffect(() => {
        if (idx) {
            getDetail();
        }
    }, [idx]);

    const [week, setWeek] = useState([0, 0, 0, 0, 0, 0, 0]);

    const weekHandler = (i) => {
        const temp = [...week];
        const target = temp[i] === 0 ? 1 : 0;
        const convert = [...temp];
        convert[i] = target;
        setWeek([...convert]);
    };

    const weekDisabledHandler = (date) => {
        console.log(disabledDate.includes(date));
        if (week[date.getDay()] === 1) {
            return true;
        } else {
            const convert = format(date, 'yyyy-MM-dd');
            return disabledDate.includes(convert);
        }
    };

    const [disabledDate, setDisabledDate] = useState<string[]>([]);
    const [active, setActive] = useState(true);
    const dayDisabledHandler = (date) => {
        const convert = format(date, 'yyyy-MM-dd');
        const tempList = [...disabledDate];
        if (disabledDate.includes(convert)) {
            const temp = tempList.filter((v) => v !== convert);
            setDisabledDate([...temp]);
        } else {
            tempList.push(convert);
            setDisabledDate([...tempList]);
        }
    };

    const postHandler = async () => {
        const url = modify ? '/pick/location/update' : '/pick/location/add';
        const postData = modify
            ? {
                  basic: formik.values.basic,
                  school: formik.values.school,
                  business: formik.values.business,
                  charity: formik.values.charity,
                  title: formik.values.title,
                  postCode: formik.values.postCode,
                  postCode2: formik.values.postCode2,
                  weekDisabled: week,
                  dateDisabled: disabledDate,
                  active: active ? 1 : 0,
                  idx
              }
            : {
                  basic: formik.values.basic,
                  school: formik.values.school,
                  business: formik.values.business,
                  charity: formik.values.charity,
                  title: formik.values.title,
                  postCode: formik.values.postCode,
                  postCode2: formik.values.postCode2,
                  weekDisabled: week,
                  dateDisabled: disabledDate,
                  active: active ? 1 : 0
              };
        const api = await axiosService.post(url, postData);
        const { data, status } = api.data;
        if (status) {
            const info = modify ? 'Success Update Subrub' : 'Success Create Subrub';
            navigation(`/setting/suburb/${data}`);
            toast.info(info, { ...ToastSetting.default });
        } else {
            toast.info('Success Create Chairty', { ...ToastSetting.default });
        }
    };

    const getDetail = async () => {
        dispatch({ type: LOADING_START });
        try {
            const api = await axiosService.post('/pick/location/detail', { idx });
            const { data, status } = api.data;
            if (status) {
                await formik.setFieldValue('title', data.title);
                await formik.setFieldValue('postCode', data.postCode ?? '');
                await formik.setFieldValue('postCode2', data.postCode2 ?? '');
                await formik.setFieldValue('business', data.business ?? '0');
                await formik.setFieldValue('charity', data.charity ?? '0');
                await formik.setFieldValue('school', data.school ?? '0');
                await formik.setFieldValue('basic', data.basic ?? '0');
                setDisabledDate([...(data.dateDisabled ?? [])]);
                setWeek([...(data.weekDisabled ?? [])]);
                setActive(data.active === 1);
                setModify(true);
            } else {
                navigation('/setting/suburb');
            }
        } catch (e) {
        } finally {
            dispatch({ type: LOADING_END });
        }
    };

    const getRootDetail = async () => {
        const api = await axiosService.post('/pick/location/detail', { idx: 1 });
        const { data, status } = api.data;
        if (status) {
            setDisabledDate([...(data.dateDisabled ?? [])]);
            setWeek([...(data.weekDisabled ?? [])]);
            setModify(true);
        } else {
            navigation('/setting/suburb');
        }
    };

    const deleteDetail = async () => {
        const api = await axiosService.post('/pick/location/delete', { idx });
        const { data, status } = api.data;
        if (status) {
            navigation('/setting/suburb');
            toast.info('Success Delete', { ...ToastSetting.default });
        } else {
            toast.info('Error', { ...ToastSetting.default });
        }
    };

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Holidays Suburb</Typography>
                    </Grid>
                    <Grid item>
                        <Box display={'flex'} alignItems={'center'}>
                            <ButtonBase style={{ marginRight: '10px' }} onClick={() => setCurrentYear((p) => p - 1)}>
                                <IconArrowLeft />
                            </ButtonBase>
                            <Typography variant="h3">{currentYear}</Typography>
                            <ButtonBase style={{ marginLeft: '10px' }} onClick={() => setCurrentYear((p) => p + 1)}>
                                <IconArrowRight />
                            </ButtonBase>
                        </Box>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <Grid item xs={12} justify-content={'flex-end'}>
                <Box ml={4} mt={4} display={'flex'} alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
                    <AnimateButton>
                        <Button variant="contained" onClick={() => formik.handleSubmit()}>
                            {modify ? 'Update' : 'Add'}
                        </Button>
                    </AnimateButton>
                </Box>
            </Grid>

            <Grid container spacing={'10px'} columns={{ xs: 12, sm: 12, md: 10, lg: 10 }} m={2} marginBottom={'30px'}>
                {[0, 1, 2].map((v, i) => (
                    <Grid display={'flex'} item xs={12} sm={12} md={3} lg={3} justifyContent={'center'}>
                        <Calendar
                            showDoubleView={false}
                            showNeighboringMonth={false}
                            prev2Label={null}
                            prevLabel={null}
                            nextLabel={null}
                            next2Label={null}
                            calendarType={'US'}
                            tileClassName={({ date, view }) => (weekDisabledHandler(date) ? 'noView' : null)}
                            onChange={(d) => dayDisabledHandler(d)}
                            value={new Date(currentYear, v, 1)}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={'10px'} columns={{ xs: 12, sm: 12, md: 10, lg: 10 }} m={2} marginBottom={'30px'}>
                {[3, 4, 5].map((v, i) => (
                    <Grid display={'flex'} item xs={12} sm={12} md={3} lg={3} justifyContent={'center'}>
                        <Calendar
                            showDoubleView={false}
                            showNeighboringMonth={false}
                            prev2Label={null}
                            prevLabel={null}
                            nextLabel={null}
                            next2Label={null}
                            calendarType={'US'}
                            tileClassName={({ date, view }) => (weekDisabledHandler(date) ? 'noView' : null)}
                            onChange={(d) => dayDisabledHandler(d)}
                            value={new Date(currentYear, v, 1)}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={'10px'} columns={{ xs: 12, sm: 12, md: 10, lg: 10 }} m={2} marginBottom={'30px'}>
                {[6, 7, 8].map((v, i) => (
                    <Grid display={'flex'} item xs={12} sm={12} md={3} lg={3} justifyContent={'center'}>
                        <Calendar
                            showDoubleView={false}
                            showNeighboringMonth={false}
                            prev2Label={null}
                            prevLabel={null}
                            nextLabel={null}
                            next2Label={null}
                            calendarType={'US'}
                            tileClassName={({ date, view }) => (weekDisabledHandler(date) ? 'noView' : null)}
                            onChange={(d) => dayDisabledHandler(d)}
                            value={new Date(currentYear, v, 1)}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={'10px'} columns={{ xs: 12, sm: 12, md: 10, lg: 10 }} m={2} marginBottom={'30px'}>
                {[9, 10, 11].map((v, i) => (
                    <Grid display={'flex'} item xs={12} sm={12} md={3} lg={3} justifyContent={'center'}>
                        <Calendar
                            showDoubleView={false}
                            showNeighboringMonth={false}
                            prev2Label={null}
                            prevLabel={null}
                            nextLabel={null}
                            next2Label={null}
                            calendarType={'US'}
                            tileClassName={({ date, view }) => (weekDisabledHandler(date) ? 'noView' : null)}
                            onChange={(d) => dayDisabledHandler(d)}
                            value={new Date(currentYear, v, 1)}
                        />
                    </Grid>
                ))}
            </Grid>
        </MainCard>
    );
};

export default SuburbRoot;

import React, { Dispatch, SetStateAction } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useTheme } from '@mui/system';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const SuburbActiveChip = ({ value }) => {
    const theme = useTheme();
    return (
        <Chip
            label={value === 1 ? 'Active' : 'None'}
            size="small"
            sx={
                value === 1
                    ? {
                          background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                          color: theme.palette.success.dark
                      }
                    : {
                          background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
                          color: theme.palette.warning.dark
                      }
            }
        />
    );
};

interface Props {
    selectMenus: number[];
    setSelectTypes: Dispatch<SetStateAction<number[]>>;
}
const SururbMultipleSelect = ({ selectMenus, setSelectTypes }: Props) => {
    const handleChange = (event: SelectChangeEvent<typeof selectMenus>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setSelectTypes([...value]);
    };
    const label = [
        { value: 0, label: 'None' },
        { value: 1, label: 'Active' }
    ];
    return (
        <div>
            <FormControl sx={{ m: 1, width: 150 }}>
                <InputLabel id="demo-multiple-checkbox-label" sx={{ background: '#fff !important', width: 100 }}>
                    Available
                </InputLabel>
                <Select
                    sx={{ background: '#fff !important', '& .MuiOutlinedInput-input': { background: '#fff !important' } }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectMenus}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(s) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {s.map((v) => (
                                <SuburbActiveChip value={v} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {label.map((value, i) => (
                        <MenuItem key={`d123emp-dfdsfid-${i}`} value={value.value}>
                            <Checkbox checked={selectMenus.includes(value.value)} />
                            <ListItemText primary={value.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default SururbMultipleSelect;

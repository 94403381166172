import { PaletteMode } from '@mui/material';

const config: {
    basename: string;
    defaultPath: string;
    fontFamily: string;
    borderRadius: number;
    outlinedFilled: boolean;
    theme: PaletteMode;
    presetColor: string;
    i18n: string;
    rtlLayout: boolean;
    jwt: {
        secret: string;
        timeout: string;
    };
    // firebase: {
    //     apiKey: string;
    //     authDomain: string;
    //     projectId: string;
    //     storageBucket: string;
    //     messagingSenderId: string;
    //     appId: string;
    //     measurementId: string;
    // };
    // auth0: {
    //     client_id: string;
    //     domain: string;
    // };
} = {
    basename: '',
    defaultPath: '/dash-board',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'theme6', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    }
};

export default config;

import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    InputBase,
    InputLabel,
    MenuItem,
    NativeSelect,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import MainCard from '../../ui-component/cards/MainCard';
import { gridSpacing } from '../../store/constant';
import { IconCodeMinus, IconSearch, IconSquareMinus } from '@tabler/icons';
import PickPageListDetail from '../pickPage/PickPageListDetail';
import useFileUpload from 'react-use-file-upload';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { addressFormat, sleep, ToastSetting } from '../../utils/common';
import { WorkBook } from 'xlsx';
import * as yup from 'yup';
import { useFormik } from 'formik';
import dayFormat, { dayFormatNotConvert } from '../../utils/times/timeFormats';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddressInput from '../../ui-component/address/address.input';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { LOADING_END, LOADING_START } from '../../store/actions';
import { useDispatch } from 'react-redux';
import AddDepotDrawer from '../pick/drawer/add.depot.drawer';
import { DepotListProfileType } from '../../_mockApis/user-profile/types';
import ManualGroupInput from '../../ui-component/inputs/manual.group.input';
import useAxiosServices from '../../hooks/useAxios';

const xlsxHeader = [
    { value: 'SchemeID', label: 'Scheme ID' },
    { value: 'FirstName', label: 'First Name' },
    { value: 'LastName', label: 'Last Name' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Address', label: 'Address' },
    { value: 'BagLocation', label: 'Bag Location' },
    { value: 'PickupDate', label: 'Pickup Date' },
    { value: 'Bags', label: 'Bags' }
] as const;
const xlsxHeader2 = [
    { value: 'SchemeID', label: 'Scheme ID' },
    { value: 'FirstName', label: 'First Name' },
    { value: 'LastName', label: 'Last Name' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Address', label: 'Address' },
    { value: 'BagLocation', label: 'Bag Location' },
    { value: 'PickupDate', label: 'Pickup Date' },
    { value: 'Bags', label: 'Bags' },
    { value: 'lot', label: 'lot' },
    { value: 'lat', label: 'lat' }
] as const;
type excelLabelType = typeof xlsxHeader[number]['label'];
type excelValueType = typeof xlsxHeader2[number]['value'];
type excel2ImportType = {
    [x in excelLabelType]: string;
};
type excel2ExportType = {
    [x in excelValueType]: any;
};

const typeLevel = [
    { label: 'Xlsx', value: 'C' },
    { label: 'Depot', value: 'D' }
];
const depotRowTypeLevel = [
    { label: 'Coex', value: '1' },
    { label: 'Depot', value: '2' }
];
const itemSchema = {
    items: yup.array().of(
        yup.object().shape({
            SchemeID: yup.string().when('type', (type) => {
                if (type === '1') return yup.string().required('SchemeID is required');
            }),
            depotId: yup.string().when('type', (type) => {
                if (type === '2') return yup.string().required('DepotId is required');
            }),
            depotName: yup.string().when('type', (type) => {
                if (type === '2') return yup.string();
            }),
            type: yup.string().required(''),
            FirstName: yup.string().required('FirstName is required'),
            LastName: yup.string().required('LastName is required'),
            Mobile: yup
                .number()
                .typeError('you must specify a number')
                .moreThan(999999, '7 characters required')
                .required('Mobile is required'),
            Address: yup.string().required('Address is required'),
            BagLocation: yup.string().required('BagLocation is required'),
            PickupDate: yup.date().required('PickupDate is required'),
            Bags: yup.number().when('type', (type) => {
                if (type === '1')
                    return yup
                        .number()
                        .typeError('you must specify a number')
                        .required('Bags is required')
                        .moreThan(0, 'Bags should not be zero');
            }),
            bag1: yup.number().when('type', (type) => {
                if (type === '2')
                    return yup.number().typeError('you must specify a number').moreThan(-1, 'Only positive numbers are allowed');
            }),
            bag2: yup.number().when('type', (type) => {
                if (type === '2')
                    return yup.number().typeError('you must specify a number').moreThan(-1, 'Only positive numbers are allowed');
            }),
            bag3: yup.number().when('type', (type) => {
                if (type === '2')
                    return yup.number().typeError('you must specify a number').moreThan(-1, 'Only positive numbers are allowed');
            }),
            bag4: yup.number().when('type', (type) => {
                if (type === '2')
                    return yup.number().typeError('you must specify a number').moreThan(-1, 'Only positive numbers are allowed');
            }),

            lat: yup.number(),
            lot: yup.number()
        })
    )
};
const initData = {
    Location: '',
    SchemeID: '',
    FirstName: '',
    Mobile: '',
    Address: '',
    BagLocation: '',
    PickupDate: new Date(),
    Bags: 0
};
const itemValidationSchema = yup.object().shape(itemSchema);

const ManualRoot = () => {
    const itemFormik = useFormik({
        initialValues: { items: [] },
        validationSchema: itemValidationSchema,
        validate: () => {
            console.log('dd');
        },
        onSubmit: async (values) => {
            const { items: itemUpdateValue } = values;
        }
    });
    const [data, setData] = useState<excel2ExportType[]>([]);
    const dispatch = useDispatch();
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile
    } = useFileUpload();
    const [resetConst, setResetConst] = useState(false);
    const { suggestions } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */

            componentRestrictions: { country: 'AU' }
        },
        debounce: 300
    });

    const { axiosService } = useAxiosServices();
    const handleSave = async (value) => {
        try {
            dispatch({ type: LOADING_START });
            const api = await axiosService.post('pick/xlsx/save', { data: value });
            const { result } = api.data;
            if (result) {
                await itemFormik.setFieldValue('items', []);
                toast.info(`Complete`, { ...ToastSetting.default });
            }
        } catch (e) {
            toast.error(`Error`, { ...ToastSetting.default });
        } finally {
            dispatch({ type: LOADING_END });
        }
    };
    const inputRef = useRef(null);
    console.log('files', files);
    useEffect(() => {
        onImportExcel();
    }, [resetConst]);
    const replaceText = (text: string) => {
        if (!text) {
            return 'No Header';
        }
        return text.trim().replace(/ /g, '');
    };

    const locationCheckAndUpdate = async () => {
        setErrorList([...[]]);
        const list: excel2ExportType[] = [...itemFormik.values.items];
        const resultList = [];
        for (const row of list) {
            const tempData = { ...row };
            if (row.lot === 0) {
                const address = row.Address;
                // const { lat, lng } = getLatLng(results[0]);
                // console.log('📍 Coordinates: ', results);
                // eslint-disable-next-line no-await-in-loop
                const result = await searchLocation(address);
                if (result) {
                    console.log('result', result);
                    tempData.lot = result.lng;
                    tempData.lat = result.lat;
                    resultList.push(tempData);
                } else {
                    resultList.push(tempData);
                }
            } else {
                resultList.push(tempData);
            }
        }
        const eList = await noLocationSearch(resultList);
        console.log('eListeList', eList);
        setErrorList([...eList]);
        await itemFormik.setFieldValue('items', [...resultList]);
        if (eList.length > 0) {
            toast.error(`Please check the list`, { ...ToastSetting.default });
        } else {
            await handleSave(itemFormik.values.items);
        }
    };
    const [errorList, setErrorList] = useState<string[]>([]);
    console.log('you', errorList);

    const noLocationSearch = async (list: any[]) => {
        const searchList = [];
        console.log('LIST', list);
        for (const row of list) {
            if (row.lot === 0) {
                searchList.push(row.SchemeID);
                const findIndex = list.findIndex((v) => v.SchemeID === row.SchemeID);
            }
        }
        console.log('LIST', searchList);
        return searchList;
    };
    console.log('ERRRRRRR', itemFormik.errors);
    console.log('ERRRRRRR', itemFormik.touched);
    const searchLocation = async (address) => {
        try {
            const search = await getGeocode({ address });
            const { lat, lng } = getLatLng(search[0]);
            return { lat, lng };

            console.log('search', search);
        } catch (e) {
            return false;
        } finally {
            sleep(100);
        }
    };

    const handleConvertImportDataExport = (list: excel2ImportType[]) => {
        const result = [];
        for (const r of list) {
            const object = Object.keys(r);
            for (const row of object) {
                const objectList = object.map((v) => replaceText(v));
            }
        }
    };
    const checkImportExcel = (list: excel2ImportType[]) => {
        const result = [];
        for (const r of list) {
            const object = Object.keys(r);
            const objectList = object.map((v) => replaceText(v));
            const addData = {
                lot: 0,
                lat: 0,
                type: '1',
                depotName: '',
                depotId: ''
            };
            for (const row of xlsxHeader) {
                const { label, value }: any = row;
                const check = objectList.includes(value);
                if (!check) {
                    toast.error(`${label} is not included. check please`, { ...ToastSetting.default });
                    return { result: false, list: result };
                }
                if (value === 'PickupDate') {
                    addData[value] = dayFormatNotConvert(r[label]);
                } else {
                    addData[value] = r[label];
                }
            }

            result.push(addData);
        }

        return { result: true, list: result };
    };

    const onImportExcel = () => {
        console.log('files', files);
        if (!files) {
            return;
        }
        if (files?.length <= 0) {
            return;
        }
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            try {
                const { result } = event.target;
                const workbook: WorkBook = XLSX.read(result, { type: 'binary' });
                const dataValue = [];
                if (!workbook.Sheets) {
                    toast.error('parsing error', { ...ToastSetting.default });
                    return;
                }

                // eslint-disable-next-line guard-for-in
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets[sheet]) {
                        const t: excel2ImportType[] = dataValue.concat([], XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        console.log('t', t);
                        const check = checkImportExcel(t);
                        console.log('check', check);
                        if (check.result) {
                            setData([...check.list]);
                            itemFormik.setFieldValue('items', [...check.list]);
                        }
                    }

                    break;
                }
            } catch (e) {
                console.log(e, 'eeee');
            }
        };
        fileReader.readAsBinaryString(files[0]);
    };
    const reset = () => {
        inputRef.current.value = '';
    };
    const scrollRef = useRef(null);
    const [saveType, setSaveType] = useState('C');
    const handleChangeType = (event: SelectChangeEvent) => {
        event?.target.value && setSaveType(event.target.value as string);
    };
    const [sortType, setSortType] = useState(['ASC', 'ASC']);
    const handleSortType = (event: any, number) => {
        const sort = [...sortType];
        sort[number] = event.target.value as string;
        event?.target.value && setSortType([...sort]);
        listSortingItems(number, event?.target.value);
    };
    const changeDepotRowItem = (event: any, id) => {
        if (event?.target.value === '1') {
            itemFormik.setFieldValue(`${id}.depotId`, '');
            itemFormik.setFieldValue(`${id}.depotName`, '');
        }
        event?.target.value && itemFormik.setFieldValue(`${id}.type`, event?.target.value);
    };
    const listSortingItems = (type: number, sort: 'ASC' | 'DESC') => {
        const list = itemFormik.values?.items ?? [];
        const [bag, date] = sortType;
        if (type === 0) {
            const bagSort = list.sort((a, b) => {
                if (a.Bags < b.Bags) {
                    return sort === 'ASC' ? 1 : -1;
                }
                if (a.Bags > b.Bags) {
                    return sort === 'ASC' ? -1 : 1;
                }
                return 0;
            });
            itemFormik.setFieldValue('items', [...bagSort]);
        } else {
            const dateSort = list.sort((a, b) => {
                const aTime = dayFormatNotConvert(a.PickupDate);
                const bTime = dayFormatNotConvert(b.PickupDate);
                if (aTime === '' || bTime === '') {
                    return -1;
                }
                if (aTime > bTime) {
                    return sort === 'ASC' ? 1 : -1;
                }
                if (aTime < bTime) {
                    return sort === 'ASC' ? -1 : 1;
                }
                return 0;
            });
            itemFormik.setFieldValue('items', [...dateSort]);
        }
    };
    const listSorting = () => {
        const list = itemFormik.values?.items ?? [];
        const [bag, date] = sortType;

        const dateSort = list.sort((a, b) => {
            const aTime = dayFormatNotConvert(a.PickupDate);
            const bTime = dayFormatNotConvert(b.PickupDate);
            if (aTime === '' || bTime === '') {
                return -1;
            }
            if (aTime > bTime) {
                return date === 'ASC' ? 1 : -1;
            }
            if (aTime < bTime) {
                return date === 'ASC' ? -1 : 1;
            }
            return 0;
        });

        const bagSort = dateSort.sort((a, b) => {
            if (a.Bags < b.Bags) {
                return bag === 'ASC' ? 1 : -1;
            }
            if (a.Bags > b.Bags) {
                return bag === 'ASC' ? -1 : 1;
            }
            return 0;
        });

        itemFormik.setFieldValue('items', [...bagSort]);
    };

    const errorCheck = async () => {
        dispatch({ type: LOADING_START });
        const error = itemFormik.errors;
        if (error?.items) {
            for (let i = 0; i < error.items.length; i++) {
                if (error.items[i]) {
                    const object = Object.values(error.items[i]);
                    if (object.length > 0) {
                        toast.error(`${i + 1} row ${object[0]}`, { ...ToastSetting.default });
                        dispatch({ type: LOADING_END });
                        return;
                    }
                }
            }
        }
        await locationCheckAndUpdate();
        dispatch({ type: LOADING_END });
    };

    const checkFromTouched = (index, id) => {
        const list = itemFormik.touched.items as any[];

        if (list) {
            if (list.length > 0) {
                return itemFormik.touched.items[index]?.[`${id}`] ?? false;
            }
        }
        return false;
    };

    const checkFromError = (index, id, type) => {
        const list = itemFormik.errors.items as any[];

        if (list) {
            if (list.length > 0) {
                if (type === 'check') {
                    return Boolean(list[index]?.[`${id}`] ?? false);
                } else {
                    return list[index]?.[`${id}`] ?? '';
                }
            }
        }
        return undefined;
    };

    const addDataItems = () => {
        const addData = {
            Location: '',
            SchemeID: '',
            FirstName: '',
            LastName: '',
            Mobile: 0,
            Address: '',
            BagLocation: '',
            PickupDate: '',
            Bags: 0,
            lat: 0,
            lot: 0,
            type: '2',
            depotName: '',
            depotId: '',
            bag1: 0,
            bag2: 0,
            bag3: 0,
            bag4: 0
        };
        const list = [...itemFormik.values.items, addData];
        itemFormik.setFieldValue('items', list);
    };

    const removeItems = (index) => {
        const list = [...itemFormik.values.items].filter((v, i) => i !== index);
        itemFormik.setFieldValue('items', list);
    };
    console.log('[...itemFormik.values.items]', itemFormik.values.items);

    const [depotOpen, setDepotOpen] = useState(false);
    const [targetIndex, setTargetIndex] = useState(-1);
    const addStartDepot = (item: DepotListProfileType) => {
        console.log('depotName', targetIndex);
        itemFormik.setFieldValue(`items[${targetIndex}].depotName`, item.userName);
        itemFormik.setFieldValue(`items[${targetIndex}].depotId`, String(item.idx));
        setDepotOpen(false);
    };

    const handleDrawerOpenStartDepot = (i = -1) => {
        if (!depotOpen) {
            setTargetIndex(i);
        } else {
            setTargetIndex(-1);
        }
        setDepotOpen((prevState) => !prevState);
    };
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Manual Booking</Typography>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} sx={{ padding: '10px' }}>
                    <Button onClick={() => addDataItems()}>
                        <Typography variant={'h4'} color={'green'}>
                            Add rows
                        </Typography>
                    </Button>
                    <input
                        accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                        ref={inputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onClick={(e) => {
                            reset();
                        }}
                        onChange={(e: any) => {
                            setResetConst((p) => !p);
                            setFiles(e);
                        }}
                    />
                </Box>
                {itemFormik.values.items.length > 0 && (
                    <>
                        <Box
                            display={'flex'}
                            width={'100%'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            sx={{ padding: '10px' }}
                        >
                            <Box display={'flex'} width={'100%'} justifyContent={'flex-start'} sx={{ padding: '10px' }}>
                                <FormControl>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Bags
                                    </InputLabel>
                                    <NativeSelect
                                        id="ncontrolled-native"
                                        name="ncontrolled-native"
                                        value={sortType[0]}
                                        onChange={(e) => handleSortType(e, 0)}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {[
                                            { label: 'Low to high', value: 'DESC' },
                                            { label: 'High to low', value: 'ASC' }
                                        ].map(({ value: monthValue, label }, index: number) => (
                                            <option key={index} value={monthValue}>
                                                {label}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                                <FormControl sx={{ marginLeft: '30px' }}>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Date
                                    </InputLabel>
                                    <NativeSelect
                                        id="ncontrolled-native"
                                        name="ncontrolled-native"
                                        value={sortType[1]}
                                        onChange={(e) => handleSortType(e, 1)}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {[
                                            { label: 'Latest', value: 'DESC' },
                                            { label: 'Early', value: 'ASC' }
                                        ].map(({ value: monthValue, label }, index: number) => (
                                            <option key={index} value={monthValue}>
                                                {label}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                                {/* <Button variant="contained" sx={{ marginLeft: '30px' }} onClick={() => listSorting()}> */}
                                {/*    <Typography variant={'h4'} color={'white'}> */}
                                {/*        Sort */}
                                {/*    </Typography> */}
                                {/* </Button> */}
                                {/* <Button variant="contained" sx={{ marginLeft: '30px' }} onClick={() => addDataImtes()}> */}
                                {/*    <Typography variant={'h4'} color={'white'}> */}
                                {/*        Add */}
                                {/*    </Typography> */}
                                {/* </Button> */}
                            </Box>
                            <Box display={'flex'} width={'100%'} justifyContent={'flex-end'} sx={{ padding: '10px' }}>
                                <Button variant="contained" sx={{ marginLeft: '20px' }} onClick={() => errorCheck()}>
                                    <Typography variant={'h4'} color={'white'}>
                                        Save data
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            <TableContainer ref={scrollRef} sx={{ transform: 'rotateX(180deg)', paddingTop: '100px' }}>
                                <Table size="small" aria-label="collapsible table" sx={{ transform: 'rotateX(180deg)' }}>
                                    <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                        <TableRow>
                                            <TableCell sx={{ maxWidth: '60px' }}>#</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>SchemeID</TableCell>
                                            <TableCell>Depot</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Bag</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Mobile</TableCell>
                                            <TableCell>BagLocation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            '& th,& td': {
                                                whiteSpace: 'nowrap'
                                            }
                                        }}
                                    >
                                        {itemFormik.values?.items.map((v, i) => (
                                            <TableRow
                                                hover
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: errorList.includes(v.SchemeID) ? '#ff460026' : '#fff'
                                                }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ maxWidth: '60px', width: '60px' }}>
                                                    <Typography
                                                        align="left"
                                                        variant="subtitle2"
                                                        noWrap
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => removeItems(i)}
                                                    >
                                                        <IconSquareMinus />
                                                    </Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '120px' }}>
                                                    <Select
                                                        id={`items[${i}].type`}
                                                        name={'columnIad'}
                                                        value={itemFormik.values?.items[i].type}
                                                        onChange={(e) => changeDepotRowItem(e, `items[${i}]`)}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {depotRowTypeLevel.map(({ value: monthValue, label }, index: number) => (
                                                            <MenuItem key={index} value={monthValue}>
                                                                {label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '120px' }}>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        <InputBase
                                                            placeholder={'SchemeID'}
                                                            id={`items[${i}].SchemeID`}
                                                            value={itemFormik.values?.items[i].SchemeID}
                                                            onChange={itemFormik.handleChange}
                                                            onBlur={itemFormik.handleBlur}
                                                            error={
                                                                checkFromError(i, 'SchemeID', 'check') && checkFromTouched(i, 'SchemeID')
                                                            }
                                                        />
                                                    </Typography>
                                                    {checkFromTouched(i, 'SchemeID') && checkFromError(i, 'SchemeID', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'SchemeID') && checkFromError(i, 'SchemeID', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '120px' }}>
                                                    <Button disabled={v.type === '1'} onClick={() => handleDrawerOpenStartDepot(i)}>
                                                        {`${v.depotId === '' ? 'Add depot' : v.depotName}`}
                                                    </Button>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '320px' }}>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        <AddressInput
                                                            placeholder={'Search address'}
                                                            sx={{ minWidth: '320px' }}
                                                            locationId={`items[${i}]`}
                                                            formikValue={itemFormik.values?.items[i].Address}
                                                            formikHandler={itemFormik.setFieldValue}
                                                            id={`items[${i}].Address`}
                                                            onBlur={itemFormik.handleBlur}
                                                            error={checkFromError(i, 'Address', 'check') && checkFromTouched(i, 'Address')}
                                                        />
                                                    </Typography>
                                                    {errorList.includes(v.SchemeID) ? (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                                                            This address can't find it. Please search again
                                                        </Typography>
                                                    ) : (
                                                        checkFromTouched(i, 'Address') &&
                                                        checkFromError(i, 'Address', 'check') && (
                                                            <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                                {checkFromTouched(i, 'Address') && checkFromError(i, 'Address', 'render')}
                                                            </Typography>
                                                        )
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '80px' }}>
                                                    <InputBase
                                                        placeholder={'First name'}
                                                        sx={{ minWidth: '40px', width: '80px' }}
                                                        id={`items[${i}].FirstName`}
                                                        value={itemFormik.values?.items[i].FirstName}
                                                        onChange={itemFormik.handleChange}
                                                        onBlur={itemFormik.handleBlur}
                                                        error={checkFromError(i, 'FirstName', 'check') && checkFromTouched(i, 'FirstName')}
                                                    />
                                                    {checkFromTouched(i, 'FirstName') && checkFromError(i, 'FirstName', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'FirstName') && checkFromError(i, 'FirstName', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '80px' }}>
                                                    <InputBase
                                                        placeholder={'Last name'}
                                                        sx={{ minWidth: '40px', width: '80px' }}
                                                        id={`items[${i}].LastName`}
                                                        value={itemFormik.values?.items[i].LastName}
                                                        onChange={itemFormik.handleChange}
                                                        onBlur={itemFormik.handleBlur}
                                                        error={checkFromError(i, 'LastName', 'check') && checkFromTouched(i, 'LastName')}
                                                    />
                                                    {checkFromTouched(i, 'LastName') && checkFromError(i, 'LastName', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'LastName') && checkFromError(i, 'LastName', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>

                                                <TableCell component="th" scope="row" sx={{ minWidth: '80px' }}>
                                                    {v.type === '1' ? (
                                                        <Box>
                                                            <Typography align="left" variant="subtitle2" noWrap>
                                                                <InputBase
                                                                    placeholder={'Bags'}
                                                                    type={'number'}
                                                                    inputMode={'numeric'}
                                                                    sx={{ minWidth: '80px', width: '80px' }}
                                                                    id={`items[${i}].Bags`}
                                                                    value={itemFormik.values?.items[i].Bags}
                                                                    onChange={itemFormik.handleChange}
                                                                    onBlur={itemFormik.handleBlur}
                                                                    error={
                                                                        checkFromError(i, 'Bags', 'check') && checkFromTouched(i, 'Bags')
                                                                    }
                                                                />
                                                            </Typography>
                                                            {checkFromTouched(i, 'Bags') && checkFromError(i, 'Bags', 'check') && (
                                                                <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                                    {checkFromTouched(i, 'Bags') && checkFromError(i, 'Bags', 'render')}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    ) : (
                                                        <ManualGroupInput
                                                            sx={{ minWidth: '40px', width: '40px' }}
                                                            i={i}
                                                            formik={itemFormik}
                                                            checkFromError={checkFromError}
                                                            checkFromTouched={checkFromTouched}
                                                        />
                                                    )}
                                                </TableCell>

                                                <TableCell component="th" scope="row" sx={{ minWidth: '200px' }}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            renderInput={(props) => <TextField id={`items[${i}].PickupDate`} {...props} />}
                                                            value={itemFormik.values?.items[i].PickupDate}
                                                            inputFormat={'dd MMM yy'}
                                                            onChange={(newValue: Date | null) => {
                                                                itemFormik.setFieldValue(`items[${i}].PickupDate`, newValue);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </TableCell>
                                                <TableCell component="th" scope="row" sx={{ minWidth: '100px' }}>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        <InputBase
                                                            placeholder={'Mobile'}
                                                            sx={{ minWidth: '40px', width: '100px' }}
                                                            id={`items[${i}].Mobile`}
                                                            value={itemFormik.values?.items[i].Mobile}
                                                            onChange={itemFormik.handleChange}
                                                            onBlur={itemFormik.handleBlur}
                                                            error={checkFromError(i, 'Mobile', 'check') && checkFromTouched(i, 'Mobile')}
                                                        />
                                                    </Typography>
                                                    {checkFromTouched(i, 'Mobile') && checkFromError(i, 'Mobile', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'Mobile') && checkFromError(i, 'Mobile', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>

                                                <TableCell component="th" scope="row" sx={{ minWidth: '200px' }}>
                                                    <Typography align="left" variant="subtitle2" noWrap>
                                                        <InputBase
                                                            placeholder={'Bag location'}
                                                            sx={{ minWidth: '200px' }}
                                                            id={`items[${i}].BagLocation`}
                                                            value={itemFormik.values?.items[i].BagLocation}
                                                            onChange={itemFormik.handleChange}
                                                            onBlur={itemFormik.handleBlur}
                                                            error={
                                                                checkFromError(i, 'BagLocation', 'check') &&
                                                                checkFromTouched(i, 'BagLocation')
                                                            }
                                                        />
                                                    </Typography>
                                                    {checkFromTouched(i, 'BagLocation') && checkFromError(i, 'BagLocation', 'check') && (
                                                        <Typography sx={{ color: 'red', fontSize: '10px' }}>
                                                            {checkFromTouched(i, 'BagLocation') &&
                                                                checkFromError(i, 'BagLocation', 'render')}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
                )}
            </MainCard>
            <AddDepotDrawer open={depotOpen} handleDrawerOpen={handleDrawerOpenStartDepot} addPick={addStartDepot} initList={[]} />
        </>
    );
};

export default ManualRoot;

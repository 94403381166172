import React, { useState } from 'react';
import { Grid, Button, TextField, Typography, Stack, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useTheme } from '@mui/system';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { gridSpacing } from '../../store/constant';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import { userListProfile } from '../../_mockApis/user-profile/types';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import ReactDraftWysiwyg from '../../ui-component/editor/ReactDraftWysiwyg';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
    title: yup.string().required('title is required')
});

interface Props {
    type: number;
}

const PushMailAll = ({ type }: Props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const formik = useFormik({
        initialValues: {
            title: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            await sendPush();
        }
    });
    const { axiosService } = useAxiosServices();
    const [content, setContent] = useState('');

    const sendPush = async () => {
        if (r) {
            if (!rangeDate) {
                alert('The time of reservation was incorrectly entered or invalid.');
                return;
            }
        }

        const api = await axiosService.post('push/all/mail', {
            type,
            title: formik.values.title,
            content,
            re: r,
            time: rangeDate
        });
        const { status } = api.data;
        if (status) {
            toast.info('Success Send to mail', { ...ToastSetting.default });
            navigation(0);
        } else {
            toast.error('Failed Send to mail', { ...ToastSetting.default });
        }
    };
    console.log(formik.errors);

    const [r, setR] = useState(false);
    const [rangeDate, setRangeDate] = useState(null);
    const [error, setError] = useState(false);
    console.log('ERROR', error);
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} mt={4}>
                <TextField
                    id="title"
                    fullWidth
                    label="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    '& .rdw-editor-wrapper': {
                        bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                        border: '1px solid',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        '& .rdw-editor-main': {
                            px: 2,
                            py: 0.5,
                            border: 'none',
                            minHeight: '300px'
                        },
                        '& .rdw-editor-toolbar': {
                            pt: 1.25,
                            border: 'none',
                            borderBottom: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                            '& .rdw-image-modal': {
                                left: '-100px'
                            },
                            '& .rdw-option-wrapper': {
                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                            },
                            '& .rdw-dropdown-wrapper': {
                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                '& .rdw-dropdown-selectedtext': {
                                    color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                }
                            }
                        }
                    }
                }}
            >
                <Stack spacing={gridSpacing}>
                    <ReactDraftWysiwyg value={content} setValue={setContent} />
                </Stack>
            </Grid>
            <Grid item container>
                <Grid>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value={r} onChange={() => setR((p) => !p)} />} label="reservation message" />
                    </FormGroup>
                </Grid>
                {r && (
                    <Grid>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid item ml={2}>
                                <DateTimePicker
                                    onError={() => setError(true)}
                                    onAccept={() => setError(false)}
                                    value={rangeDate}
                                    minDateTime={new Date()}
                                    onChange={(newValue) => {
                                        setRangeDate(newValue);
                                    }}
                                    renderInput={(props) => (
                                        <>
                                            <TextField {...props} />
                                        </>
                                    )}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} justifyContent={'flex-end'}>
                <Button onClick={() => formik.handleSubmit()}>Send</Button>
            </Grid>
        </Grid>
    );
};

export default PushMailAll;

import { Box, Checkbox, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { PickProfileChip } from '../../ui-component/swith/pickTypeCard';
import { PickProfileConst } from '../../utils/const/pick.const';
import React, { useCallback, useEffect, useState } from 'react';
import { locationListType } from '../../types/pick';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';

interface Props {
    data: locationListType;
}
export const SuburbWeeklySelect = ({ data }: Props) => {
    const [weekly, setWeekly] = useState('0');
    const { axiosService } = useAxiosServices();
    const selectValue = useCallback(() => {
        console.log('Data', data.frequency);
        if (data.frequency === 0) {
            return '1';
        }
        if (data.frequencyType === 0) {
            return '2';
        }
        return '3';
    }, [data]);
    useEffect(() => {
        setWeekly(selectValue());
    }, [data]);

    const handleActive = useCallback(
        async (type) => {
            const api = await axiosService.post('/address/weekly/change', { idx: data.idx, type });
            const { data: apiData } = api.data;
            setWeekly(apiData);
            console.log('api', api.data);
            toast.info(`${data.title} frequency Updated!`, { ...ToastSetting.default });
        },
        [data]
    );

    const label = [
        { value: '1', label: 'Weekly' },
        { value: '2', label: 'Bi-weekly(2-4)' },
        { value: '3', label: 'Bi-weekly(1-3-5)' }
    ];
    return (
        <Select
            sx={{
                height: '30px',
                width: '100%',
                background: 'none !important',
                '& .MuiOutlinedInput-input': { background: 'none !important' }
            }}
            labelId="selectsubWeekly"
            id="selectsubWeeklyID"
            value={weekly}
            onChange={(e) => handleActive(e.target.value)}
        >
            {label.map((value, i) => (
                <MenuItem key={`demp-dfdsfid-${i}`} value={value.value}>
                    <ListItemText primary={value.label} />
                </MenuItem>
            ))}
        </Select>
    );
};

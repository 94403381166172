import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, IconButton, InputAdornment, Stack, Switch, TextField, Typography } from '@mui/material';
import { gridSpacing } from '../../store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { userListProfile } from '../../_mockApis/user-profile/types';
import MaskedInput from 'react-text-mask';
import AnimateButton from '../../ui-component/extended/AnimateButton';
import MapDialog from '../../ui-component/dialog/MapDialog';
import { Search, Visibility, VisibilityOff } from '@mui/icons-material';
import useAxiosServices from '../../hooks/useAxios';
import AlertDialog from '../../ui-component/dialog/AlertDialog';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import SubCard from '../../ui-component/cards/SubCard';
import useAuth from '../../hooks/useAuth';

const validationSchema = yup.object({
    userId: yup.string().min(2, 'user id should be of minimum 2 characters length').required('id is required'),
    userPwd: yup.string().min(8, 'password should be of minimum 8 characters length').required('password is required'),
    userPwdCheck: yup.string().oneOf([yup.ref('userPwd'), null], 'Passwords must match'),
    userName: yup.string().min(1, 'last name should be of minimum 1 characters length').required('last name is required'),
    userFirstName: yup.string().min(1, 'first name should be of minimum 1 characters length').required('first name is required'),
    userEmail: yup.string().email('Enter a valid email').required('Email is required'),
    note: yup.string()
});

const CreateManager = () => {
    const { axiosService } = useAxiosServices();
    const navigate = useNavigate();

    const [depotType, setDepotType] = useState<number>(0);
    const formik = useFormik({
        initialValues: {
            userId: '',
            userName: '',
            userFirstName: '',
            userPwdCheck: '',
            userPwd: '',
            userEmail: '',
            note: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });

    const [profile, setProfile] = useState<userListProfile | null>(null);
    const [open, setOpen] = useState(false);
    const handlerDriverAccess = () => {
        console.log('idx', profile.idx);
        setOpen((p) => !p);
    };

    const handlerAddress = (add, lat, lot) => {
        formik.setFieldValue('userAddress', add);
        setOpen((p) => !p);
    };

    // const memo = useMemo(() => depotEmailCreate(), [formik.values.userName, formik.values.depotName]);

    useEffect(() => {}, []);

    const createDepot = async () => {
        try {
            const v = formik.values;
            const api = await axiosService.post('/users/admin/managerCreate', { ...v, stat: statView, trans: tranView, man: manager });
            const { status, data } = api.data;
            if (status) {
                toast.info('Success Create Manager', { ...ToastSetting.default });
                navigate('/user/manager');
            } else {
                toast.error(data, { ...ToastSetting.default });
            }
        } catch (e) {
            toast.error('Error', { ...ToastSetting.default });
        } finally {
            setAlertOpen(false);
        }
    };

    const [alertOpen, setAlertOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const halderShow = (type) => {
        if (type === 1) {
            setShow((p) => !p);
        } else {
            setShow1((p) => !p);
        }
    };

    const [tranView, setTransView] = useState(false);
    const [statView, setStatView] = useState(false);
    const [manager, setManager] = useState(false);
    const { user } = useAuth();
    const adminRole = user?.admin ?? 0;
    if (adminRole !== 1) {
        return <div />;
    }
    console.log('error', formik.errors);
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Created Manager</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Button size="large" color="primary">
                                        Create
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing} p={2}>
                        <Grid container spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <Grid item xs={6} spacing={2}>
                                <TextField
                                    id="userFirstName"
                                    fullWidth
                                    label="First Name"
                                    value={formik.values.userFirstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.userFirstName && Boolean(formik.errors.userFirstName)}
                                    helperText={formik.touched.userFirstName && formik.errors.userFirstName}
                                />
                            </Grid>
                            <Grid item xs={6} spacing={2}>
                                <TextField
                                    id="userName"
                                    fullWidth
                                    label="Last Name"
                                    value={formik.values.userName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.userName && Boolean(formik.errors.userName)}
                                    helperText={formik.touched.userName && formik.errors.userName}
                                />
                            </Grid>
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                autoComplete={'do-not-autofill'}
                                id="userId"
                                fullWidth
                                label="Id"
                                value={formik.values.userId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userId && Boolean(formik.errors.userId)}
                                helperText={formik.touched.userId && formik.errors.userId}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                autoComplete={'off'}
                                id="userEmail"
                                fullWidth
                                label="Email"
                                value={formik.values.userEmail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userEmail && Boolean(formik.errors.userEmail)}
                                helperText={formik.touched.userEmail && formik.errors.userEmail}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                type={show ? 'text' : 'password'}
                                id="userPwd"
                                fullWidth
                                label="Password"
                                value={formik.values.userPwd}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userPwd && Boolean(formik.errors.userPwd)}
                                helperText={formik.touched.userPwd && formik.errors.userPwd}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton aria-label="toggle password visibility" onClick={() => halderShow(1)} edge="end">
                                            {show ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'}>
                            <TextField
                                type={show1 ? 'type' : 'password'}
                                id="userPwdCheck"
                                fullWidth
                                label="Confirm Password"
                                value={formik.values.userPwdCheck}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.userPwdCheck && Boolean(formik.errors.userPwdCheck)}
                                helperText={formik.touched.userPwdCheck && formik.errors.userPwdCheck}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton aria-label="toggle password visibility" onClick={() => halderShow(2)} edge="end">
                                            {show1 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid spacing={2} item xs={12} alignItems={'center'} justifyContent={'center'} />
                        <Grid item xs={12}>
                            <TextField
                                id="note"
                                fullWidth
                                label="Note"
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                        <Grid item sm={12} md={12}>
                            <SubCard title="Manager Setting" contentSX={{ textAlign: 'center' }}>
                                <Grid xs={12} mb={2}>
                                    <Typography variant={'h4'}>Managers Hide</Typography>
                                    <Switch
                                        checked={manager}
                                        onChange={(event, checked) => setManager(checked)}
                                        inputProps={{ 'aria-label': 'checked' }}
                                    />
                                </Grid>
                                <Grid xs={12} mb={2}>
                                    <Typography variant={'h4'}>Transaction Hide</Typography>
                                    <Switch
                                        checked={tranView}
                                        onChange={(event, checked) => setTransView(checked)}
                                        inputProps={{ 'aria-label': 'checked' }}
                                    />
                                </Grid>
                                <Grid xs={12} mb={2}>
                                    <Typography variant={'h4'}>Statistics Hide</Typography>
                                    <Switch
                                        checked={statView}
                                        onChange={(event, checked) => setStatView(checked)}
                                        inputProps={{ 'aria-label': 'checked' }}
                                    />
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button variant="contained" type={'submit'}>
                                        Create Manager
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </MainCard>
            {open && <MapDialog open={open} handleClose={setOpen} handleSuccess={handlerAddress} />}
            <AlertDialog
                title={'Create Manager'}
                content={`Do you want to create an account for Manager with your ${formik.values.userId}`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => createDepot()}
            />
        </>
    );
};

export default CreateManager;

import { useTheme } from '@mui/system';
import { Chip } from '@mui/material';
import React from 'react';

export const OrderTypeConst = [
    { value: 1, label: 'Processing' },
    { value: 2, label: 'Delivered' },
    { value: 3, label: 'Completed' },
    { value: 4, label: 'Refund' },
    { value: 5, label: 'Cancellation' },
    { value: 6, label: 'Refund request' }
];

export const FindOrderTypeConst = (value) => OrderTypeConst.find((v) => v.value === value);

export const FindOrderTypeStyle = (theme, type) => {
    switch (type) {
        case 1:
            return {
                background: theme.palette.warning.light,
                color: theme.palette.warning.dark
            };
        case 2:
            return {
                background: theme.palette.success.light + 60,
                color: theme.palette.success.dark
            };
        case 3:
            return {
                background: theme.palette.orange.light + 80,
                color: theme.palette.orange.dark
            };
        case 4:
            return {
                background: theme.palette.orange.light + 120,
                color: theme.palette.success.dark
            };
        case 5:
            return {
                background: theme.palette.orange.light + 120,
                color: theme.palette.info.dark
            };
        default:
            return {
                background: theme.palette.warning.light,
                color: theme.palette.warning.dark
            };
    }
};

export default OrderTypeConst;

import React, { useEffect, useRef, useState } from 'react';
import { CSVLink, CSVDownload } from 'react-csv';
import useAxiosServices from '../../hooks/useAxios';
import { Button } from '@mui/material';
import { format } from 'date-fns';

const switchTypeCase = (type) => {
    switch (type) {
        case 1:
            return 'User';
        case 2:
            return 'Driver';
        case 3:
            return 'Depot';
    }
};

interface Props {
    type: number;
    skip: number;
    take: number;
}

const UserDownload = ({ type, skip, take }: Props) => {
    const { axiosService } = useAxiosServices();
    // const [csvData, setData] = useState([['1'], ['2']]);

    const [csvData, setCsvData] = useState([]);
    const csvInstance = useRef<any>();
    useEffect(() => {
        console.log('csvData', csvData);
        if (csvData && csvData.length > 0 && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
                csvInstance?.current?.link.click();
            });
        }
    }, [csvData]);

    const getData = async () => {
        try {
            const api = await axiosService.post('/users/download/csv', { type, skip, take });
            const { status, data } = api.data;
            console.log('DATA. ', data);
            if (status) {
                setCsvData([...data]);
            }
        } catch (e) {
        } finally {
        }
    };

    console.log('Data', csvData);

    return (
        <>
            <Button onClick={() => getData()}>Download .CSV</Button>
            {csvData.length > 0 && (
                <CSVLink filename={`${format(new Date(), 'ddMMyyyyHHss')}${switchTypeCase(type)}`} ref={csvInstance} data={csvData} />
            )}
        </>
    );
};

export default UserDownload;

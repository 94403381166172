import React, { Dispatch, SetStateAction } from 'react';
import PickTypeConst, { FindPickTypeConst, FindPickTypeStyle } from '../../utils/const/pick.const';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import PickTypeCard from '../swith/pickTypeCard';
import OrderTypeConst, { FindOrderTypeConst, FindOrderTypeStyle } from '../../utils/const/order.const';
import { useTheme } from '@mui/system';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
interface ICardProps {
    type: number | null;
}

export const OrderTypeCard = ({ type }: ICardProps) => {
    const theme = useTheme();
    return <Chip label={FindOrderTypeConst(type).label} size="small" sx={FindOrderTypeStyle(theme, type)} />;
};
interface Props {
    selectMenus: number[];
    setSelectTypes: Dispatch<SetStateAction<number[]>>;
}
const OrderMultipleSelect = ({ selectMenus, setSelectTypes }: Props) => {
    const handleChange = (event: SelectChangeEvent<typeof selectMenus>) => {
        const {
            target: { value }
        } = event;
        // @ts-ignore
        setSelectTypes([...value]);
    };
    console.log('select', selectMenus);
    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 300, background: '#fff' }}>
                <InputLabel id="demos-multiple-checkbox-label" sx={{ background: '#fff !important', width: 50 }}>
                    Status
                </InputLabel>
                <Select
                    sx={{ background: '#fff !important', '& .MuiOutlinedInput-input': { background: '#fff !important' } }}
                    labelId="demos-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectMenus}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" sx={{ background: '#fff !important' }} />}
                    renderValue={(s) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {s.map((v) => (
                                <OrderTypeCard type={v} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {OrderTypeConst.map((value, i) => (
                        <MenuItem key={`demp-dfdsfid-${i}`} value={value.value}>
                            <Checkbox checked={selectMenus.includes(value.value)} />
                            <ListItemText primary={value.label} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default OrderMultipleSelect;

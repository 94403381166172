import React, { useEffect, useState } from 'react';
import { Grid, Button, TextField, Typography, FormGroup, FormControlLabel, Checkbox, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { gridSpacing } from '../../store/constant';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DisabledByDefaultTwoToneIcon from '@mui/icons-material/DisabledByDefaultTwoTone';
import { userListProfile } from '../../_mockApis/user-profile/types';
import useAxiosServices from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import { CheckBox } from '@mui/icons-material';
import { DatePicker, DateRangePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import ReactDraftWysiwyg from '../../ui-component/editor/ReactDraftWysiwyg';
import EmailEditorUnLayer from './email.editor';
import AlertDialog from '../../ui-component/dialog/AlertDialog';

const validationSchema = yup.object({
    title: yup.string().required('title is required'),
    content: yup
        .string()
        .min(2, 'content should be of minimum 2 characters length')
        .max(70, 'content should be of max 70 characters length')
});

interface Props {
    type: any;
    selectRows: number[];
    onRefresh: () => void;
}

const MessageUserContent = ({ type, selectRows, onRefresh }: Props) => {
    const theme = useTheme();
    const navigation = useNavigate();
    const formik = useFormik({
        initialValues: {
            title: '',
            content: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setAlertOpen(true);
        }
    });
    const { axiosService } = useAxiosServices();
    const [content, setContent] = useState('');
    const sendPush = async () => {
        const api = await axiosService.post('/before-store/send', {
            type,
            title: formik.values.title,
            content: formik.values.content,
            mailContent: content,
            list: selectRows
        });
        const { status } = api.data;
        if (status) {
            onRefresh();
            toast.info('Success Send', { ...ToastSetting.default });
        } else {
            toast.error('Failed Send', { ...ToastSetting.default });
        }
        setAlertOpen(false);
    };

    const [r, setR] = useState(false);
    const [rangeDate, setRangeDate] = useState(null);
    const [error, setError] = useState(false);

    const getMailContent = async () => {
        const api = await axiosService.post('mailer/get', { type });
        const { status, data } = api.data;
        if (status) {
            formik.setValues({
                title: data.title ?? '',
                content: data.content ?? ''
            });
            setContent(data.mailContent ?? 'addContent');
        }
    };

    useEffect(() => {
        getMailContent();
    }, []);
    const [alertOpen, setAlertOpen] = useState(false);
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} mt={4}>
                    <TextField
                        id="title"
                        fullWidth
                        label="Title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />
                </Grid>
                {(type === 2 || type === 3 || type === 4 || type === 5 || type === 6 || type === 8) && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant={'h3'}>Push Content</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="content"
                                fullWidth
                                label="Content"
                                value={formik.values.content}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.content && Boolean(formik.errors.content)}
                                helperText={formik.touched.content && formik.errors.content}
                            />
                        </Grid>
                    </>
                )}

                <Grid item xs={12}>
                    <Typography variant={'h3'}>Mail Content</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        '& .rdw-editor-wrapper': {
                            bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : theme.palette.background.paper,
                            border: '1px solid',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                            borderRadius: '12px',
                            overflow: 'hidden',
                            '& .rdw-editor-main': {
                                px: 2,
                                py: 0.5,
                                border: 'none',
                                minHeight: '300px'
                            },
                            '& .rdw-editor-toolbar': {
                                pt: 1.25,
                                border: 'none',
                                borderBottom: '1px solid',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                                bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                '& .rdw-option-wrapper': {
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900'
                                },
                                '& .rdw-dropdown-wrapper': {
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.50',
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : 'grey.900',
                                    '& .rdw-dropdown-selectedtext': {
                                        color: theme.palette.mode === 'dark' ? 'dark.dark' : 'grey.900'
                                    }
                                }
                            }
                        }
                    }}
                >
                    <Stack spacing={gridSpacing}>
                        {content !== '' && <ReactDraftWysiwyg value={content} init={content} setValue={setContent} />}
                    </Stack>
                </Grid>

                <Grid container item xs={12} justifyContent={'flex-end'} sx={{ mb: 2 }}>
                    <Button variant={'outlined'} size={'large'} onClick={() => formik.handleSubmit()}>
                        Send
                    </Button>
                </Grid>
            </Grid>
            <AlertDialog
                title={'Send Mail'}
                content={`Do you want to send this content to ${selectRows.length} contacts?`}
                open={alertOpen}
                handleClose={setAlertOpen}
                handleSuccess={() => sendPush()}
            />
        </>
    );
};

export default MessageUserContent;

import { Box, Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select, TableCell, Typography } from '@mui/material';
import { PickProfileChip } from '../../ui-component/swith/pickTypeCard';
import { PickProfileConst } from '../../utils/const/pick.const';
import React, { useCallback, useEffect, useState } from 'react';
import { locationListType } from '../../types/pick';
import useAxiosServices from '../../hooks/useAxios';
import { useTheme } from '@mui/styles';
import { switchWeek } from './suburb.setting';
import { useStyles } from '../../utils/useStyles';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';

interface Props {
    data: locationListType;
    hover?: number;
}

export const SuburbWeekList = ({ data, hover }: Props) => {
    const classes = useStyles();
    const [weekly, setWeekly] = useState<number[]>([]);
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const weekListChange = useCallback(() => {
        const apiData = data.weekDisabled ?? '';
        return apiData.split('|:|').map((v) => Number(v));
    }, [data]);
    useEffect(() => {
        setWeekly(weekListChange());
    }, [data]);

    const handleWeekList = useCallback(
        async (type) => {
            const api = await axiosService.post('/address/weekList/change', { idx: data.idx, type });
            const { data: apiData } = api.data;
            setWeekly(apiData);
            console.log('api', api.data);
            toast.info(`${data.title} ${switchWeek(type)} Updated!`, { ...ToastSetting.default });
        },
        [data]
    );

    const label = [
        { value: '1', label: 'Weekly' },
        { value: '2', label: 'Bi-weekly(2-4)' },
        { value: '3', label: 'Bi-weekly(1-3-5)' }
    ];
    return (
        <>
            {weekly.map((v, i) => (
                <TableCell
                    align={'center'}
                    sx={{
                        width: '10px',
                        padding: '0px',
                        background: hover === i ? '#eeeeee' : 'none'
                    }}
                >
                    <Checkbox
                        color="primary"
                        title={switchWeek(i)}
                        checked={weekly[i] === 1}
                        onChange={() => handleWeekList(i)}
                        sx={{
                            color: theme.palette.primary.main,
                            '&.Mui-checked': { color: theme.palette.primary.main }
                        }}
                    />
                </TableCell>
            ))}
        </>
    );
};

import { IconChartLine } from '@tabler/icons';

const icons = {
    IconChartLine
};

const statRouting = {
    id: 'stat-menu',
    type: 'group',
    children: [
        {
            id: 'Statistics order',
            title: 'Statistics',
            type: 'collapse',
            icon: icons.IconChartLine,
            children: [
                {
                    id: 'financial',
                    title: 'Financial',
                    type: 'item',
                    url: '/order/financial'
                },
                {
                    id: 'postcode',
                    title: 'Suburb',
                    type: 'item',
                    url: '/stat/postcode'
                },
                {
                    id: 'share',
                    title: 'Share',
                    type: 'item',
                    url: '/share/financial'
                }
                // {
                //     id: 'pick-list',
                //     title: 'list',
                //     type: 'item',
                //     url: '/pick/list'
                // }
            ]
        }
    ]
};

export default statRouting;

import React from 'react';
import { useTheme } from '@mui/styles';
import { Button, Grid, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import MessageList from './message.list';

const MessageListWrapper = () => {
    const theme = useTheme();
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Message List</Typography>
                    </Grid>
                </Grid>
            }
            content={false}
        >
            <MessageList />
        </MainCard>
    );
};

export default MessageListWrapper;

import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { IconFileUpload } from '@tabler/icons';
import { Box, Button } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

function ImageUploader({ onChange, imageURL, onCancel, effect = false, loading }: any) {
    const [files, setFiles] = useState(imageURL && imageURL !== '' ? [{ name: 'ssuva', preview: imageURL }] : []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop: useCallback(
            (acceptedFiles) => {
                setFiles(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        })
                    )
                );
                onChange(acceptedFiles);
            },
            [onChange]
        )
    });

    const fileCancel = (e) => {
        e.stopPropagation();
        setFiles([]);
        onCancel();
    };

    const thumbs = files.map((file) => (
        <Box
            alignItems={'center'}
            justifyContent={'center'}
            border={1}
            borderColor={'#222'}
            borderRadius={'12px'}
            width={'150px'}
            height={'150px'}
            margin={'8px'}
            p={4}
        >
            <Box minWidth={0} overflow={'hidden'}>
                <img src={file.preview} style={{ width: '100%', height: '100%' }} alt={file.name} />
            </Box>
        </Box>
    ));

    useEffect(() => {
        setFiles(imageURL && imageURL !== '' ? [{ name: 'ssuva', preview: imageURL }] : []);
    }, [imageURL, loading]);

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files, loading]
    );
    return (
        <section className="container uploader">
            <Box {...getRootProps()} style={{ padding: files.length > 0 ? '0px' : '20px' }}>
                <input {...getInputProps()} />
                {/* <UploadIcon /> */}
                {files.length > 0 ? (
                    <Box>
                        <Button onClick={(e) => fileCancel(e)}>
                            <CloseOutlined htmlColor={'#222'} />
                        </Button>
                        {thumbs}
                    </Box>
                ) : (
                    <>
                        <IconFileUpload />
                    </>
                )}
            </Box>
            {/* {thumbs && <ThumbsContainer>{thumbs}</ThumbsContainer>} */}
        </section>
    );
}

export default ImageUploader;

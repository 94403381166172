import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import {
    Button,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    OutlinedInput,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { IconSearch } from '@tabler/icons';
import MainCard from '../../ui-component/cards/MainCard';
import PickPageList from './pickPageList';
import useAxios from '../../hooks/useAxios';
import { pickListTypes } from '../../types/pick';
import { toast } from 'react-toastify';
import { ToastSetting } from '../../utils/common';
import PickPageListDetail from './PickPageListDetail';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import AlertDialog from '../../ui-component/dialog/AlertDialog';

const WaitDepotList = () => {
    const theme = useTheme();
    const { axiosService } = useAxios();
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/pick/list/depotWait', listParam);
        setList(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
    };

    useEffect(() => {
        getData();
    }, [listParam]);

    const pageChange = (event, page) => {
        setListParam((p) => ({ ...p, skip: page - 1 }));
    };
    const [list, setList] = useState<pickListTypes[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [tempId, setTempId] = useState('');
    const deletePick = async (idx) => {
        setDeleteOpen(true);
        setTempId(idx);
    };

    const deleteHandler = async () => {
        const api = await axiosService.post('/pick/delete', { idx: tempId });
        const { status } = api.data;
        if (status) {
            toast.info('Success Delete pick', { ...ToastSetting.default });
            getData();
        }
        setDeleteOpen(false);
    };

    const [deleteOpen, setDeleteOpen] = useState(false);
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Awaiting confirmation of contamination report</Typography>
                        </Grid>
                        <Grid item>
                            <OutlinedInput
                                id="input-search"
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={0.5} size="0.5rem" />
                                    </InputAdornment>
                                }
                                size="small"
                            />
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <>
                    <TableContainer>
                        <Table size="small" aria-label="collapsible table">
                            <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Customer</TableCell>
                                    <TableCell>Items</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Driver</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                                {list.map((value, index: number) => (
                                    <PickPageListDetail deletePick={deletePick} key={value.idx} value={value} index={index} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid item xs={12} sx={{ p: 3 }}>
                        <Grid container justifyContent="space-between" spacing={gridSpacing}>
                            <Grid item>
                                <Pagination page={listParam.skip + 1} count={paging.allPage} color="secondary" onChange={pageChange} />
                            </Grid>
                            <Grid item>
                                <Button
                                    size="large"
                                    sx={{ color: theme.palette.grey[900] }}
                                    color="secondary"
                                    endIcon={<ExpandMoreRoundedIcon />}
                                    onClick={handleClick}
                                >
                                    10 Rows
                                </Button>
                                <Menu
                                    id="menu-user-list"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    variant="selectedMenu"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                >
                                    <MenuItem onClick={handleClose}> 10+</MenuItem>
                                    <MenuItem onClick={handleClose}> 20+</MenuItem>
                                    <MenuItem onClick={handleClose}> 30+</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </MainCard>
            <AlertDialog
                title={'Delete pick'}
                content={'Do you really want to delete the pick information?'}
                open={deleteOpen}
                handleClose={setDeleteOpen}
                handleSuccess={() => deleteHandler()}
            />
        </>
    );
};

export default WaitDepotList;

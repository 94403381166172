import { dayFormatNotConvert } from './times/timeFormats';

export function parseExcelDate(value) {
    // Check if the value is a number (Excel date serial number)
    console.log('format', value);
    if (typeof value === 'number') {
        return excelDateToJSDate(value);
    }

    // Check if the value is a string (Excel date in text format)
    if (typeof value === 'string') {
        return dayFormatNotConvert(value);
    }

    // If value is neither a number nor a string, return null or handle as needed
    return '';
}

// Convert Excel date serial number to JavaScript Date object
export function excelDateToJSDate(excelDate) {
    const startDate = new Date(1900, 0, 1); // January 1, 1900
    startDate.setDate(startDate.getDate() + (excelDate - 1));
    return dayFormatNotConvert(startDate);
}

export function splitName(name) {
    if (!name) {
        return name;
    }
    const parts = name.split(' ');

    if (parts.length === 2) {
        return [parts[0], parts[1]];
    } else {
        return [parts[0], ''];
    }
}

import React from 'react';
import { RouteRootList } from '../../views/pick/pickListWithDepot';
import { driverListProfile } from '../../_mockApis/user-profile/types';
import * as xlsx from 'xlsx-js-style';
import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
    allBagCalc,
    allBagCalcEa,
    bagCalc,
    bagCalcOnlyNumberEA,
    bagTypeRending,
    bagTypeRendingRows,
    numberWithCommas,
    timeCalcHelper,
    totalTimeGroupCalc
} from '../../utils/common';
import { RouteGroupListType } from '../../views/pick/map/route.group.list';
import { PolyLineDataType } from '../../views/pick/route.optimasation';
import SaveIcon from '@mui/icons-material/Save';
import { IconFileExport } from '@tabler/icons';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import selectExcelRange from '../../utils/excel.helper';

interface Props {
    routeList: RouteRootList[];
    date: Date;
    driverProfile: driverListProfile | null;
    polylineData: PolyLineDataType[];
}

const RouteDownload = ({ routeList, date, driverProfile, polylineData }: Props) => {
    const handleConvertFileAndDownLoad = () => {
        const data = [dataHeader(), driverHeader(), TotalLiterHeader(), Descriptionheader(), descriptionBagTypeHeader(), ...groupRow()];
        const ws = xlsx.utils.aoa_to_sheet(data);
        const wb = xlsx.utils.book_new();
        const merge = [
            { s: { r: 3, c: 4 }, e: { r: 3, c: 8 } },
            { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
            { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } },
            { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } },
            { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } },
            { s: { r: 3, c: 9 }, e: { r: 4, c: 9 } },
            { s: { r: 3, c: 10 }, e: { r: 4, c: 10 } },
            { s: { r: 3, c: 11 }, e: { r: 4, c: 11 } },
            { s: { r: 3, c: 12 }, e: { r: 4, c: 12 } },
            { s: { r: 3, c: 13 }, e: { r: 4, c: 13 } },
            { s: { r: 3, c: 14 }, e: { r: 4, c: 14 } }
        ];
        ws['!cols'] = [
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 40 },
            { wch: 12 },
            { wch: 12 },
            { wch: 12 },
            { wch: 12 }
        ];

        convertSpecificLine(ws, '4', {
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: '1' // any truthy value here
            }
        });

        // const result2 = convertSpecificColulmn(ws, 'J')
        const range = xlsx.utils.decode_range(ws['!ref']);
        const num_rows = range.e.r - range.s.r + 1;
        const num_cols = range.e.r - range.s.r + 1;
        console.log('reange', range);
        ws['!rows'] = new Array(num_rows).fill(18).map((v) => ({ hpx: v }));
        // ws[3].s = {
        //     alignment: {
        //         vertical: 'center',
        //         horizontal: 'center',
        //         wrapText: '1' // any truthy value here
        //     }
        // };

        convertAllStyle(ws, {
            border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } }
            }
        });

        const result = { ...ws };
        result['!merges'] = merge;

        console.log(ws);
        xlsx.utils.book_append_sheet(wb, result, 'Sheet1');
        const fileName = format(new Date(), 'yyyyMMddHHmmss');
        xlsx.writeFile(wb, `${fileName}.xlsx`);
    };
    const handleConvertFileAndDownLoad2 = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.columns = [
            { header: ' ', key: '1', width: 8 },
            { header: ' ', key: '1', width: 8 },
            { header: ' ', key: '1', width: 8 },
            { header: ' ', key: '1', width: 8 },
            { header: ' ', key: '1', width: 10 },
            { header: ' ', key: '1', width: 10 },
            { header: ' ', key: '1', width: 10 },
            { header: ' ', key: '1', width: 10 },
            { header: ' ', key: '1', width: 10 },
            { header: ' ', key: '1', width: 8 },
            { header: ' ', key: '1', width: 40 },
            { header: ' ', key: '1', width: 12 },
            { header: ' ', key: '1', width: 12 },
            { header: ' ', key: '1', width: 12 },
            { header: ' ', key: '1', width: 12 }
        ];

        const data = [dataHeader(), driverHeader(), TotalLiterHeader(), Descriptionheader(), descriptionBagTypeHeader(), ...groupRow()];
        for (const row of data) {
            worksheet.addRow(row);
        }

        worksheet.mergeCells('A5:A6');
        worksheet.mergeCells('B5:B6');
        worksheet.mergeCells('C5:C6');
        worksheet.mergeCells('D5:D6');

        worksheet.mergeCells('E5:I5');
        // worksheet.mergeCells('G5:G6');
        // worksheet.mergeCells('H5:H6');
        // worksheet.mergeCells('I5:I6');
        worksheet.mergeCells('J5:J6');
        worksheet.mergeCells('K5:K6');
        worksheet.mergeCells('L5:L6');
        worksheet.mergeCells('M5:M6');
        worksheet.mergeCells('N5:N6');
        worksheet.mergeCells('O5:O6');

        console.log('worksheet.columnCount', worksheet.columnCount);
        console.log('w', worksheet.lastColumn);
        console.log('w', worksheet.lastRow.number);
        worksheet.columns.forEach((column) => {
            column.alignment = { vertical: 'middle', horizontal: 'center' };
            column.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });
        worksheet.eachRow((row) => {
            row.height = 18;
        });

        selectExcelRange(worksheet, `K7:O${worksheet.lastRow.number}`).forEach((cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'left' };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], mimeType);
        const fileName = format(new Date(), 'yyyyMMddHHmmss');
        saveAs(blob, `${fileName}.xlsx`);
    };
    const dataHeader = () => {
        const formats = format(date, 'dd MMM yy');
        return ['Date', formats, ' ', ' ', ' ', ' ', ' ', ' '];
    };
    const driverHeader = () => {
        if (!driverProfile) {
            return ['Driver'];
        }
        return ['Driver', driverProfile.userFirstName ?? '', driverProfile.userName ?? '', driverProfile.userEmail ?? ''];
    };

    const TotalLiterHeader = () => {
        if (!routeList || routeList.length === 0) {
            return ['Total litre'];
        }
        return ['Total litre', `${numberWithCommas(allBagCalc(routeList))} L`, ''];
    };

    const Descriptionheader = () => [
        ' ',
        'Time',
        'Litre',
        'Bag',
        'Bag type',
        ' ',
        ' ',
        ' ',
        ' ',
        'Unit',
        'Address',
        'Description',
        'Mobile',
        'Name',
        'Type'
    ];
    //  TODO : unit
    const descriptionBagTypeHeader = () => [' ', ' ', ' ', ' ', 'Recan Bag', 'Non-Recan', 'Wheelie Bin', 'Bulk Bag', 'Coex Bag'];

    const convertSpecificLine = (ws, wsrow: string, changeData: any) => {
        const objectKeyArray = Object.keys(ws);
        for (const row of objectKeyArray) {
            const regex = /[^0-9]/gi;
            const a = row.replace(regex, '');
            if (wsrow === a) {
                if (typeof ws[row] === 'object') {
                    ws[row].s = changeData;
                }
            }
        }
        return ws;
    };
    const convertAllStyle = (ws, changeData: any) => {
        const objectKeyArray = Object.keys(ws);
        console.log('WS', ws);
        for (const row of objectKeyArray) {
            if (typeof ws[row] === 'object') {
                ws[row].s = { ...(ws[row]?.s ?? {}), ...changeData };
            }
        }
        return ws;
    };

    const depotRefry = (row: RouteRootList, callback) => {
        if (row.type === 'depot') {
            return '';
        } else {
            return callback;
        }
    };

    const convertSpecificColulmn = (ws, wsrow: string, changeData: any) => {
        const objectKeyArray = Object.keys(ws);
        for (const row of objectKeyArray) {
            const regex = /[0-9]/g;
            const a = row.replace(regex, '');
            if (wsrow === a) {
                if (typeof ws[row] === 'object') {
                    ws[row].s = changeData;
                }
            }
        }
        return ws;
    };

    const groupRow = () => {
        const formList = handleConvert(routeList);
        if (formList.length === 0) {
            return [];
        }
        const result = [];
        for (const row of formList) {
            const bagType = bagTypeRending(row.list ?? []);
            console.log('bagType', bagType);
            const addRow = [
                `Group ${row.group}`,
                `${timeCalcHelper(totalTimeGroupCalc(polylineData, row.group))}`,
                `${numberWithCommas(allBagCalc(row.list ?? []))} L`,
                `${numberWithCommas(allBagCalcEa(row.list ?? []))} EA`,
                `${bagType.one === 0 ? '0' : `${bagType.one} `}`,
                `${bagType.two === 0 ? '0' : `${bagType.two}`}`,
                `${bagType.three === 0 ? '0' : `${bagType.three}`}`,
                `${bagType.four === 0 ? '0' : `${bagType.four}`}`,
                `${bagType.four === 0 ? '0' : `${bagType.four}`}`
            ];
            result.push(addRow);
            let numbering = 1;
            for (const item of row.list) {
                const bagInfo = bagTypeRendingRows(item?.info?.bags ?? []);
                const addItem = [
                    numbering,
                    '',
                    `${depotRefry(item, bagCalc(item?.info?.bags ?? []))}`,
                    `${depotRefry(
                        item,
                        bagCalcOnlyNumberEA(item?.info.bags ?? []) === 0 ? 'N/A' : `${bagCalcOnlyNumberEA(item?.info.bags ?? [])} EA`
                    )}`,
                    `${bagInfo.one === 0 ? '0' : `${bagInfo.one}`}`,
                    `${bagInfo.two === 0 ? '0' : `${bagInfo.two} `}`,
                    `${bagInfo.three === 0 ? '0' : `${bagInfo.three}`}`,
                    `${bagInfo.four === 0 ? '0' : `${bagInfo.four}`}`,
                    `${bagInfo.four === 0 ? '0' : `${bagInfo.four}`}`,
                    ``,
                    `${item.address ?? ''}`,
                    `${item.info?.pickPlace ?? ''}`,
                    `${item.info?.user?.userPhone ?? ''}`,
                    `${item.name}`,
                    `${item.type?.toLocaleUpperCase() ?? ''}`
                ];
                // TODO Type == C / D / R
                result.push(addItem);
                numbering += 1;
            }
        }
        console.log('row', result);
        return result;
    };

    const handleConvert = (list: RouteRootList[]): RouteGroupListType[] => {
        const temp = [];
        let group = 1;
        for (const row of list) {
            const addData = { ...row, group };
            if (row.type === 'depot') {
                group++;
            }
            temp.push(addData);
        }
        return handleGroupList(temp);
    };

    const handleGroupList = (list: RouteRootList[]) => {
        let group = 1;
        let temp = [];
        const tempReturn = [];
        for (const row of list) {
            if (group === row.group) {
                temp.push(row);
            } else {
                const tempData = {
                    group,
                    list: temp
                };
                tempReturn.push(tempData);
                temp = Array.from([row]);
                group = row.group;
            }
        }
        if (temp.length > 0) {
            const tempData = {
                group,
                list: temp
            };
            tempReturn.push(tempData);
        }
        return tempReturn;
    };

    return (
        <Button sx={{ marginRight: '10px' }} size="small" startIcon={<IconFileExport />} onClick={() => handleConvertFileAndDownLoad2()}>
            <Typography variant="h4">Export Xlsx</Typography>
        </Button>
    );
};

export default RouteDownload;

import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// project imports

import { userListProfile, UserProfile } from '_mockApis/user-profile/types';

// assets

import useAxios from '../../hooks/useAxios';

import PushInRow from './push.in.row';

// ==============================|| USER LIST 1 ||============================== //
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export const BeforeListSelectConst = [
    { value: 'none', label: 'Default' },
    { value: 'collection', label: 'Collection' },
    { value: 'credit', label: 'credit' }
];
export const BeforeListSelectDesc = [
    { value: 'DESC', label: 'DESC' },
    { value: 'ASC', label: 'ASC' }
];
export const BeforeListSelectChange = [
    { value: 'All', label: 'All' },
    { value: 'Complete', label: 'Change' },
    { value: 'Non', label: 'None' }
];
interface IBeforeInList {
    inList: number[];
    onDelete: (idx: number) => void;
    refresh: boolean;
}
const PushInList = ({ inList, onDelete, refresh }: IBeforeInList) => {
    const theme = useTheme();

    const { axiosService } = useAxios();
    const [data, setData] = React.useState<userListProfile[]>([]);

    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        search: string;
        change: 'All' | 'Complete' | 'Non';
        send: 'All' | 'Complete' | 'Non';
        sort: 'credit' | 'collection' | 'none';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        search: '',
        change: 'All',
        send: 'All',
        sort: 'none'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });
    const getData = async () => {
        const response = await axiosService.post('/users/push/list/in', { in: inList });
        setData(response.data.list);
        setPaging((p) => ({
            ...p,
            allPage: response.data.allPage,
            count: response.data.count
        }));
        setSelectRows([...[]]);
    };

    React.useEffect(() => {
        getData();
    }, [listParam, inList, refresh]);

    const [selectRows, setSelectRows] = useState<number[]>([]);

    const handleSelectAllRow = () => {
        const find = data.map((v) => v.idx);
        if (selectRows.length === find.length) {
            setSelectRows([...[]]);
        } else {
            const changeData = [...find];
            setSelectRows([...changeData]);
        }
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ p: 0, pl: 3, cursor: 'pointer', width: 100 }} onClick={() => handleSelectAllRow()}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>Delete</div>
                            </TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Mail</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            data.map((row, index) => (
                                <PushInRow handleDeleteRow={onDelete} selectRows={selectRows} row={row} index={index} />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default PushInList;

import { useDispatch, useSelector } from 'react-redux';

// material-ui
import {
    Box,
    Button,
    Drawer,
    Grid,
    Typography,
    Autocomplete,
    CardMedia,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    OutlinedInput,
    InputAdornment,
    IconButton,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    LinearProgress,
    TableHead,
    Tooltip,
    Stack,
    Link,
    ButtonBase,
    Menu,
    Collapse
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// third party
import * as yup from 'yup';
import { Chance } from 'chance';
import { useFormik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { ADD_ITEM, SNACKBAR_OPEN } from 'store/actions';

// assets
import CancelIcon from '@mui/icons-material/Cancel';
// types
import { DefaultRootStateProps } from 'types';
import { KanbanProfile, KanbanColumn, KanbanUserStory } from 'types/kanban';
import { gridSpacing } from '../../../store/constant';
import { IconSearch } from '@tabler/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTheme } from '@mui/system';
import React, { useEffect, useState } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import AlertRouteDelete from '../AlertRouteDelete';
import { sub } from 'date-fns';
import PickListDetail from '../PickListDetail';
import AddPickUpInfo from '../AddPickupInfo';
import useAxiosServices from '../../../hooks/useAxios';
import { pickListTypes } from '../../../types/pick';
import { DepotListProfileType, userListProfile } from '../../../_mockApis/user-profile/types';

interface Props {
    open: boolean;
    handleDrawerOpen: () => void;
    addPick: (item: DepotListProfileType) => void;
    initList: DepotListProfileType[];
}

const chance = new Chance();

const avatarImage = require.context('assets/images/users', true);

const validationSchema = yup.object({
    search: yup.string().required('search text required')
});

const AddDepotDrawer = ({ open, handleDrawerOpen, addPick, initList }: Props) => {
    const theme = useTheme();
    const { axiosService } = useAxiosServices();
    const [list, setList] = useState<DepotListProfileType[]>([]);
    const [reload, setReload] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [listParam, setListParam] = useState<{
        skip: number;
        take: number;
        nameSort: 'ASC' | 'DESC';
        creditSort: 'ASC' | 'DESC';
    }>({
        skip: 0,
        take: 10,
        nameSort: 'ASC',
        creditSort: 'ASC'
    });
    const [paging, setPaging] = useState({
        allPage: 0,
        count: 0
    });

    useEffect(() => {
        getReadyList();
    }, [open, reload]);

    const getReadyList = async () => {
        const api = await axiosService.post('/users/depot/addList', listParam);
        const { data } = api;
        setList([...filteringList(initList, data.list)]);
        setPaging((p) => ({
            ...p,
            allPage: data.allPage,
            count: data.count
        }));
    };

    const filteringList = (init: DepotListProfileType[], data: DepotListProfileType[]) => {
        const convertInit = init.map((v) => v.idx);
        return data.filter((v) => !convertInit.includes(v.idx));
    };

    const addItem = async (item: DepotListProfileType) => {
        addPick(item);
        setReload((p) => !p);
        // handleDrawerOpen();
    };

    const handleSearchCheck = (value: string) => {
        if (!value) {
            return false;
        }
        const lowerValue = value ? value.toLocaleLowerCase() : '';
        const lowerText = searchText.toLocaleLowerCase();
        return lowerValue.includes(lowerText);
    };

    const renderSearchText = (paramList: DepotListProfileType[]) => {
        if (searchText === '') {
            return paramList;
        }
        const resultList = [];
        for (const row of paramList) {
            if (handleSearchCheck(row.userName)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.userAddress)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.userAddressMore)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.userZip)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.depot.depotAddress)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.depot.depotAddressMore)) {
                resultList.push(row);
            } else if (handleSearchCheck(row.depot.depotZip)) {
                resultList.push(row);
            }
        }

        return resultList;
    };
    return (
        <Drawer
            sx={{
                ml: open ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 900 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 900 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={open}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            <Box sx={{ p: 3 }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={gridSpacing}>
                    <Grid container justifyContent="space-between" alignItems="center" item xs={12}>
                        <Grid item xs={8}>
                            <Typography variant="h3">Depot Add</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton size="small" onClick={handleDrawerOpen}>
                                <CancelIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedInput
                            value={searchText}
                            onChange={(e) => setSearchText(e.currentTarget.value)}
                            id="input-search-depot"
                            placeholder="Search Address & Id"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" />
                                </InputAdornment>
                            }
                            size="small"
                            sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                        />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table size="small" aria-label="collapsible table">
                        <TableHead sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                            <TableRow>
                                <TableCell sx={{ pl: 3 }} />

                                <TableCell>Address</TableCell>
                                <TableCell>Depot</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ '& th,& td': { whiteSpace: 'nowrap' } }}>
                            {renderSearchText(list).map((value, index: number) => (
                                <TableRow hover>
                                    <TableCell sx={{ pl: 3, minWidth: 40, width: 40, height: 46 }}>
                                        <Tooltip title="Add Pick">
                                            <Button
                                                onClick={() => addItem(value)}
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                endIcon={<AddIcon />}
                                            >
                                                ADD
                                            </Button>
                                        </Tooltip>
                                    </TableCell>

                                    <TableCell sx={{ maxWidth: 'calc(100vw - 850px)', minWidth: 140 }} component="th" scope="row">
                                        {value.depot?.depotAddress}
                                    </TableCell>
                                    <TableCell sx={{ width: 40, minWidth: 40 }}>
                                        <Stack direction="row" spacing={0.5} alignItems="center">
                                            <MenuBookTwoToneIcon color="secondary" sx={{ fontSize: '0.875rem' }} />
                                            <Typography variant="body2">{value.userName}</Typography>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Drawer>
    );
};
export default AddDepotDrawer;

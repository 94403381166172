// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import MapBox from '../../views/pick/map/MapBox';
import SearchAddressBox from '../../components/map/SearchAddressBox';

// types
interface Props {
    open: boolean;
    initLat?: number | undefined;
    initLng?: number | undefined;
    handleClose: Dispatch<SetStateAction<Boolean>>;
    handleSuccess: (add, lat, lot) => void;
}

export default function MapDialog({ open, initLat, initLng, handleClose, handleSuccess }: Props) {
    const [latitude, setLatitude] = useState<number | undefined>(-33.8688197);
    const [longitude, setLongitude] = useState<number | undefined>(151.2092955);
    const [address, setAddress] = useState('-');

    useEffect(() => {
        setLatitude(initLat ?? -33.8688197);
        setLongitude(initLng ?? 151.2092955);
    }, []);

    const postAddress = (add, lat, lng) => {
        setLongitude(lng);
        setLatitude(lat);
        setAddress(add);
    };

    const sendAddress = () => {
        if (address === '-') {
            alert('Pleas Search Address.');
        } else {
            handleSuccess(address, latitude, longitude);
        }
    };

    return (
        <Dialog
            style={{ zIndex: 999 }}
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            fullWidth
            maxWidth="xs"
            aria-labelledby="item-delete-title"
            aria-describedby="item-delete-description"
        >
            <DialogTitle id="item-delete-title">{'Map Search'}</DialogTitle>
            <DialogContent>
                <SearchAddressBox initLatitude={latitude} initLongitude={longitude} postAddress={postAddress} />
            </DialogContent>
            <DialogActions sx={{ mr: 2 }}>
                <Button onClick={() => handleClose((p) => !p)} color="error">
                    Cancel
                </Button>
                <Button variant="contained" size="small" onClick={() => sendAddress()} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

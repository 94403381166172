import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Profile from './Profile';
import useAxiosServices from '../../hooks/useAxios';
import { userListProfile } from '../../_mockApis/user-profile/types';
import UserPickUpList from './UserPickUpList';
import UserPickUpWrapper from './UserPickUpWrapper';
import UserCredit from './UserCredit';
import DriverProfile from './DriverProfile';
import RoutingList from '../pick-routing-pages/RoutingList';
import CollectionUser from '../collection/collectionUser';
import PushPage from '../userPage/push.page';
import ChangePassword from './chane.password';
import ChangeEmail from './chage.email';

function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`user-tabpanel-${index}`} aria-labelledby={`user-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `user-tab-${index}`,
        'aria-controls': `user-tabpanel-${index}`
    };
}

const AdminProfile = () => {
    const { id } = useParams();
    const [profile, setProfile] = useState<userListProfile>({
        userBirth: '',
        userPhone: '',
        credit: 0,
        idx: 0,
        userAddress: '',
        userAddressMore: '',
        userEmail: '',
        userId: undefined,
        userImage: undefined,
        userName: '',
        userNickName: '',
        userPick: 0,
        userRole: 0,
        userStatus: 0,
        userZip: ''
    });
    const { axiosService } = useAxiosServices();
    const [value, setValue] = useState(0);
    const [userRole, setUserRole] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue) => {
        setValue(newValue);
    };

    const handlerUpdateProfile = (updateData) => {};

    useEffect(() => {
        getProfile();
    }, [id]);

    const getProfile = async () => {
        try {
            const api = await axiosService.post('/users/detail', { id });
            const { data } = api;
            console.log('DATA', data);
            setProfile({ ...data });
            setUserRole(data.userRole);
        } catch (e) {
            console.log(e);
        }
    };

    console.log('value', value);

    return (
        <MainCard title={'Admin Account Setting'}>
            <div>
                {userRole === 9 && (
                    <>
                        <Tabs
                            value={value}
                            indicatorColor={'primary'}
                            onChange={handleChange}
                            sx={{
                                mb: 3,
                                minHeight: 'auto',
                                '& button': {
                                    minWidth: 100
                                },
                                '& a': {
                                    minHeight: 'auto',
                                    minWidth: 10,
                                    py: 1.5,
                                    px: 1,
                                    mr: 2.25,
                                    color: 'grey.600'
                                },
                                '& a.Mui-selected': {
                                    color: 'primary.main'
                                }
                            }}
                            aria-label="simple tabs example"
                            variant="scrollable"
                        >
                            {/* <Tab component={Link} to="#" label="Profile" {...a11yProps(0)} /> */}
                            <Tab component={Link} to="#" label="Change Password" {...a11yProps(0)} />
                            <Tab component={Link} to="#" label="Change Email" {...a11yProps(1)} />
                        </Tabs>
                        {/* <TabPanel value={value} index={0}> */}
                        {/*    <Profile id={id} /> */}
                        {/* </TabPanel> */}
                        <TabPanel value={value} index={0}>
                            <ChangePassword id={id} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ChangeEmail profile={profile} />
                        </TabPanel>
                    </>
                )}
            </div>
        </MainCard>
    );
};

export default AdminProfile;

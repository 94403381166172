import React from 'react';
import { useTheme } from '@mui/system';
import { Chip } from '@mui/material';

import { FindMessageTypeConst, FindMessageTypeStyle } from './message.const';

interface Props {
    type: number | null;
}
export const MessageTypeCard = ({ type }: Props) => {
    const theme = useTheme();
    return <Chip label={FindMessageTypeConst(type).label} size="small" sx={FindMessageTypeStyle(theme, type)} />;
};
